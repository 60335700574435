// Status Colors for Tasks in Project
import { Box, Button, Popover } from "@mui/material";
import React, { useRef, useState } from "react";

const data = [
  {
    color: "#FFC107",
    status: "TO DO",
  },
  {
    color: "#ff0000",
    status: "On Hold",
  },
  {
    color: "#00ff00",
    status: "Completed",
  },
  {
    color: "#7BDFE5",
    status: "In Progress",
  },
];

const StatusPopover = () => {
  const [isOpen, setIsOpen] = useState(false);

  const anchorRef = useRef(null);

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        ref={anchorRef}
        sx={{ alignItems: "center", display: "flex", ml: 2 }}
        variant="outlined"
      >
        ! Status
      </Button>

      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, p: 1 },
        }}
      >
        <Box sx={{ p: 1 }}>
          {data.map((item) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                py: 0.5,
              }}
              key={item.status}
            >
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                  background: item.color,
                }}
              />
              <Box sx={{ ml: 1, fontWeight: "bold" }}>{item.status}</Box>
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default StatusPopover;
