import React, { useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { Droppable, Draggable } from "react-beautiful-dnd";
import SingleTask from "./SingleTask";
import { AddCircle } from "@mui/icons-material";
import AddNewTaskModal from "../AddNewTaskModal";
const SingleMilestone = ({ item, i, selectedProject, getTaskList }) => {
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);

  return (
    <>
      {showAddTaskModal && (
        <AddNewTaskModal
          selectedProject={selectedProject}
          showAddTaskModal={showAddTaskModal}
          setShowAddTaskModal={setShowAddTaskModal}
          getTaskList={getTaskList}
          selectedMilestone={item.id}
        />
      )}
      <Draggable
        draggableId={item.id ? `m#${item.id}` : `nomilestone#${i}`}
        index={i}
      >
        {(provided, snapshot) => (
          <Box>
            <Box
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={{
                ...provided.draggableProps.style,
                boxShadow: snapshot.isDragging ? "0 0 0.6rem #999" : "none",
                background: snapshot.isDragging ? "#fff" : "none",
                border: snapshot.isDragging
                  ? `1px solid ${item.color}`
                  : "1px solid #ccc",
              }}
              sx={{
                pt: 1,
                mt: 2,
                borderRadius: "8px",
                borderLeft: `3px solid ${item.color} !important`,
              }}
            >
              {item.isMilestone ? (
                <Box
                  {...provided.dragHandleProps}
                  sx={{
                    display: "flex",
                    p: 1,
                    borderBottom: "1px solid #ddd",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box {...provided.dragHandleProps}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#222",
                        fontSize: "16px",
                      }}
                      {...provided.dragHandleProps}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                  <Box sx={{ height: "20px" }}>
                    <Box sx={{ height: "20px" }}>
                      <Tooltip title="Add Task">
                        <AddCircle
                          onClick={() => setShowAddTaskModal(true)}
                          sx={{
                            transition: "0.2s ease-in all",
                            cursor: "pointer",
                            "&:hover": {
                              transform: "rotate(90deg) scale(1.3)",
                              opacity: 0.8,
                            },
                          }}
                          fontSize="small"
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box {...provided.dragHandleProps}></Box>
              )}
              {item?.children.length > 0 ? (
                <Droppable droppableId={`dm#${item.id}`}>
                  {(provided, _) => (
                    <div ref={provided.innerRef}>
                      {item.children.map((item, i) => (
                        <SingleTask
                          selectedProject={selectedProject}
                          getTaskList={getTaskList}
                          key={i}
                          item={item}
                          i={i}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              ) : (
                <Droppable droppableId={`dm#${item.id}`}>
                  {(provided, _) => (
                    <div ref={provided.innerRef}>
                      <SingleTask
                        getTaskList={getTaskList}
                        selectedProject={selectedProject}
                        key={i}
                        item={{ id: `notask#${item.id}` }}
                        i={111111111}
                        noTask={true}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              )}
            </Box>
          </Box>
        )}
      </Draggable>
    </>
  );
};

export default SingleMilestone;
