import {
  Box,
  Breadcrumbs,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import SprintBoard from "../../components/sprint/SprintBoard";
import SprintDetailsActions from "../../components/sprint/SprintDetailsActions";
import { useFetch } from "../../hooks";
import moment from "moment";

export default function Sprint({ sprintId, selectedProject }) {
  const { projectId } = useParams();
  const { data: sprint } = useFetch(`/api/sprints/${sprintId}`);
  const [refreshCount, setRefreshCount] = useState(0);
  const [isColumn, setIsColumn] = useState(true);

  return (
    <Box p={2}>
      {/* Create an header with MUI components that contents Sprint title, start date and end date etc. */}
      <Grid container justifyContent="space-between" alignItems="center" px={2}>
        <Grid item>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<ChevronRight fontSize="small" />}
            sx={{ mt: 1 }}
          >
            <Link
              color="textSecondary"
              component={RouterLink}
              to={`/dashboard/projects/view-and-edit-project/${projectId}?p=sprints`}
              variant="breadCrumbText"
            >
              View All Sprints
            </Link>
            <Typography color="textSecondary" variant="breadCrumbText">
              {sprint?.name}
            </Typography>
          </Breadcrumbs>
          {/* <Typography variant="subtitle1" fontWeight={"bold"}>
            {sprint?.name}
          </Typography> */}
          <Typography variant="caption" component={"h1"}>
            Goal: {sprint?.goal}
          </Typography>
          <Typography variant="caption">
            Duration: {moment(sprint?.start_date).format("MMM DD, YYYY")} -{" "}
            {moment(sprint?.end_date).format("MMM DD, YYYY")}
          </Typography>
        </Grid>
        <Grid item display={"flex"} gap={2}>
          {sprint && (
            <SprintDetailsActions
              sprint={sprint}
              setRefreshCount={setRefreshCount}
              setIsColumn={setIsColumn}
              isColumn={isColumn}
              startDate={selectedProject?.start_date}
              endDate={selectedProject?.due_date}
            />
          )}
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      <SprintBoard
        sprintId={sprintId}
        refreshCount={refreshCount}
        isColumn={isColumn}
      />
    </Box>
  );
}
