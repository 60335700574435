import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getProjectID } from "../../api/share/Share";
import ProjectDashboard from "../../components/share/ProjectDashboard";
import SharedNav from "../../components/SharedNav";
const SharedProjectDashboard = () => {
  const { shareId } = useParams();
  const [projectDetails, setProjectDetails] = useState(null);

  useEffect(() => {
    handleGetProjectId();
  }, []);

  const handleGetProjectId = async () => {
    const res = await getProjectID(shareId);
    if (res.data.data.item) {
      setProjectDetails(res.data.data.item);
    }
  };
  return (
    <>
      <SharedNav />
      <Box sx={{ pt: 4, mt: 6 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "primary.main",
              display: "block",
              fontSize: "28px",
              fontWeight: "bold",
              pl: 2,
              textAlign: "center",
            }}
          >
            {projectDetails?.name}
          </Typography>
        </Box>
        <Typography sx={{ pl: 2, fontSize: "16px", textAlign: "center" }}>
          Status:{" "}
          {projectDetails?.status === 3
            ? "Delayed"
            : projectDetails?.status === 2
            ? "Completed"
            : "Work in progress"}
        </Typography>
      </Box>
      {projectDetails && (
        <ProjectDashboard
          selectedProject={projectDetails}
          projectID={projectDetails.id}
          authToken={shareId}
        />
      )}
    </>
  );
};

export default SharedProjectDashboard;
