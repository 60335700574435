import React, { useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import PencilAltIcon from "../../../../icons/PencilAlt";
import TrashIcon from "../../../../icons/Trash";
import ViewAndEditTaskModal from "../ViewAndEditTaskModal";
import { deleteTask } from "../../../../api/project/Project";
import toast from "react-hot-toast";
const SingleTask = ({
  item: task,
  i,
  noTask,
  selectedProject,
  getTaskList,
}) => {
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);

  const deleteProjectTask = async () => {
    const res = await deleteTask(task.id);
    if (res.data.success) {
      toast.success("Task deleted successfully");
      getTaskList();
    }
  };

  return (
    <>
      {showEditTaskModal && (
        <ViewAndEditTaskModal
          task={task}
          showEditTaskModal={showEditTaskModal}
          setShowEditTaskModal={setShowEditTaskModal}
          selectedProject={selectedProject}
          getTaskList={getTaskList}
        />
      )}
      <Draggable draggableId={`t#${task.id}`} index={i}>
        {(provided, snapshot) => (
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{
              ...provided.draggableProps.style,
              boxShadow: snapshot.isDragging ? "0 0 .4rem #666" : "none",
              background: snapshot.isDragging ? "#d7f5ff" : "none",
            }}
          >
            {!noTask ? (
              <Box
                {...provided.dragHandleProps}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  px: 1,
                  py: 1.5,
                  borderBottom: "1px solid #ddd",
                }}
              >
                <Box>
                  <Typography
                    className="treeview-text"
                    sx={{
                      minWidth: "140px",
                      maxWidth: "140px",
                      width: "30%",
                    }}
                  >
                    {task.name}
                  </Typography>
                </Box>
                <Box sx={{ width: "12%" }}>
                  <Typography className="treeview-text">
                    {task?.get_type?.type}
                  </Typography>
                </Box>
                <Box sx={{ width: "14%" }}>
                  <Typography className="treeview-text">
                    {task.get_status.status}
                  </Typography>
                </Box>
                <Box sx={{ width: "12%" }}>
                  <Typography className="treeview-text">
                    {task.start_date}
                  </Typography>
                </Box>
                <Box sx={{ width: "12%" }}>
                  <Typography className="treeview-text">
                    {task.end_date}
                  </Typography>
                </Box>
                <Box sx={{ width: "14%" }}>
                  <Typography className="treeview-text">
                    {task?.get_assigned_to
                      ? `${task?.get_assigned_to?.first_name} ${task?.get_assigned_to?.last_name}`
                      : "-"}
                  </Typography>
                </Box>
                <Box sx={{ width: "6%" }}>
                  <Tooltip title="Edit">
                    <PencilAltIcon
                      onClick={() => setShowEditTaskModal(true)}
                      className="action-icon"
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <TrashIcon
                      onClick={deleteProjectTask}
                      className="action-icon"
                    />
                  </Tooltip>
                </Box>
              </Box>
            ) : (
              <Box sx={{ py: 0.01 }} {...provided.dragHandleProps}>
                {" "}
              </Box>
            )}
          </Box>
        )}
      </Draggable>
    </>
  );
};

export default SingleTask;
