import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { Delete as DeleteIcon, FiberManualRecord } from "@mui/icons-material";
import React, { useEffect } from "react";
import { deleteReminder } from "../../../api/calender/Calender";
import { useFetch } from "../../../hooks/useAPI";

const SavedReminders = ({ userId, date }) => {
  const { data, loading, refetch } = useFetch(`api/reminder/${userId}/${date}`);
  const publicTodos = data?.public_todos || [];
  const myTodos = data?.my_todos || [];
  const allTodos = [...publicTodos, ...myTodos];

  const handleDelete = async (reminderId) => {
    try {
      await deleteReminder(reminderId);
      refetch?.();
    } catch (error) {
      console.error("Error deleting reminder:", error);
    }
  };

  const confirmDelete = (reminderId) => {
    if (window.confirm("Are you sure you want to delete this reminder?")) {
      handleDelete(reminderId);
    }
  };

  return (
    <Box mb={2} mt={3} sx={{ maxHeight: 400, overflowY: "auto" }}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : allTodos?.length === 0 ? (
        <Typography variant="body2" color="textSecondary">
          No to do found for this date.
        </Typography>
      ) : (
        <List sx={{ padding: 0 }}>
          {allTodos?.map((reminder) => (
            <ListItem
              key={reminder.id}
              sx={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #44444444",
                padding: "8px 16px",
                marginBottom: "0.5rem",
                borderRadius: "8px",
                boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                transition: "all 0.3s ease",
                "&:hover": {
                  backgroundColor: "#f4f4f4",
                  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                },
              }}
            >
              <FiberManualRecord
                style={{
                  color: "#3f51b5",
                  fontSize: 14,
                  marginRight: 12,
                }}
                fontSize="small"
              />
              <Box flex="1">
                <ListItemText
                  primary={reminder.reminder}
                  primaryTypographyProps={{
                    variant: "body1",
                    style: {
                      fontWeight: 500,
                      color: "#333",
                    },
                  }}
                  secondary={
                    <>
                      <Chip
                        label={reminder.tag}
                        color="primary"
                        size="small"
                        sx={{ marginRight: 1 }}
                      />
                      <Chip
                        label={reminder.is_public ? "Public" : "Private"}
                        color={reminder.is_public ? "success" : "default"}
                        size="small"
                      />
                    </>
                  }
                  secondaryTypographyProps={{
                    variant: "body2",
                    style: {
                      color: "#555",
                    },
                  }}
                />
              </Box>
              <IconButton
                edge="end"
                aria-label={`delete reminder ${reminder.reminder}`}
                onClick={() => confirmDelete(reminder.id)}
                sx={{
                  color: "#f44336",
                  "&:hover": {
                    color: "#d32f2f",
                  },
                }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default SavedReminders;
