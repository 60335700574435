import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  Grid,
  Typography,
  TextField,
  InputLabel,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import {
  fetchMemberScheduling,
  fetchScheduling,
} from "../../../api/scheduling/Scheduling";
import moment from "moment";
import ViewAllSchedules from "./ViewAllSchedules";
import useLoader from "../../../hooks/useLoader";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(5),
  },
}));

const DateRangeView = ({ memberId, setUtilizationDetails }) => {
  let currentDate = moment();
  let weekStart = currentDate.clone().startOf("isoWeek");
  const weekEnd = moment(weekStart).add(6, "days");
  const [startDate, setstartDate] = useState(weekStart);
  const [endDate, setEndDate] = useState(weekEnd);
  const [schedules, setSchedules] = useState([]);
  const { loaderState, loaderStart, loaderEnd } = useLoader();

  const classes = useStyles();

  useEffect(() => {
    getSchedulingData();
    return () => {
      setSchedules([]);
    };
  }, [startDate, endDate]);

  const getSchedulingData = async () => {
    loaderStart();
    let res;
    if (memberId) {
      res = await fetchMemberScheduling({
        startDate: startDate,
        endDate: endDate,
        userId: memberId || "",
      });
      if (res?.data?.data?.daySchedules?.data?.length > 0) {
        setUtilizationDetails({
          utilization: res?.data?.data?.utilization,
          availability: res?.data?.data?.availability,
        });
      } else {
        setUtilizationDetails({
          utilization: 0,
          availability: 100,
        });
      }
    } else {
      res = await fetchScheduling({
        startDate: startDate,
        endDate: endDate,
        userId: memberId || "",
      });
    }
    if (res?.data?.success) {
      if (memberId) {
        setSchedules(res.data.data.daySchedules);
      } else {
        setSchedules(res.data.data);
      }
    }
    loaderEnd();
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Week View</title>
      </Helmet>
      <Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <FormControl fullWidth required error={!startDate}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => {
                      if (newValue && moment.isMoment(newValue)) {
                        setstartDate(newValue);
                        setEndDate(newValue.clone().add(6, "days"));
                      }
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        variant: "outlined",
                        className: "pt-datepicker-ip",
                      },
                    }}
                  />
                </FormControl>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <FormControl fullWidth required error={!endDate}>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => {
                      if (newValue && moment.isMoment(newValue)) {
                        setEndDate(newValue);
                        setstartDate(newValue.clone().startOf("isoWeek"));
                      }
                    }}
                    minDate={startDate}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        variant: "outlined",
                        className: "pt-datepicker-ip",
                      },
                    }}
                  />
                </FormControl>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 1, p: 2 }}>
          {schedules?.data?.length > 0 ? (
            <ViewAllSchedules
              schedules={schedules}
              userView={memberId ? true : false}
            />
          ) : (
            <Typography
              variant="h6"
              color="secondary"
              align="center"
              sx={{ p: 4 }}
            >
              {loaderState ? "Loading..." : "No Records Available"}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default DateRangeView;
