import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { addProjectMember } from "../../../api/project/Project";
import useMounted from "../../../hooks/useMounted";

const AddAssignedToProjectMemberModal = ({
  selectedProject,
  addAssignedToProjectMembers,
  setaddAssignedToProjectMembers,
}) => {
  const mounted = useMounted();

  return (
    <>
      <Dialog
        open={addAssignedToProjectMembers}
        // onClose={() => setaddAssignedToProjectMembers(null)}
        minWidth={"md"}
        fullWidth={true}
      >
        <Formik
          // enableReinitialize
          initialValues={{
            projectHours: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            projectHours: Yup.number()
              .max(255)
              .required("Project Hours is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const memberData = {
                proj_id: selectedProject.id,
                proj_time: values.projectHours,
                member_id: addAssignedToProjectMembers,
                existing: 1,
                daily_work_hours: 8,
              };
              const res = await addProjectMember(memberData);

              if (res.data.success) {
                setaddAssignedToProjectMembers(null);
              }
              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              toast.error("Something went wrong");
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>
                Add Member <br />
                <Typography variant="body2" sx={{ mt: 1 }}>
                  The Person you have assigned task was not a member of this
                  project. Please enter daily project hours.
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="Daily project hours"
                      required
                      error={Boolean(
                        touched.projectHours && errors.projectHours
                      )}
                      fullWidth
                      helperText={touched.projectHours && errors.projectHours}
                      margin="normal"
                      name="projectHours"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.projectHours}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default AddAssignedToProjectMemberModal;
