import React from "react";
import { Card, CardHeader, Grid, Box } from "@mui/material";
import DashboardGanttChart from "../dashboard/portfolios/DashboardGanttChart";
import DashboardProgress from "../dashboard/portfolios/DashboardProgress";
import CostGraph from "../dashboard/portfolios/CostGraph";
import ProjectRevenue from "../dashboard/portfolios/ProjectRevenue";

const PortfolioDashboard = ({ portfolioID, authToken }) => {
  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <SinglePortfolioDashboard title="Project Gantt Chart">
          <DashboardGanttChart id={portfolioID} authToken={authToken} />
        </SinglePortfolioDashboard>
        <SinglePortfolioDashboard title="Progress" grid={{ md: 6, sm: 6 }}>
          <DashboardProgress id={portfolioID} authToken={authToken} />
        </SinglePortfolioDashboard>
        <SinglePortfolioDashboard title="Cost" grid={{ md: 6, sm: 6 }}>
          <CostGraph id={portfolioID} authToken={authToken} />
        </SinglePortfolioDashboard>
        {portfolioID === 202 && (
          <SinglePortfolioDashboard title="Revenue Chart">
            <ProjectRevenue />
          </SinglePortfolioDashboard>
        )}
      </Grid>
    </Box>
  );
};

const SinglePortfolioDashboard = ({
  title,
  styles = {},
  grid = {},
  children,
}) => {
  return (
    <>
      <Grid item xs={12} {...grid}>
        <Card
          sx={{
            boxShadow: "4px 4px 5px #00000050 !important",
            ...styles,
          }}
        >
          <CardHeader title={title} />
          {children}
        </Card>
      </Grid>
    </>
  );
};

export default PortfolioDashboard;
