import { Box, Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  useNavigate,
  useParams,
  useLocation,
  Navigate,
  Routes,
  Route,
} from "react-router-dom";
import useSettings from "../../hooks/useSettings";
import WorkflowSelection from "../../components/dashboard/projects/create-project/WorkflowSelection";
import ProjectStepper from "../../components/dashboard/projects/create-project/ProjectStepper";
import CreateProjectForm from "../../components/dashboard/projects/CreateProjectForm";
import SuccessView from "../../components/dashboard/projects/create-project/SuccessView";
import TeamMembersForm from "../../components/dashboard/projects/create-project/TeamMembersForm";

const STEPS = {
  workflow: 0,
  form: 1,
  team: 2,
  complete: 3,
};

export default function AddNewProject() {
  const navigate = useNavigate();
  const location = useLocation();
  const { settings } = useSettings();
  const [projectData, setProjectData] = useState(() => {
    const saved = sessionStorage.getItem("projectCreationData");
    return saved ? JSON.parse(saved) : null;
  });

  // Get current step from URL
  const getCurrentStep = () => {
    if (location.pathname.includes("/workflow")) return STEPS.workflow;
    if (location.pathname.includes("/form")) return STEPS.form;
    if (location.pathname.includes("/team")) return STEPS.team;
    if (location.pathname.includes("/complete")) return STEPS.complete;
    return STEPS.workflow;
  };

  const handleWorkflowSelect = (workflow) => {
    const data = { workflow: workflow.title };
    setProjectData(data);
    sessionStorage.setItem("projectCreationData", JSON.stringify(data));
    navigate("/dashboard/projects/new/form");
  };

  const handleProjectCreate = (newProjectData) => {
    const data = { ...projectData, ...newProjectData };
    setProjectData(data);
    sessionStorage.setItem("projectCreationData", JSON.stringify(data));
    navigate(`/dashboard/projects/new/team/${newProjectData.id}`);
  };

  const handleCreateAnother = () => {
    sessionStorage.removeItem("projectCreationData");
    setProjectData(null);
    navigate("/dashboard/projects/new/workflow");
  };

  // Redirect to workflow selection if accessing form without workflow
  if (location.pathname.includes("/form") && !projectData?.workflow) {
    return <Navigate to="/dashboard/projects/new/workflow" replace />;
  }

  // Redirect to form if accessing complete without project data
  if (location.pathname.includes("/complete") && !projectData?.id) {
    return <Navigate to="/dashboard/projects/new/workflow" replace />;
  }

  return (
    <>
      <Helmet>
        <title>Create New Project</title>
      </Helmet>
      <Box sx={{ backgroundColor: "background.default", minHeight: "100%" }}>
        <Container maxWidth={settings.compact ? "xl" : false} sx={{ pt: 3 }}>
          <ProjectStepper activeStep={getCurrentStep()} />

          <Box sx={{ py: 4 }}>
            <Routes>
              <Route
                path="workflow"
                element={
                  <WorkflowSelection onWorkflowSelect={handleWorkflowSelect} />
                }
              />
              <Route
                path="form"
                element={
                  <CreateProjectForm
                    selectedWorkFlow={projectData?.workflow}
                    onProjectCreated={handleProjectCreate}
                  />
                }
              />
              <Route
                path="team/:projectId"
                element={<TeamMembersForm projectData={projectData} />}
              />
              <Route
                path="complete/:projectId"
                element={
                  <SuccessView
                    projectData={projectData}
                    selectedWorkFlow={projectData?.workflow}
                    onCreateAnother={handleCreateAnother}
                  />
                }
              />
              <Route path="*" element={<Navigate to="workflow" replace />} />
            </Routes>
          </Box>
        </Container>
      </Box>
    </>
  );
}
