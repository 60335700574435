import { Box, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackArrow from "../icons/ArrowLeft";
import Logo from "./Logo";

const MainNavInfo = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        px: 2, // Adjust padding for smaller screens
        py: 1,
        borderBottom: "1px solid #D7DFEC",
        display: "flex",
        flexDirection: { xs: "column", sm: "row" }, // Stack columns on xs and switch to row on sm
        alignItems: { xs: "initial", sm: "center" }, // Align items differently for small screens
        justifyContent: "space-between",
        background: "#eeeff3",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: { xs: 1, sm: 0 }, // Add margin bottom on xs screens
        }}
      >
        <Tooltip title="Back">
          <BackArrow
            sx={{
              color: "#333",
              fontSize: "1rem",
              marginRight: "0.5rem",
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                cursor: "pointer",
                color: "#000",
                transform: "translateX(-0.2rem)",
              },
            }}
            onClick={() => navigate(-1)}
          />
        </Tooltip>
        <Logo height="18px" />
        <Typography
          sx={{
            fontSize: { xs: "12px", sm: "14px" },
            fontWeight: 400,
            color: "#486383",
            pl: { xs: 0, sm: 0.5 },
          }}
        >
          {" "}
          | Project Management System
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{ color: "#486383", fontSize: { xs: "10px", sm: "12px" } }}
        >
          info@projectric.com | B3.0
        </Typography>
      </Box>
    </Box>
  );
};

export default MainNavInfo;
