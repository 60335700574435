import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useFetch } from "../../hooks";
import BurnDownChart from "./Charts/BurnDownChart";
import GanttChart from "./Charts/GanttChart";
import MemberContributionBarChart from "./Charts/MemberContributionBarChart";
import SprintVelocityChart from "./Charts/SprintVelocityChart";
import TaskStatusPieChart from "./Charts/TaskStatusPieChart";
export default function Dashboard({ onSprintClick, selectedProjectID }) {
  const { projectId } = useParams();
  const { data: sprintsData } = useFetch(
    `/api/sprints/project/${projectId || selectedProjectID}`
  );
  // const { data: sprintsVelocityData, loading } = useFetch(
  //   `/api/sprints/velocity/${projectId || selectedProjectID}`
  // );
  const { data: contributionData } = useFetch(
    `/api/sprints/member-contribution/${projectId || selectedProjectID}`
  );
  const { data: burnDownData } = useFetch(
    `/api/sprints/burn-down/${projectId || selectedProjectID}`
  );
  const { data: taskDistributionData } = useFetch(
    `/api/sprints/task-status-distribution/${projectId || selectedProjectID}`
  );

  return (
    <Grid container spacing={2}>
      {/* Sprint Gantt Chart */}
      <Grid item md={12}>
        <Card>
          <CardHeader title="Sprint Gantt Chart" />
          {!sprintsData ? (
            <CircularProgress />
          ) : (
            <GanttChart data={sprintsData} onSprintClick={onSprintClick} />
          )}
        </Card>
      </Grid>
      {/* Sprint Member Contribution Chart */}
      <Grid item md={6}>
        <Card>
          <CardHeader title="Task Distribution Among Members In All Sprints" />
          {!contributionData ? (
            <CircularProgress />
          ) : (
            <MemberContributionBarChart data={contributionData} />
          )}
        </Card>
      </Grid>
      {/* Sprint Task Status Pie Chart */}
      <Grid item md={6}>
        <Card sx={{ height: 400 }}>
          <CardHeader title="All Sprint Task Status In Pie Chart" />
          {!taskDistributionData ? (
            <CircularProgress />
          ) : (
            <TaskStatusPieChart data={taskDistributionData} />
          )}
        </Card>
      </Grid>

      {/* Sprint Velocity Chart */}
      {/* <Grid item md={12}>
        <Card>
          <CardHeader title="Overall Sprint Velocity Of Project" />
          {loading ? (
            <CircularProgress />
          ) : (
            <SprintVelocityChart data={sprintsVelocityData || []} />
          )}
        </Card>
      </Grid> */}

      {/* Sprint Burn-Down Chart */}
      <Grid item md={12}>
        <Card>
          <CardContent>
            {!burnDownData ? <></> : <BurnDownChart data={burnDownData} />}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
