import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Drawer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { ChromePicker } from "react-color";
import { sprintTaskPriorities } from "../../@const/sprintTaskPriorities";
import { useFetch } from "../../hooks/useAPI";

// Duration calculation
const calculateDuration = (start, finish) => {
  const startDate = moment(start);
  const finishDate = moment(finish);
  return finishDate.diff(startDate, "days") + " days";
};

// Calculate completion percentage based on task status
const calculateCompletion = (status) => {
  const completionMapping = {
    backlog: 0,
    "to-dos": 25,
    "in-progress": 50,
    "q/a": 75,
    done: 100,
  };
  return completionMapping[status] || 0;
};

// Status color determination
const determineStatusColor = (status, finish) => {
  const today = moment();
  const finishDate = moment(finish);

  if (status === "done") {
    return "Green";
  } else if (today.isAfter(finishDate)) {
    return "Red";
  } else if (finishDate.diff(today, "days") <= 2) {
    return "Yellow";
  } else {
    return "Blue";
  }
};

// Map for status chip colors
const statusColors = {
  Red: "error",
  Yellow: "warning",
  Green: "success",
  Blue: "info",
};

export default function Report() {
  const { data } = useFetch(`/api/project-search?workflow_type=agile`);
  const projectsWithSprints = data?.data?.projects?.data;
  const [selectedProject, setSelectedProject] = useState(null);
  const { data: projectSprints } = useFetch(
    `/api/sprints/report/${selectedProject?.id}`
  );

  // State for dynamic colors
  const [colors, setColors] = useState({
    sprintRow: "#E3F2FD",
    sprintText: "#1565C0",
    taskRowEven: "#FAFAF4",
    taskRowOdd: "#F7EFEE",
    emptyMessageBg: "#FFF8E1",
    emptyMessageText: "#FF6F00",
    tableHeader: "#1976D2",
    tableHeaderText: "#FFFFFF",
    projectSummaryBg: "#E8EAF6",
    projectSummaryText: "#283593",
  });

  // Drawer state
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Color picker handlers
  const handleColorChange = (key, color) => {
    setColors((prev) => ({ ...prev, [key]: color.hex }));
  };

  const calculateProjectCompletionPercentage = (projectSprints) => {
    if (!projectSprints || !projectSprints.length) return 0;

    const allTasks = projectSprints.flatMap(
      (sprint) => sprint?.tasks_with_status || []
    );

    const totalCompletion = allTasks.reduce((acc, task) => {
      const taskCompletion = calculateCompletion(
        task?.get_sprint_status?.status
      );
      return acc + taskCompletion;
    }, 0);

    const averageCompletion = allTasks.length
      ? totalCompletion / allTasks.length
      : 0;

    return Math.round(averageCompletion);
  };

  return (
    <Box p={3}>
      <Box display="flex" alignItems="center" gap="1rem" marginBottom="1rem">
        {/* Project Selector */}
        <Autocomplete
          options={projectsWithSprints}
          getOptionLabel={(option) => option?.name}
          value={selectedProject}
          onChange={(_e, newValue) => setSelectedProject(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Project"
              variant="outlined"
              fullWidth
            />
          )}
          style={{ flex: 1 }}
        />
        {/* Customize Table Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setDrawerOpen(true)}
        >
          Customize Table
        </Button>
      </Box>

      {/* Sprint and Task Table */}
      <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  backgroundColor: colors.tableHeader,
                  color: colors.tableHeaderText,
                  fontWeight: "bold",
                }}
              >
                Sprint
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: colors.tableHeader,
                  color: colors.tableHeaderText,
                  fontWeight: "bold",
                }}
              >
                Task
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: colors.tableHeader,
                  color: colors.tableHeaderText,
                  fontWeight: "bold",
                }}
              >
                Priority
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: colors.tableHeader,
                  color: colors.tableHeaderText,
                  fontWeight: "bold",
                }}
              >
                Start Date
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: colors.tableHeader,
                  color: colors.tableHeaderText,
                  fontWeight: "bold",
                }}
              >
                End Date
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: colors.tableHeader,
                  color: colors.tableHeaderText,
                  fontWeight: "bold",
                }}
              >
                Duration
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: colors.tableHeader,
                  color: colors.tableHeaderText,
                  fontWeight: "bold",
                }}
              >
                Status
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: colors.tableHeader,
                  color: colors.tableHeaderText,
                  fontWeight: "bold",
                }}
              >
                % Complete
              </TableCell>
            </TableRow>
          </TableHead>
          {selectedProject && (
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={2}
                  style={{
                    backgroundColor: colors.projectSummaryBg,
                    color: colors.projectSummaryText,
                    fontWeight: "bold",
                  }}
                >
                  Project Summary: {selectedProject.name}
                </TableCell>
                <TableCell>{selectedProject.get_priority.priority}</TableCell>
                <TableCell>
                  {moment(selectedProject.start_date).format("MMM DD, YYYY")}{" "}
                </TableCell>
                <TableCell>
                  {moment(selectedProject.due_date).format("MMM DD, YYYY")}{" "}
                </TableCell>
                <TableCell>
                  {calculateDuration(
                    selectedProject.start_date,
                    selectedProject.due_date
                  )}
                </TableCell>
                <TableCell>{selectedProject.get_status.status}</TableCell>
                <TableCell>
                  {calculateProjectCompletionPercentage(projectSprints)}%
                  Complete
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {projectSprints?.map((sprint, sprintIndex) => (
              <React.Fragment key={sprintIndex}>
                <TableRow>
                  <TableCell
                    rowSpan={sprint?.tasks_with_status?.length + 1}
                    style={{
                      fontWeight: "bold",
                      backgroundColor: colors.sprintRow,
                      color: colors.sprintText,
                    }}
                  >
                    {sprint.name}
                  </TableCell>
                </TableRow>
                {sprint?.tasks_with_status?.map((task, taskIndex) => (
                  <TableRow
                    key={taskIndex}
                    style={{
                      backgroundColor:
                        taskIndex % 2 === 0
                          ? colors.taskRowEven
                          : colors.taskRowOdd,
                    }}
                  >
                    <TableCell style={{ fontWeight: "bold" }}>
                      {task.name}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {sprintTaskPriorities
                        .find((priority) => priority.id === task?.priority)
                        ?.priority?.toUpperCase() || ""}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {moment(task.start_date).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {moment(task.end_date).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {calculateDuration(task.start_date, task.end_date)}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      <Chip
                        label={task?.get_sprint_status?.status?.toUpperCase()}
                        color={
                          statusColors[
                            determineStatusColor(
                              task?.get_sprint_status?.status,
                              task.end_date
                            )
                          ]
                        }
                        variant="outlined"
                        size="small"
                      />
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {calculateCompletion(task?.get_sprint_status?.status)}%
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
            {!projectSprints?.length && (
              <TableRow>
                <TableCell
                  colSpan={8}
                  align="center"
                  style={{
                    backgroundColor: colors.emptyMessageBg,
                    color: colors.emptyMessageText,
                    fontWeight: "bold",
                  }}
                >
                  No sprints available for this project
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Drawer for Color Customization */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box p={3} width="300px">
          <h4>Customize Table Colors</h4>
          <Box display="flex" flexDirection="column" gap="1rem">
            <div>
              <p>Sprints</p>
              <ChromePicker
                color={colors.sprintRow}
                onChangeComplete={(color) =>
                  handleColorChange("sprintRow", color)
                }
              />
            </div>
            <div>
              <p>Task Row (Even)</p>
              <ChromePicker
                color={colors.taskRowEven}
                onChangeComplete={(color) =>
                  handleColorChange("taskRowEven", color)
                }
              />
            </div>
            <div>
              <p>Task Row (Odd)</p>
              <ChromePicker
                color={colors.taskRowOdd}
                onChangeComplete={(color) =>
                  handleColorChange("taskRowOdd", color)
                }
              />
            </div>
            <div>
              <p>Empty Message Background</p>
              <ChromePicker
                color={colors.emptyMessageBg}
                onChangeComplete={(color) =>
                  handleColorChange("emptyMessageBg", color)
                }
              />
            </div>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setDrawerOpen(false)}
            style={{ marginTop: "1rem" }}
          >
            Close
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
}
