import React, { useState, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography,
  Card,
} from "@mui/material";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import moment from "moment";
import { fetchAvailableMembers } from "../../api/scheduling/Scheduling";
import ViewAllSchedules from "../../components/dashboard/scheduling/ViewAllSchedules";
import useLoader from "../../hooks/useLoader";

const AvailableMembers = () => {
  const [schedules, setSchedules] = useState([]);
  const { loaderState, loaderStart, loaderEnd } = useLoader();

  let currentDate = moment();
  let weekStart = currentDate.clone().startOf("isoWeek");
  const weekEnd = moment(weekStart).add(6, "days").format("YYYY-MM-DD");

  const { settings } = useSettings();

  useEffect(() => {
    getMembersAvailable();
  }, []);

  const getMembersAvailable = async () => {
    loaderStart();
    const res = await fetchAvailableMembers({
      startDate: weekStart,
      endDate: weekEnd,
    });
    if (res?.data?.success) {
      setSchedules(res.data.data.daySchedules);
    }
    loaderEnd();
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Members Available</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Members Available
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                Members Available
              </Typography>
            </Grid>
          </Grid>
          <Card sx={{ mt: 2 }}>
            <Box>
              <Box sx={{ borderBottom: "1px solid #D7DFEC" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                ></Box>
              </Box>
              <Box sx={{ mt: 1, p: 2 }}>
                {schedules?.data?.length > 0 ? (
                  <ViewAllSchedules schedules={schedules} />
                ) : (
                  <Typography
                    variant="h6"
                    color="secondary"
                    align="center"
                    sx={{ p: 4 }}
                  >
                    {loaderState ? "Loading..." : "No Records Available"}
                  </Typography>
                )}
              </Box>
            </Box>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default AvailableMembers;
