import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { getPortfolioDashboardProgress } from "../../../api/portfolio/Portfolio";
import SingleProgressGraph from "./SingleProgressGraph";
const DashboardProgress = ({ id, authToken = null }) => {
  const [graphData, setGraphData] = useState({});
  useEffect(() => {
    getGraph();
  }, []);

  const getGraph = async () => {
    const res = await getPortfolioDashboardProgress(id, authToken);
    if (res.data.data.values) {
      setGraphData(res.data.data.values);
    }
  };
  return (
    <Grid container spacing={2} sx={{ pl: 2 }}>
      <Grid item xs={3} sx={{ pl: 0, mb: 2 }}>
        <Typography sx={{ fontSize: "14px !important", fontWeight: "bold" }}>
          Project
        </Typography>
      </Grid>
      <Grid item xs={9} sx={{ pl: 0, mb: 2 }}>
        <Typography sx={{ fontSize: "14px !important", fontWeight: "bold" }}>
          Percent Complete
        </Typography>
      </Grid>
      <Box
        className="scroll-shadows"
        sx={{
          overflowY: "auto",
          width: "100%",
          height: "360px",
          pr: 0.5,
        }}
      >
        {graphData.length > 0 &&
          graphData.map((item, index) => (
            <SingleProgressGraph data={item} key={index} />
          ))}
      </Box>
    </Grid>
  );
};

export default DashboardProgress;
