import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography,
  Tab,
  Tabs,
  Card,
} from "@mui/material";

import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import ArchiveProjects from "../../components/dashboard/admin/archive/ArchiveProjects";
import ArchiveTasks from "../../components/dashboard/admin/archive/ArchiveTasks";
import ArchivePortfolios from "../../components/dashboard/admin/archive/ArchivePortfolios";
import ArchiveUsers from "../../components/dashboard/admin/archive/ArchiveUsers";
import ArchiveMilestone from "../../components/dashboard/admin/archive/ArchiveMilestone";

const tabs = [
  { value: "projects", label: "Projects" },
  { value: "tasks", label: "Tasks" },
  { value: "users", label: "Users" },
  { value: "milestone", label: "Milestone" },
  { value: "portfolio", label: "Portfolio" },
];

const Archive = () => {
  const { settings } = useSettings();

  const [currentTab, setCurrentTab] = useState("projects");
  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };
  return (
    <>
      <Helmet>
        <title>Dashboard: Requested Users |</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Admin Settings
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                Archive
              </Typography>
              <Box
                sx={{
                  mt: 0.5,
                }}
              ></Box>
            </Grid>
          </Grid>
          <Card>
            <Box
              sx={{
                mt: 3,
                borderBottom: "1px solid #D7DFEC",
              }}
            >
              <Tabs
                indicatorColor="primary"
                onChange={handleTabsChange}
                scrollButtons="auto"
                textColor="primary"
                value={currentTab}
                variant="scrollable"
                sx={{
                  ".MuiTab-wrapper": {
                    textTransform: "none",
                    fontSize: "12px",
                  },
                  ".Mui-selected": {
                    fontWeight: "bold",
                    color: "#000",
                  },
                  button: {
                    minWidth: "80px",
                    "&:hover": {
                      backgroundColor: "#eee",
                    },
                  },
                  ".MuiTabs-indicator": {
                    minWidth: "60px",
                  },
                  px: 2,
                }}
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
            </Box>
            <Box sx={{ px: 3, py: 2 }}>
              {currentTab === "projects" && <ArchiveProjects />}
              {currentTab === "tasks" && <ArchiveTasks />}
              {currentTab === "users" && <ArchiveUsers />}
              {currentTab === "portfolio" && <ArchivePortfolios />}
              {currentTab === "milestone" && <ArchiveMilestone />}
            </Box>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Archive;
