import React, { useState } from "react";
import { Formik } from "formik";
import {
  TextField,
  Box,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { updatValidityDates } from "../../../api/user/User";
import moment from "moment";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CompanyDetailsAddModal = ({
  companyDetails,
  showEditTaskModal,
  setShowEditTaskModal,
  getCompanies,
}) => {
  const classes = useStyles();
  const [validFrom, setValidFrom] = useState(
    moment(companyDetails.valid_from_date || Date.now())
  );
  const [validTo, setValidTo] = useState(
    moment(companyDetails.valid_to_date || Date.now())
  );

  return (
    <>
      <Dialog
        open={showEditTaskModal}
        onClose={() => setShowEditTaskModal(false)}
      >
        <Formik
          initialValues={{
            subscriptionType: companyDetails.current_status || "",
            notes: companyDetails.notes || "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            subscriptionType: Yup.number()
              .typeError("Please select subscription type")
              .max(255)
              .required("Subscription type is required"),
            notes: Yup.string(),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const res = await updatValidityDates(
                companyDetails.id,
                moment(validFrom).format("YYYY/MM/DD/"),
                moment(validTo).format("YYYY/MM/DD/"),
                values.subscriptionType,
                values.notes
              );
              if (res.data.success) {
                toast.success("Company data updated successfully");
                getCompanies();
                setShowEditTaskModal(false);
              }
            } catch (error) {
              console.error(error);
              toast.error("Something went wrong");
              setStatus({ success: false });
              setErrors({ submit: error.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>Edit Company Details</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        required
                        error={!validFrom}
                      >
                        <DatePicker
                          label="Valid From"
                          value={validFrom}
                          onChange={(newValue) => {
                            if (newValue && moment.isMoment(newValue)) {
                              setValidFrom(newValue);
                            }
                          }}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              variant: "outlined",
                              className: "pt-datepicker-ip",
                            },
                          }}
                        />
                      </FormControl>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        required
                        error={!validTo}
                      >
                        <DatePicker
                          label="Valid To"
                          value={validTo}
                          onChange={(newValue) => {
                            if (newValue && moment.isMoment(newValue)) {
                              setValidTo(newValue);
                            }
                          }}
                          minDate={validFrom}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              variant: "outlined",
                              className: "pt-datepicker-ip",
                            },
                          }}
                        />
                      </FormControl>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      className={classes.formControl}
                      error={Boolean(
                        touched.subscriptionType && errors.subscriptionType
                      )}
                      required
                    >
                      <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                        Subscription Type
                      </InputLabel>
                      <Select
                        labelId="select-status"
                        label="Subscription Type *"
                        name="subscriptionType"
                        value={values.subscriptionType}
                        fullWidth
                        placeholder="Status"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        <MenuItem value={1}>Subscribed</MenuItem>
                        <MenuItem value={2}>Trial</MenuItem>
                        <MenuItem value={3}>POC</MenuItem>
                      </Select>
                      <FormHelperText>
                        {touched.subscriptionType && errors.subscriptionType}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Notes"
                      required
                      error={Boolean(touched.notes && errors.notes)}
                      fullWidth
                      helperText={touched.notes && errors.notes}
                      margin="normal"
                      name="notes"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                  <Box sx={{ ml: 1 }}>
                    <Button
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      onClick={() => setShowEditTaskModal(false)}
                      sx={{ backgroundColor: "#C0CBDE" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default CompanyDetailsAddModal;
