import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  getArchivedProjects,
  restoreArchivedProject,
} from "../../../../api/archive/Archive";
import useLoader from "../../../../hooks/useLoader";
import SingleArchiveRow from "./SingleArchiveRow";
const ArchiveProjects = () => {
  const [archivedProjects, setArchivedProjects] = useState([]);
  const { loaderStart, loaderEnd } = useLoader();

  useEffect(() => {
    getAllArchivedProjects();
  }, []);

  const getAllArchivedProjects = async () => {
    loaderStart();
    const res = await getArchivedProjects();
    if (res.data.data.items) {
      setArchivedProjects(res.data.data.items);
    }
    loaderEnd();
  };
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Deleted At</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {archivedProjects.map((project) => (
          <SingleArchiveRow
            key={project.id}
            item={project}
            onSuccess={getAllArchivedProjects}
            onDelete={restoreArchivedProject}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default ArchiveProjects;
