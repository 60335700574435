import React, { useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
const FileUploader = ({
  onFileSelect,
  onFileSelectSuccess,
  onFileSelectError,
}) => {
  const [fileName, setFileName] = useState(null);
  const fileInput = useRef(null);

  const handleFileInput = (e) => {
    // handle validations
    const file = e.target.files[0];
    setFileName(file.name);
    onFileSelectSuccess(file);
  };

  return (
    <Box>
      <Box
        sx={{
          background: "rgba(9, 43, 177, 0.178);",
          alignItems: "center",
          border: 1,
          borderRadius: 1,
          borderColor: "divider",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          outline: "none",
          mt: 1,
          "&:hover": {
            backgroundColor: "rgba(9, 43, 177, 0.178)",
            cursor: "pointer",
            opacity: 0.5,
          },
        }}
      >
        <input
          type="file"
          onChange={handleFileInput}
          ref={fileInput}
          hidden
          multiple={false}
        />
        <div
          onClick={(e) => fileInput.current && fileInput.current.click()}
          className="fileuploader btn btn-primary"
        >
          <h4>{!fileName ? " Upload File" : "Change File"}</h4>
        </div>
      </Box>
      <Box>
        <Typography variant="small">{fileName}</Typography>
      </Box>
    </Box>
  );
};

export default FileUploader;
