import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  addProjectMember,
  getUsersUnderAdmin,
  getProjectMembers,
} from "../../../../api/project/Project";
import * as Yup from "yup";
import { Formik } from "formik";
import useMounted from "../../../../hooks/useMounted";

const TeamMembersForm = ({ projectData }) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const mounted = useMounted();
  const [memberList, setMemberList] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeData = async () => {
      try {
        setIsLoading(true);
        await Promise.all([getMemberList(), fetchExistingMembers()]);
      } catch (error) {
        console.error("Error initializing data:", error);
        toast.error("Failed to load team members");
      } finally {
        setIsLoading(false);
      }
    };

    initializeData();
  }, [projectId]);

  const fetchExistingMembers = async () => {
    try {
      const response = await getProjectMembers(projectId);
      if (response?.data?.success) {
        const existingMembers = response.data.data.members.map((member) => ({
          id: member.id,
          first_name: member.first_name,
          last_name: member.last_name,
          proj_time: member.pivot?.proj_time,
        }));
        setSelectedMembers(existingMembers);
      }
    } catch (error) {
      console.error("Error fetching existing members:", error);
    }
  };

  const getMemberList = async () => {
    try {
      const res = await getUsersUnderAdmin(1, 0);
      if (res.data.success) {
        setMemberList(res.data.data.peopleDropdown);
      }
    } catch (error) {
      console.error("Error fetching member list:", error);
    }
  };

  const handleSkip = () => {
    navigate(`/dashboard/projects/new/complete/${projectId}`);
  };

  // Filter out already selected members from the dropdown
  const getAvailableMembers = () => {
    return memberList.filter(
      (member) => !selectedMembers.some((selected) => selected.id === member.id)
    );
  };

  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Add Team Members
      </Typography>
      <Typography color="textSecondary" sx={{ mb: 3 }}>
        Add team members to your project or skip this step to add them later
      </Typography>

      {isLoading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            projectHours: "",
            selectedMember: null,
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            projectHours: Yup.number()
              .max(255)
              .required("Project Hours is required"),
            selectedMember: Yup.object()
              .nullable()
              .required("Member is required"),
          })}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm }
          ) => {
            try {
              const memberData = {
                proj_id: projectId,
                proj_time: values.projectHours,
                existing: 1,
                member_id: values.selectedMember?.id,
              };

              const res = await addProjectMember(memberData);

              if (res.data.success) {
                toast.success("New member added");
                setSelectedMembers([
                  ...selectedMembers,
                  {
                    ...values.selectedMember,
                    proj_time: values.projectHours,
                  },
                ]);
                resetForm();
              }
              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              toast.error("Something went wrong");
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    id="member-select"
                    blurOnSelect={true}
                    options={getAvailableMembers()}
                    onChange={(e, value) =>
                      setFieldValue("selectedMember", value)
                    }
                    value={values.selectedMember}
                    ListboxProps={{ style: { maxHeight: "150px" } }}
                    getOptionLabel={(option) =>
                      `${option.first_name} ${option.last_name}`
                    }
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        key={`${option.first_name} ${option.last_name} ${option.id}`}
                      >
                        {`${option.first_name} ${option.last_name}`}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Member"
                        error={Boolean(
                          touched.selectedMember && errors.selectedMember
                        )}
                        helperText={
                          touched.selectedMember && errors.selectedMember
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Daily project hours"
                    required
                    error={Boolean(touched.projectHours && errors.projectHours)}
                    fullWidth
                    helperText={touched.projectHours && errors.projectHours}
                    margin="normal"
                    name="projectHours"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.projectHours}
                    variant="outlined"
                    disabled={!values.selectedMember}
                  />
                </Grid>

                {errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Grid>
                )}

                {selectedMembers.length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      Added Members:
                    </Typography>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                      {selectedMembers.map((member) => (
                        <Typography
                          key={member.id}
                          variant="body2"
                          sx={{
                            bgcolor: "primary.light",
                            color: "primary.contrastText",
                            px: 2,
                            py: 1,
                            borderRadius: 1,
                          }}
                        >
                          {member.first_name} {member.last_name} (
                          {member.proj_time} hrs/day)
                        </Typography>
                      ))}
                    </Box>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Add Member
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleSkip}
                    >
                      {selectedMembers.length > 0 ? "Continue" : "Skip"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      )}
    </Card>
  );
};

export default TeamMembersForm;
