import { Box, Typography } from "@mui/material";
import React from "react";
import Logo from "../../components/Logo";
import RequestPendingIcon from "../../icons/RequestPending.svg";
const RequestPending = () => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          p: 2,
          height: "100vh",
        }}
      >
        <Box
          component="img"
          src={RequestPendingIcon}
          sx={{ width: "100%", maxWidth: "300px" }}
        />

        <Typography variant="h2" align="center" sx={{ pt: 4 }}>
          Request Pending
        </Typography>
        <Typography variant="h6" align="center" sx={{ py: 2 }}>
          We will inform you once your request has been accepted!
        </Typography>
        <Logo />
      </Box>
    </Box>
  );
};

export default RequestPending;
