import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
import ViewAllCredentialsListTable from "../../components/dashboard/admin/ViewAllCredentialsListTable";
import useAuth from "../../hooks/useAuth";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";

const ViewAllNewUsers = () => {
  const { settings } = useSettings();

  const { getAllUsers, allUsers, acceptUserRequest } = useAuth();
  useEffect(() => {
    getAllUsers();
  }, []);

  const handleAllUser = (pageNum, roleFilter, userNameSearch) =>
    getAllUsers(pageNum, roleFilter, userNameSearch);

  const handleAdminRequest = async (uid, userState) => {
    const res = await acceptUserRequest(uid, userState);
    return res;
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: View All New Users</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Admin Settings
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                New Registrations
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <ViewAllCredentialsListTable
              allUsers={allUsers}
              handleAllUser={handleAllUser}
              handleAdminRequest={handleAdminRequest}
              showOnlyNewUsers
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ViewAllNewUsers;
