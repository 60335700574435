import React from "react";
import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import SplashScreen from "./components/SplashScreen";
import useAuth from "./hooks/useAuth";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";
import routes from "./routes";
import { createCustomTheme } from "./theme";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/system";

import "./App.scss";

const App = () => {
  const content = useRoutes(routes);

  const { settings } = useSettings();
  const auth = useAuth();
  useScrollReset();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster position="top-center" />
      {auth.isInitialized ? content : <SplashScreen />}
    </ThemeProvider>
  );
};

export default App;
