import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  getArchivedUsers,
  restoreArchivedUser,
} from "../../../../api/archive/Archive";
import useLoader from "../../../../hooks/useLoader";
import SingleArchiveRow from "./SingleArchiveRow";
const ArchiveUsers = () => {
  const [archivedUsers, setArchivedUsers] = useState([]);
  const { loaderStart, loaderEnd } = useLoader();

  useEffect(() => {
    getAllArchivedUsers();
  }, []);

  const getAllArchivedUsers = async () => {
    loaderStart();
    const res = await getArchivedUsers();
    if (res.data.data.items) {
      setArchivedUsers(res.data.data.items);
    }
    loaderEnd();
  };
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Deleted At</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {archivedUsers.map((project) => (
          <SingleArchiveRow
            key={project.id}
            item={project}
            onSuccess={getAllArchivedUsers}
            onDelete={restoreArchivedUser}
            type="user"
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default ArchiveUsers;
