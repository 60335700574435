import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { ChevronRight, DeleteOutlineOutlined, ContentCopyOutlined } from "@mui/icons-material";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink, useParams } from "react-router-dom";
import AddSprint from "../../components/sprint/AddSprint";
import { useFetch } from "../../hooks";
import { handleApiRequest } from "../../hooks/callApi";
import useSettings from "../../hooks/useSettings";
import moment from "moment";

export default function Sprints({ onlySprint, onListClick, selectedProject }) {
  const { settings } = useSettings();
  const { projectId } = useParams();
  const { data: sprintsData, refetch: refetchSprints } = useFetch(
    `/api/sprints/project/${projectId || selectedProject.id}`
  );

  return (
    <Box sx={{ backgroundColor: "background.default", minHeight: "100%" }}>
      <Container maxWidth={settings.compact ? "xl" : false}>
        {!onlySprint && (
          <Helmet>
            <title>Dashboard: View All Sprints |</title>
          </Helmet>
        )}
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            {!onlySprint && (
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRight fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Sprints
                </Typography>
              </Breadcrumbs>
            )}
            <Box>
              <Typography color="textPrimary" variant="dashboardHeading">
                Sprints
              </Typography>
              {!!onlySprint && (
                <AddSprint
                  refetchSprints={refetchSprints}
                  startDate={selectedProject?.start_date}
                  endDate={selectedProject?.due_date}
                />
              )}
            </Box>
          </Grid>
        </Grid>
        <List>
          {sprintsData?.map((sprint) => (
            <ListItem
              key={sprint.id}
              divider
              button
              onClick={(e) => {
                e.stopPropagation();
                onListClick?.(sprint.id);
                // navigate(
                //   `/dashboard/projects/view-and-edit-project/${sprint.project_id}/sprints/${sprint.id}`
                // );
              }}
            >
              <ListItemText
                primary={sprint.name}
                secondary={`${moment(sprint.start_date).format(
                  "MMM DD, YYYY"
                )} - ${moment(sprint.end_date).format("MMM DD, YYYY")}`}
                primaryTypographyProps={{
                  variant: "subtitle1",
                  fontWeight: "bold",
                }}
                secondaryTypographyProps={{ variant: "caption" }}
              />

              <Tooltip title="Copy Sprint">
                <IconButton
                  size="small"
                  onClick={async (e) => {
                    e.stopPropagation();
                    const response = await handleApiRequest(
                      `/api/sprints/${sprint.id}/copy`,
                      "post"
                    );
                    console.log(response);
                    await refetchSprints();
                  }}
                  sx={{ mr: 1 }}
                >
                  <ContentCopyOutlined fontSize="inherit" color="primary" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete Sprint">
                <IconButton
                  size="small"
                  onClick={async (e) => {
                    e.stopPropagation();
                    const response = await handleApiRequest(
                      `/api/sprints/${sprint.id}`,
                      "delete"
                    );
                    console.log(response);
                    await refetchSprints();
                  }}
                >
                  <DeleteOutlineOutlined fontSize="inherit" color="error" />
                </IconButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      </Container>
    </Box>
  );
}
