import { createTheme } from "@mui/material";
import merge from "lodash/merge";
import { THEMES } from "../constants";
import { lightShadows } from "./shadows";
import { responsiveFontSizes } from "@mui/material";

const baseOptions = {
  direction: "ltr",
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: "75%",
          width: "75%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          height: "100%",
          width: "100%",
        },
        body: {
          height: "100%",
        },
        "#root": {
          height: "100%",
        },
        "#nprogress .bar": {
          zIndex: "2000 !important",
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: "h6",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: "hidden",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          marginRight: "16px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: '10px !important',
        },
      },
    },
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily:
      '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: "3.5rem",
    },
    h2: {
      fontWeight: 600,
      fontSize: "3rem",
    },
    h3: {
      fontWeight: 600,
      fontSize: "2.25rem",
    },
    h4: {
      fontWeight: 600,
      fontSize: "2rem",
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    h6: {
      fontWeight: 600,
      fontSize: "1.125rem",
    },
    dashboardHeading: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "24px",
      lineHeight: "36px",
    },
    breadCrumbText: {
      fontStyle: "normal",
      fontSize: "12px",
      lineHeight: "18px",
    },
    dashboardInfo: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "24px",
    },
    dashboardContentHeader: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "18px",
      lineHeight: "28px",
    },
    overline: {
      fontWeight: 600,
    },
  },
};

const themesOptions = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            padding: "padding: 13.5px 14px",
            "&::placeholder": {
              opacity: 0.86,
              color: "#A0AABB",
            },
          },
        },
      },
    },
    palette: {
      action: {
        active: "#F7B31E",
      },
      background: {
        default: "#F8F9FB",
        paper: "#ffffff",
      },
      error: {
        contrastText: "#ffffff",
        main: "#f44336",
      },
      mode: "light",
      primary: {
        contrastText: "#ffffff",
        main: "#F7B31E",
        orange: "#FD863A",
        blue: "#3F51B5",
      },
      success: {
        contrastText: "#ffffff",
        main: "#4caf50",
      },
      text: {
        primary: "#2D3E56",
        secondary: "#486383",
        green: "#4caf50",
        blue: "#2196f3",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#ff9800",
      },
      breadCrumbColor: {
        main: "#8A94A6",
      },
    },
    shadows: lightShadows,
  },
  [THEMES.DARK]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            padding: "padding: 13.5px 14px",
            "&::placeholder": {
              opacity: 0.86,
              color: "#A0AABB",
            },
          },
        },
      },
    },
    palette: {
      action: {
        active: "#F7B31E",
      },
      background: {
        default: "#F8F9FB",
        paper: "#ffffff",
      },
      error: {
        contrastText: "#ffffff",
        main: "#f44336",
      },
      mode: "light",
      primary: {
        contrastText: "#ffffff",
        main: "#F7B31E",
        orange: "#FD863A",
        blue: "#3F51B5",
      },
      success: {
        contrastText: "#ffffff",
        main: "#4caf50",
      },
      text: {
        primary: "#2D3E56",
        secondary: "#486383",
        green: "#4caf50",
        blue: "#2196f3",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#ff9800",
      },
      breadCrumbColor: {
        main: "#8A94A6",
      },
    },
    shadows: lightShadows,
  },
  [THEMES.NATURE]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            padding: "padding: 13.5px 14px",
            "&::placeholder": {
              opacity: 0.86,
              color: "#A0AABB",
            },
          },
        },
      },
    },
    palette: {
      action: {
        active: "#F7B31E",
      },
      background: {
        default: "#F8F9FB",
        paper: "#ffffff",
      },
      error: {
        contrastText: "#ffffff",
        main: "#f44336",
      },
      mode: "light",
      primary: {
        contrastText: "#ffffff",
        main: "#F7B31E",
        orange: "#FD863A",
        blue: "#3F51B5",
      },
      success: {
        contrastText: "#ffffff",
        main: "#4caf50",
      },
      text: {
        primary: "#2D3E56",
        secondary: "#486383",
        green: "#4caf50",
        blue: "#2196f3",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#ff9800",
      },
      breadCrumbColor: {
        main: "#8A94A6",
      },
    },
    shadows: lightShadows,
  },
};

export const createCustomTheme = (config = {}) => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  let theme = createTheme(
    merge(
      {},
      baseOptions,
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 4,
          },
        }),
      },
      {
        direction: config.direction,
      }
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
