import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link, TableCell, TableRow } from "@mui/material";

import CheckIcon from "../../../icons/Check";
import TrashIcon from "../../../icons/Trash";
import moment from "moment";
import useAuth from "../../../hooks/useAuth";

const SinglePeopleRequest = ({
  user,
  refreshUserList,
  handleAdminRequest,
  index,
}) => {
  const { user: currentUser } = useAuth();
  return (
    <TableRow
      hover
      key={index}
      sx={{
        td: {
          fontSize: "12px",
          a: {
            fontSize: "12px",
          },
        },
      }}
    >
      <TableCell
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          maxWidth: "120px",
          overflow: "hidden",
        }}
      >
        {index + 1}
      </TableCell>
      <TableCell>
        <Link
          color="textPrimary"
          component={RouterLink}
          to="#"
          underline="none"
          variant="subtitle2"
          title={user.name}
        >
          {`${user.first_name} ${user.last_name}`}
        </Link>
      </TableCell>
      <TableCell>{user.email}</TableCell>
      {currentUser.role === 1 && (
        <TableCell>{user?.company?.company_name}</TableCell>
      )}
      {currentUser.role === 1 && (
        <TableCell>{user?.company?.code ? user?.company?.code : "-"}</TableCell>
      )}
      <TableCell>
        {user.created_at ? moment(user.created_at).format("MM-DD-YYYY") : "-"}
      </TableCell>
      <TableCell align="right">
        <CheckIcon
          className="action-icon"
          onClick={async () => {
            await handleAdminRequest(user.id, 1);
            refreshUserList();
          }}
        />
        <TrashIcon title="delete" className="action-icon" />
      </TableCell>
    </TableRow>
  );
};

export default SinglePeopleRequest;
