import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { getAllTemplates, deleteTemplate } from "../../api/admin/Template";
import CreateTemplateModal from "../../components/dashboard/admin/CreateTemplateModal";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import PlusIcon from "../../icons/Plus";
import DeleteIcon from "@mui/icons-material/Delete";

const ManageExcelTemplate = () => {
  const { settings } = useSettings();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const handleCreateModalOpen = () => setCreateModalOpen(true);
  const handleCreateModalClose = () => setCreateModalOpen(false);

  const fetchTemplates = async () => {
    const res = await getAllTemplates();
    if (res.data) {
      setTemplates(res.data);
    }
  };

  const handleDelete = async (id) => {
    await deleteTemplate(id);
    fetchTemplates(); // Refresh the list after deleting
  };

  return (
    <>
      {createModalOpen && (
        <CreateTemplateModal
          open={createModalOpen}
          onClose={handleCreateModalClose}
          fetchTemplates={fetchTemplates}
        />
      )}
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "80vh",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Manage Templates
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                Manage Templates
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  startIcon={<PlusIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={handleCreateModalOpen}
                >
                  Create Template
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3, width: "100%" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">URL</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {templates.map((template) => (
                  <TableRow key={template.id}>
                    <TableCell align="left">{template.name}</TableCell>
                    <TableCell align="left">{template.description}</TableCell>
                    <TableCell align="left">
                      <a
                        href={template.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View
                      </a>
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        color="secondary"
                        onClick={() => handleDelete(template.id)}
                      >
                        <DeleteIcon fontSize="small" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ManageExcelTemplate;
