import { TableCell, TableRow } from "@mui/material";
import React from "react";
import toast from "react-hot-toast";
import Minus from "../../../icons/Minus";
import Plus from "../../../icons/Plus";
const SingleIntakeForm = ({ room, increaseOccupiedRooms }) => {
  const manageIncrement = () => {
    if (room.occupiedRooms < room.totalRoom) {
      increaseOccupiedRooms(room.id, 1);
      toast.success("Room Occupied");
    } else {
      toast.error("Room is full");
    }
  };
  const manageDecrement = () => {
    if (room.occupiedRooms > 0) {
      increaseOccupiedRooms(room.id, -1);
      toast.success("Room Vacated");
    } else {
      toast.error("Room is empty");
    }
  };
  return (
    <TableRow hover>
      <TableCell>{room.name}</TableCell>
      <TableCell>{room.status}</TableCell>
      <TableCell>{room.contact_person}</TableCell>
      <TableCell>{room.email}</TableCell>
      <TableCell align="right">{room.created_on}</TableCell>
      <TableCell align="right">{room.totalRoom}</TableCell>
      <TableCell
        align="right"
        sx={{
          color: room.totalRoom > room.occupiedRooms ? "#0aF" : "#f00",
        }}
      >
        {room.occupiedRooms}
      </TableCell>
      <TableCell align="center">
        <Plus
          title="Delete"
          onClick={manageIncrement}
          className="action-icon"
        />
        <Minus
          title="Delete"
          onClick={manageDecrement}
          className="action-icon"
        />
      </TableCell>
    </TableRow>
  );
};

export default SingleIntakeForm;
