import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  getGanttChartData,
  getProjectTasks,
} from "../../../api/project/Project";
import { Typography } from "@mui/material";
import AddNewTaskModal from "./AddNewTaskModal";
import useLoader from "../../../hooks/useLoader";
import HighchartsReact from "highcharts-react-official";
// Import Highcharts
import Highcharts from "highcharts";
import highchartsGantt from "highcharts/modules/gantt";
import highchartsDraggablePoints from "highcharts/modules/draggable-points";
import moment from "moment";
import ImportTaskModal from "./ImportTaskModal";
import { useParams } from "react-router-dom";

// Initialize Highcharts modules
highchartsGantt(Highcharts);
highchartsDraggablePoints(Highcharts);

const AllTasksGanttChartView = ({
  selectedProject,
  showAddTaskModal,
  setShowAddTaskModal,
  showImportTaskModal,
  setShowImportTaskModal,
  shared,
}) => {
  const [ganttData, setGanttData] = useState([]);
  const [allTasks, setAllTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { loaderState, loaderStart, loaderEnd } = useLoader();
  const chartRef = useRef(null);
  const [chartOptions, setChartOptions] = useState({
    series: [{ data: [] }],
  });
  const { shareId } = useParams();

  const updateRemoveButtonStatus = useCallback((task) => {
    console.log("updateRemoveButtonStatus", task);
  }, []);

  const getTaskList = useCallback(
    async (isMounted = true) => {
      if (!selectedProject?.id) return;

      try {
        loaderStart();
        const res = await getProjectTasks({
          projectId: selectedProject.id,
          page: currentPage,
          shared,
          authToken: shareId,
        });
        if (isMounted && res.data.success) {
          setAllTasks(res.data.data.tasks);
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
      } finally {
        if (isMounted) {
          loaderEnd();
        }
      }
    },
    [selectedProject?.id, currentPage, shared, shareId, loaderStart, loaderEnd]
  );

  const loadGanttData = useCallback(
    async (isMounted = true) => {
      if (!selectedProject?.id) return;

      try {
        loaderStart();
        const res = await getGanttChartData(
          selectedProject.id,
          shared,
          shareId
        );

        if (!isMounted) return;

        if (res?.data?.data?.ganttTaskData.length > 0) {
          const ganttData = res.data.data.ganttTaskData;

          const ganttDataWithUnixDate = ganttData.map((obj) => {
            if (obj.x && obj.x2) {
              const x1date = moment(obj.x, "YYYY-MM-DD")
                .format("YYYY-MM-DD")
                .split("-");
              const nextEndDay = moment(obj.x2, "YYYY-MM-DD")
                .add(obj.x === obj.x2 ? 1 : 0, "days")
                .format("YYYY-MM-DD");
              const x2date = nextEndDay.split("-");
              const m1 = +x1date[1] - 1;
              const m2 = +x2date[1] - 1;
              const x = Date.UTC(x1date[0], m1, x1date[2]);
              const x2 = Date.UTC(x2date[0], m2, x2date[2]);

              return { ...obj, x, x2 };
            }
            return obj;
          });

          const minDate = moment(selectedProject.start_date, "YYYY-MM-DD")
            .add(-10, "days")
            .format("YYYY-MM-DD")
            .split("-");
          const maxDate = moment(selectedProject.due_date, "YYYY-MM-DD")
            .add(10, "days")
            .format("YYYY-MM-DD")
            .split("-");

          if (isMounted) {
            setChartOptions({
              chart: { type: "xrange", panning: true, height: 600 },
              credits: { enabled: false },
              xAxis: {
                type: "datetime",
                min: Date.UTC(minDate[0], minDate[1] - 1, minDate[2]),
                max: Date.UTC(maxDate[0], maxDate[1] - 1, maxDate[2]),
                dateTimeLabelFormats: {
                  day: "%e-%b",
                  week: "%e %b %Y",
                  month: "%b",
                },
                currentDateIndicator: true,
              },
              rangeSelector: {
                enabled: true,
                selected: 3,
                zIndex: 100,
                position: { y: 0 },
                inputPosition: { align: "left" },
                buttonPosition: { align: "right" },
                inputStyle: {
                  fontSize: "14px",
                  fontWeight: "800",
                  color: "#092bb1",
                  fill: "#092bb1",
                },
                buttons: [
                  { type: "month", count: 12, text: "12m" },
                  { type: "month", count: 3, text: "3m" },
                  { type: "day", count: 21, text: "3w" },
                  { type: "all", text: "All" },
                ],
              },
              yAxis: {
                uniqueNames: true,
                min: 0,
                max: 8,
                scrollbar: { enabled: true },
              },
              scrollbar: { enabled: true, liveRedraw: true },
              plotOptions: {
                series: {
                  minPointLength: 5,
                  dragDrop: {
                    draggableX: true,
                    draggableY: true,
                    dragMinY: 2,
                    dragMaxY: 2,
                    liveRedraw: false,
                    dragPrecisionX: 8,
                  },
                  allowPointSelect: true,
                  point: {
                    events: {
                      select: updateRemoveButtonStatus,
                      unselect: updateRemoveButtonStatus,
                      remove: updateRemoveButtonStatus,
                      drop: (event) => console.log("drop", event),
                      drag: (event) => console.log("drag", event),
                    },
                  },
                },
              },
              tooltip: {
                formatter: function () {
                  const dt1 = new Date(this.x);
                  const dt2 = new Date(this.x2);
                  dt2.setDate(dt2.getDate() - (this.x === this.x2 ? 1 : 0));

                  return `<b>Name: ${this.key}</b><br/>
                  Start Date: <b>${dt1.getDate()}-${
                    dt1.getMonth() === 0 ? 12 : dt1.getMonth() + 1
                  }-${dt1.getFullYear()}</b><br/>
                  End Date: <b>${dt2.getDate()}-${
                    dt2.getMonth() === 0 ? 12 : dt2.getMonth() + 1
                  }-${dt2.getFullYear()}</b>`;
                },
              },
              series: [{ data: ganttDataWithUnixDate, cursor: "move" }],
            });
            setGanttData(ganttDataWithUnixDate);
          }
        }
      } catch (error) {
        console.error("Error loading Gantt data:", error);
      } finally {
        if (isMounted) {
          loaderEnd();
        }
      }
    },
    [
      selectedProject?.id,
      shared,
      shareId,
      updateRemoveButtonStatus,
      loaderStart,
      loaderEnd,
    ]
  );

  useEffect(() => {
    let isMounted = true;

    loadGanttData(isMounted);
    getTaskList(isMounted);

    return () => {
      isMounted = false;
      loaderEnd();

      // Cleanup Highcharts
      if (chartRef.current) {
        const chart = chartRef.current.chart;
        if (chart) {
          chart.destroy();
        }
      }
    };
  }, [loadGanttData, getTaskList, loaderEnd]);

  return (
    <div>
      {showImportTaskModal && (
        <ImportTaskModal
          selectedProject={selectedProject}
          showImportTaskModal={showImportTaskModal}
          setShowImportTaskModal={setShowImportTaskModal}
          onSuccess={() => getTaskList(true)}
        />
      )}
      {showAddTaskModal && (
        <AddNewTaskModal
          selectedProject={selectedProject}
          showAddTaskModal={showAddTaskModal}
          setShowAddTaskModal={setShowAddTaskModal}
          allTasks={allTasks}
          getTaskList={() => getTaskList(true)}
        />
      )}
      {ganttData?.length > 0 ? (
        // <FrappeGantt
        //   tasks={ganttData}
        //   viewMode="Day"
        //   onClick={(task) => console.log(task)}
        //   onDateChange={(task, start, end) => console.log(task, start, end)}
        //   onProgressChange={(task, progress) => console.log(task, progress)}
        //   onTasksChange={(tasks) => console.log(tasks)}
        // />
        <HighchartsReact
          constructorType="ganttChart"
          highcharts={Highcharts}
          options={chartOptions}
          ref={chartRef}
        />
      ) : (
        <Typography align="center" sx={{ p: 4 }} variant="h6" color="secondary">
          {loaderState ? "Loading..." : "Add task to see the gantt chart"}
        </Typography>
      )}
    </div>
  );
};

export default AllTasksGanttChartView;
