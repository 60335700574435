import React from "react";
import {
  FormHelperText,
  TextField,
  Box,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import useLoader from "../../../hooks/useLoader";
import { uploadDocumentLink } from "../../../api/project/Project";
import useAuth from "../../../hooks/useAuth";
import toast from "react-hot-toast";

const AddDocumentLink = ({
  selectedProject,
  setShowAddDoumentLinkModal,
  showAddDoumentLinkModal,
  getProjectDocuments,
}) => {
  const { loaderStart, loaderEnd } = useLoader();
  const { user } = useAuth();
  return (
    <>
      <Dialog
        open={showAddDoumentLinkModal}
        onClose={() => setShowAddDoumentLinkModal(false)}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <Formik
          initialValues={{
            documentTitle: "",
            documentLink: "",
            documentExtension: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            documentTitle: Yup.string()
              .max(2555)
              .required("Document Title is required"),
            documentLink: Yup.string()
              .max(2555)
              .required("Document Link is required"),
            documentExtension: Yup.string()
              .max(2555)
              .required("Document Extension is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            loaderStart();
            try {
              const res = await uploadDocumentLink({
                projectId: selectedProject.id,
                documentTitle: values.documentTitle,
                uploadedBy: user.id,
                documentLink: values.documentLink,
                documentExtension: values.documentExtension,
              });

              if (res.data.success) {
                toast.success("Document Link Uploaded");
                getProjectDocuments();
                setShowAddDoumentLinkModal(false);
              }
              setStatus({ success: true });
              setSubmitting(false);
              loaderEnd();
            } catch (err) {
              console.error(err);
              toast.error("Something went wrong");
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
              loaderEnd();
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>Add Document Link</DialogTitle>
              <DialogContent
                sx={{ pt: 3, minWidth: "300px", textAlign: "center" }}
              >
                <Grid container spacing={4} rowSpacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      label="Document Link"
                      required
                      error={Boolean(
                        touched.documentLink && errors.documentLink
                      )}
                      fullWidth
                      helperText={touched.documentLink && errors.documentLink}
                      margin="normal"
                      name="documentLink"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.documentLink}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                    <TextField
                      label="Document title"
                      required
                      error={Boolean(
                        touched.documentTitle && errors.documentTitle
                      )}
                      fullWidth
                      helperText={touched.documentTitle && errors.documentTitle}
                      margin="normal"
                      name="documentTitle"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.documentTitle}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                    <TextField
                      label="Document Type"
                      required
                      error={Boolean(
                        touched.documentExtension && errors.documentExtension
                      )}
                      fullWidth
                      helperText={
                        touched.documentExtension && errors.documentExtension
                      }
                      margin="normal"
                      name="documentExtension"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.documentExtension}
                      variant="outlined"
                      sx={{ mt: 0 }}
                      select
                      SelectProps={{ native: true }}
                    >
                      <option value=""></option>
                      <option value="img">Image</option>
                      <option value="pdf">PDF</option>
                      <option value="doc">Doc</option>
                      <option value="xxl">Excel</option>
                      <option value="unknown">Other</option>
                    </TextField>
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                  <Box sx={{ ml: 1 }}>
                    <Button
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      onClick={() => setShowAddDoumentLinkModal(false)}
                      sx={{ backgroundColor: "#C0CBDE" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default AddDocumentLink;
