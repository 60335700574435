import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  getArchivedTasks,
  restoreArchivedTask,
} from "../../../../api/archive/Archive";
import useLoader from "../../../../hooks/useLoader";
import SingleArchiveRow from "./SingleArchiveRow";
const ArchiveTasks = () => {
  const [archivedTasks, setArchivedTasks] = useState([]);
  const { loaderStart, loaderEnd } = useLoader();

  useEffect(() => {
    getAllArchivedTasks();
  }, []);

  const getAllArchivedTasks = async () => {
    loaderStart();
    const res = await getArchivedTasks();
    if (res.data.data.items) {
      setArchivedTasks(res.data.data.items);
    }
    loaderEnd();
  };
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Project</TableCell>
          <TableCell>Milestone</TableCell>
          <TableCell>Deleted At</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {archivedTasks.map((task) => (
          <SingleArchiveRow
            key={task.id}
            item={task}
            type="task"
            onSuccess={getAllArchivedTasks}
            onDelete={restoreArchivedTask}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default ArchiveTasks;
