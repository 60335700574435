import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Pagination,
} from "@mui/material";
import SinglePeopleRequest from "./SinglePeopleRequest";
import useAuth from "../../../hooks/useAuth";
import useLoader from "../../../hooks/useLoader";

const PendingCredentialsListTable = (props) => {
  const { userRequests, handleRequestedUser, handleAdminRequest, ...other } =
    props;

  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    handleRequestedUser(currentPage);
  }, [currentPage]);

  const refreshUserList = () => {
    handleRequestedUser(currentPage);
  };

  const { user } = useAuth();
  const { loaderState } = useLoader();

  return (
    <Card {...other}>
      <Box
        sx={{
          m: -1,
          p: 3,
        }}
      >
        <Typography
          variant="dashboardContentHeader"
          sx={{
            color: "#2D3E56",
            mb: "10px",
            display: "block",
          }}
        >
          All Pending Requests
        </Typography>
        <Typography
          sx={{
            color: "#2D3E56",
            mb: "18px",
            display: "block",
            fontSize: "14px",
          }}
        >
          (Click ✓ under <b>Action Bar</b> to grant user permission)
        </Typography>
        <Box
          sx={{
            m: 1,
            maxWidth: "100%",
            width: 240,
          }}
        ></Box>
      </Box>
      <Box sx={{ pt: 3 }}>
        <Table>
          <TableHead sx={{ th: { fontSize: "12px" } }}>
            <TableRow>
              <TableCell sx={{ px: 2 }}>#</TableCell>
              <TableCell>MEMBER NAME</TableCell>
              <TableCell>EMAIL</TableCell>
              {user.role === 1 && <TableCell>COMPANY NAME</TableCell>}
              {user.role === 1 && <TableCell>COMPANY CODE</TableCell>}
              <TableCell>DATE CREATED</TableCell>
              <TableCell align="right">ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userRequests?.data?.length > 0 &&
              userRequests?.data.map((user, index) => {
                return (
                  <SinglePeopleRequest
                    user={user}
                    key={index}
                    index={index}
                    handleAdminRequest={handleAdminRequest}
                    refreshUserList={refreshUserList}
                  />
                );
              })}
          </TableBody>
        </Table>
        <Box sx={{ py: 4, display: "flex", justifyContent: "center" }}>
          {userRequests?.data?.length > 0 ? (
            <Pagination
              page={currentPage}
              onChange={handleChange}
              count={userRequests.last_page}
            />
          ) : (
            <Typography variant="h6" color="secondary">
              {loaderState ? "Loading..." : "No Records Available"}
            </Typography>
          )}
        </Box>
      </Box>
    </Card>
  );
};

PendingCredentialsListTable.propTypes = {
  invoices: PropTypes.array.isRequired,
};

export default PendingCredentialsListTable;
