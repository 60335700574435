import React from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { Formik } from "formik";
import { updateProjectStage } from "../../../api/project/Project";
import useMounted from "../../../hooks/useMounted";
const Stages = (props) => {
  const mounted = useMounted();
  const { selectedProject } = props;

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <Box
          sx={{
            maxWidth: "110px",
            width: "100%",
            minHeight: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#3700ff",
            clipPath: "polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)",
          }}
        >
          <Typography
            sx={{ fontSize: "11px", color: "#fff", fontWeight: "bold" }}
          >
            Request
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: "110px",
            width: "100%",
            minHeight: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#dd1971",
            pl: 1,
            clipPath:
              "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)",
          }}
        >
          <Typography
            sx={{ fontSize: "11px", color: "#fff", fontWeight: "bold" }}
          >
            Approve
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: "110px",
            width: "100%",
            minHeight: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#cbac07",
            clipPath:
              "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)",
          }}
        >
          <Typography
            sx={{ fontSize: "11px", color: "#fff", fontWeight: "bold" }}
          >
            Start
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: "110px",
            width: "100%",
            minHeight: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#cb1307",
            clipPath:
              "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)",
          }}
        >
          <Typography
            sx={{ fontSize: "11px", color: "#fff", fontWeight: "bold" }}
          >
            Build
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: "110px",
            width: "100%",
            minHeight: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#1da310",
            clipPath:
              "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)",
          }}
        >
          <Typography
            sx={{ fontSize: "11px", color: "#fff", fontWeight: "bold" }}
          >
            QA
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: "110px",
            width: "100%",
            minHeight: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#558fff",
            clipPath:
              "polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)",
          }}
        >
          <Typography
            sx={{ fontSize: "11px", color: "#fff", fontWeight: "bold" }}
          >
            Go Live!
          </Typography>
        </Box>
      </Box>
      <Formik
        initialValues={{
          projectStage: selectedProject.stage || "",
        }}
        validationSchema={Yup.object().shape({
          projectStage: Yup.string().max(255).required("Select Stage "),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const res = await updateProjectStage(
              selectedProject.id,
              values.projectStage
            );
            if (res.data.success) {
              toast.success("Project Stage Updated Successfully");
            }
            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            toast.error("Something went wrong");
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit} {...props}>
            <Box>
              <Typography
                variant="h5"
                sx={{
                  fontSize: "14px",
                }}
              >
                Project Stage:{" "}
                {selectedProject?.stage === 1
                  ? "Request"
                  : selectedProject?.stage === 2
                  ? "Approve"
                  : selectedProject?.stage === 3
                  ? "Start"
                  : selectedProject?.stage === 4
                  ? "Build"
                  : selectedProject?.stage === 5
                  ? "QA"
                  : selectedProject?.stage === 6
                  ? "Go Live!"
                  : "Not Selected"}
              </Typography>
            </Box>
            <Box
              sx={{
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                color="textPrimary"
                sx={{ mb: 1, fontSize: "14px", fontWeight: "500", mr: 2 }}
                variant="subtitle2"
              >
                Update Project Stage
              </Typography>
              <TextField
                margin="normal"
                name="projectStage"
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
                sx={{ mt: 0, minWidth: "350px" }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.projectStage}
              >
                <option value="">None</option>
                <option value={1}>Request</option>
                <option value={2}>Approve</option>
                <option value={3}>Start</option>
                <option value={4}>Build</option>
                <option value={5}>QA</option>
                <option value={6}>Go Live!</option>
              </TextField>
            </Box>
            <Box sx={{ mt: 4 }}>
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                disabled={isSubmitting}
              >
                SUBMIT
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Stages;
