import { Box, Button, Typography } from "@mui/material";
import React from "react";

const ExportTable = ({ data, total, takeScreenShot }) => {
  // ExportTable is a component that is used to export the table to excel
  const exportToExcel = () => {
    const table = document.getElementById("table-to-xls");
    const rows = table.querySelectorAll("tr");
    const newRows = [];
    rows.forEach((row) => {
      const cols = row.querySelectorAll("td, th");
      const newCols = [];
      cols.forEach((col) => {
        newCols.push(col.innerText);
      });
      newRows.push(newCols.join(","));
    });
    const csv = newRows.join("\r\n");
    console.log(newRows, csv);
    const link = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURI(csv));
    link.setAttribute("download", "table.csv");
    link.click();
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{ float: "left", marginLeft: "-8px !important" }}
        >
          Budget Summary
        </Typography>
        <Box>
          <Button
            variant="outlined"
            size="small"
            sx={{
              backgroundColor: "#fff",
              color: "blue",
              border: "1px solid blue",
              mr: 2,
            }}
            onClick={takeScreenShot}
          >
            Capture
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              backgroundColor: "#fff",
              color: "blue",
              border: "1px solid blue",
            }}
            onClick={exportToExcel}
          >
            Export to Excel
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: "none" }}>
        <table id="table-to-xls">
          <thead>
            <tr>
              <th>Date</th>
              <th>Budget ($)</th>
              <th>Actual ($)</th>
              <th>Variance ($)</th>
              <th>Percentage (%)</th>
              <th>Notes</th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th>Summary</th>
              <th>{total.totalBudget}</th>
              <th>{total.totalActual}</th>
              <th>{Math.floor(total.totalVariance)}</th>
              <th>{Math.floor(total.percentage)}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <React.Fragment key={item.id}>
                <tr>
                  <td>{item.date}</td>
                  <td>{item.budget}</td>
                  <td>{item.actual}</td>
                  <td>{Math.floor(item.variance)}</td>
                  <td>{Math.floor(item.percentage)}</td>
                  <td>{item.notes}</td>
                </tr>
                {item.children.map((child) => (
                  <tr key={child.id}>
                    <td>{child.summery}</td>
                    <td>{child.budget}</td>
                    <td>{child.actual}</td>
                    <td>{Math.floor(child.variance)}</td>
                    <td>{Math.floor(child.percentage)}</td>
                    <td>{child.notes}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </Box>
    </div>
  );
};

export default ExportTable;
