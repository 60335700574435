import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import timelinePlugin from "@fullcalendar/timeline";
import { Box, Card, Container, Dialog, styled } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";
import { validateToken } from "../../api/calender/Calender";
import { CalendarEventForm } from "../../components/dashboard/calendar";
import SharedCalendarToolbar from "../../components/dashboard/calendar/SharedCalendarToolbar";
import {
  closeModal,
  getEvents,
  openModal,
  selectEvent,
  selectRange,
  updateEvent,
} from "../../slices/calendar";
import { useDispatch, useSelector } from "../../store";

const selectedEventSelector = (state) => {
  const { events, selectedEventId } = state.calendar;

  if (selectedEventId) {
    return events.find((_event) => _event.id === selectedEventId);
  }

  return null;
};

const FullCalendarWrapper = styled("div")(({ theme }) => ({
  "& .fc-license-message": {
    display: "none",
  },
  "& .fc": {
    "--fc-bg-event-opacity": 1,
    "--fc-border-color": theme.palette.divider,
    "--fc-daygrid-event-dot-width": "10px",
    "--fc-event-text-color": theme.palette.text.primary,
    "--fc-list-event-hover-bg-color": theme.palette.background.default,
    "--fc-neutral-bg-color": theme.palette.background.default,
    "--fc-page-bg-color": theme.palette.background.default,
    "--fc-today-bg-color": (theme.palette.primary.main, 0.25),
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
  },
  "& .fc .fc-col-header-cell-cushion": {
    paddingBottom: "10px",
    paddingTop: "10px",
  },
  "& .fc .fc-day-other .fc-daygrid-day-top": {
    color: theme.palette.text.secondary,
  },
  "& .fc-daygrid-event": {
    padding: "10px",
  },
  "& .fc-timeGridWeek-view": {
    "& .fc-scrollgrid-section-liquid": {
      display: "none",
    },
    "& .projectric-all-day": {
      height: "600px",
    },
  },
  "& .fc-timeGridDay-view": {
    "& .fc-scrollgrid-section-liquid": {
      display: "none",
    },
    "& .projectric-all-day": {
      height: "600px",
    },
  },
  "& .fc-dayGridMonth-view": {
    "& .fc-daygrid-day-number": {
      width: "100%",
    },
  },
}));

const SharedCalendar = () => {
  const { userId } = useParams();
  // Get query parameter t value as token
  const { search } = useLocation();
  const token = new URLSearchParams(search).get("t");
  const dispatch = useDispatch();
  const calendarRef = useRef(null);
  const mobileDevice = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { events, isModalOpen, selectedRange } = useSelector(
    (state) => state.calendar
  );
  const selectedEvent = useSelector(selectedEventSelector);
  const [date, setDate] = useState(new Date());
  const [reminderDate, setReminderDate] = useState(null);
  const [view, setView] = useState(mobileDevice ? "listWeek" : "dayGridMonth");

  useEffect(() => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      // Check if userId is available
      if (!userId) {
        // redirect to un-authenticated page
        window.location.href = "/un-authenticated";
      }
      const calendarApi = calendarEl.getApi();
      const startDate = moment(calendarApi.view.activeStart).format(
        "YYYY-MM-DD"
      );
      const endDate = moment(calendarApi.view.activeEnd).format("YYYY-MM-DD");
      // checkIfValidUser(token);
      dispatch(getEvents(userId, startDate, endDate));
    }
  }, [date, userId]);

  const checkIfValidUser = async () => {
    const res = validateToken(token);
    if (res.data) {
      console.log("Valid token");
      return true;
    } else {
      console.log("Invalid token");
      window.location.href = "/un-authenticated";
    }
  };

  useEffect(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const newView = mobileDevice ? "listWeek" : "dayGridMonth";

      calendarApi.changeView(newView);
      setView(newView);
    }
  }, [mobileDevice]);

  useEffect(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      if (view === "timeGridWeek") {
        calendarApi.today();
        const startDate = calendarApi.getDate();
        startDate.setDate(startDate.getDate() - startDate.getDay());
        setDate(startDate);
      }
      // calendarApi.gotoDate(startDate);
      if (view === "timeGridDay") {
        calendarApi.today();
        setDate(calendarApi.getDate());
      }
    }
  }, [view]);

  const handleDateToday = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleViewChange = (newView) => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleDatePrev = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleDateNext = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const handleAddClick = () => {
    dispatch(openModal());
  };

  const handleRangeSelect = (arg) => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.unselect();
    }

    dispatch(selectRange(arg.start.getTime(), arg.end.getTime()));
  };

  const handleEventSelect = (arg) => {
    dispatch(selectEvent(arg.event.id));
  };

  const handleAddReminder = (date) => {
    setReminderDate(date);
  };

  const handleEventResize = async ({ event }) => {
    try {
      await dispatch(
        updateEvent(event.id, {
          allDay: event.allDay,
          start: event.start,
          end: event.end,
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handleEventDrop = async ({ event }) => {
    try {
      await dispatch(
        updateEvent(event.id, {
          allDay: event.allDay,
          start: event.start,
          end: event.end,
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handleModalClose = () => {
    dispatch(closeModal());
  };

  return (
    <>
      <Helmet>
        <title>Shared Calendar</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ mt: 3 }}>
            <SharedCalendarToolbar
              date={date}
              onDateNext={handleDateNext}
              onDatePrev={handleDatePrev}
              onDateToday={handleDateToday}
              onViewChange={handleViewChange}
              view={view}
            />
          </Box>
          <Card
            sx={{
              mt: 3,
              p: 2,
            }}
          >
            <FullCalendarWrapper>
              <FullCalendar
                // all Day row class
                allDayClassNames="projectric-all-day"
                dayMaxEventRows={4}
                // droppable
                // editable
                eventClick={handleEventSelect}
                eventDisplay="block"
                eventDrop={handleEventDrop}
                eventResizableFromStart
                eventResize={handleEventResize}
                displayEventTime={false}
                events={events}
                headerToolbar={false}
                height={view === "listWeek" ? "auto" : 800}
                initialDate={date}
                initialView={view}
                weekends={true}
                plugins={[
                  dayGridPlugin,
                  interactionPlugin,
                  listPlugin,
                  timeGridPlugin,
                  timelinePlugin,
                ]}
                // Customize cell in Month view to show number of events
                dayCellContent={(arg) => {
                  const events = arg.view.calendar.getEvents();
                  const dayEvents = events.filter((event) => {
                    return (
                      event.start.getDate() === arg.date.getDate() &&
                      event.start.getMonth() === arg.date.getMonth() &&
                      event.start.getFullYear() === arg.date.getFullYear()
                    );
                  });
                  const totalUtilization = dayEvents.reduce(
                    (acc, event) => acc + event._def.extendedProps.utilization,
                    0
                  );
                  return (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>{arg.dayNumberText}</div>
                      </div>
                      {/* <IconButton
                        className="add-reminder-btn"
                        onClick={() => handleAddReminder(arg.date)}
                        size="small"
                        sx={{
                          position: "absolute",
                          right: 1,
                          top: 1,
                          color: "primary.blue",
                          opacity: 0.5,
                          "&:hover": {
                            opacity: 1,
                          },
                        }}
                      >
                        <AlarmAdd fontSize="small" />
                      </IconButton> */}
                    </div>
                  );
                }}
                ref={calendarRef}
                rerenderDelay={10}
                select={handleRangeSelect}
                selectable
              />
            </FullCalendarWrapper>
          </Card>
          <Dialog
            fullWidth
            maxWidth="sm"
            onClose={handleModalClose}
            open={selectedEvent && isModalOpen}
          >
            {/* Dialog renders its body even if not open */}
            {isModalOpen && (
              <CalendarEventForm
                event={selectedEvent}
                onAddComplete={handleModalClose}
                onCancel={handleModalClose}
                onDeleteComplete={handleModalClose}
                onEditComplete={handleModalClose}
                range={selectedRange}
              />
            )}
          </Dialog>
        </Container>
      </Box>
    </>
  );
};

export default SharedCalendar;
