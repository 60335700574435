import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import ViewAllSchedules from "./ViewAllSchedules";
import moment from "moment";
import useLoader from "../../../hooks/useLoader";
import {
  fetchMemberScheduling,
  fetchScheduling,
} from "../../../api/scheduling/Scheduling";

const months = [
  { label: "Jan", value: 1 },
  { label: "Feb", value: 2 },
  { label: "Mar", value: 3 },
  { label: "Apr", value: 4 },
  { label: "May", value: 5 },
  { label: "Jun", value: 6 },
  { label: "Jul", value: 7 },
  { label: "Aug", value: 8 },
  { label: "Sep", value: 9 },
  { label: "Oct", value: 10 },
  { label: "Nov", value: 11 },
  { label: "Dec", value: 12 },
];

const MonthView = ({ memberId, setUtilizationDetails }) => {
  const [tabs, setTabs] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [schedules, setSchedules] = useState([]);

  const { loaderState, loaderStart, loaderEnd } = useLoader();

  useEffect(() => {
    if (tabs.length === 6) {
      getSchedulingData();
    }
  }, [tabs, currentTab]);

  useEffect(() => {
    generateTab();
  }, []);

  const getSchedulingData = async () => {
    loaderStart();
    const selectedTabDetails = tabs[currentTab];
    let res;
    if (memberId) {
      res = await fetchMemberScheduling({
        startDate: selectedTabDetails.startDate,
        endDate: selectedTabDetails.endDate,
        userId: memberId || "",
      });
      if (res?.data?.data?.daySchedules?.data?.length > 0) {
        setUtilizationDetails({
          utilization: res?.data?.data?.utilization,
          availability: res?.data?.data?.availability,
        });
      } else {
        setUtilizationDetails({
          utilization: 0,
          availability: 100,
        });
      }
    } else {
      res = await fetchScheduling({
        startDate: selectedTabDetails.startDate,
        endDate: selectedTabDetails.endDate,
        userId: memberId || "",
      });
    }
    if (res?.data?.success) {
      if (memberId) {
        setSchedules(res.data.data.daySchedules);
      } else {
        setSchedules(res.data.data);
      }
    }
    loaderEnd();
  };

  const generateTab = () => {
    let refDay = new Date();
    const tempTabs = [];

    for (let i = 0; i < 6; i++) {
      const month = +moment(refDay).format("MM");

      const monthStartDate = moment(refDay)
        .startOf("month")
        .format("YYYY-MM-DD");
      const monthEndDate = moment(refDay).endOf("month").format("YYYY-MM-DD");
      const year = moment(refDay).format("YY");

      const tempTab = {
        month: month,
        startDate: monthStartDate,
        endDate: monthEndDate,
        year: year,
        label: months[month - 1].label,
        valuve: i,
      };

      tempTabs.push(tempTab);

      refDay = moment(monthEndDate).add(2, "days").format("YYYY-MM-DD");
    }

    setTabs(tempTabs);
  };

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Month View</title>
      </Helmet>
      <Box>
        {tabs.length > 0 && (
          <Box>
            <Box>
              <Tabs
                indicatorColor="primary"
                onChange={handleTabsChange}
                scrollButtons="auto"
                textColor="primary"
                value={currentTab}
                variant="scrollable"
                sx={{
                  ".MuiTab-wrapper": {
                    textTransform: "none",
                    fontSize: "12px",
                  },
                  ".Mui-selected": {
                    fontWeight: "bold",
                    color: "#000",
                  },
                  button: {
                    minWidth: "80px",
                    width: "16.666%",
                    "&:hover": {
                      backgroundColor: "#eee",
                    },
                  },
                  ".MuiTabs-indicator": {
                    minWidth: "80px",
                    width: "16.666%",
                  },
                }}
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
            </Box>
            <Box sx={{ mt: 1, p: 2 }}>
              {schedules?.data?.length > 0 ? (
                <ViewAllSchedules
                  schedules={schedules}
                  userView={memberId ? true : false}
                />
              ) : (
                <Typography
                  variant="h6"
                  color="secondary"
                  align="center"
                  sx={{ p: 4 }}
                >
                  {loaderState ? "Loading..." : "No Records Available"}
                </Typography>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default MonthView;
