import { TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { getIssueFileURL, removeIssue } from "../../../api/project/Project";
import PencilAltIcon from "../../../icons/PencilAlt";
import TrashIcon from "../../../icons/Trash";
import DeleteModal from "../../DeleteModal";
import EditIssueModal from "./EditIssueModal";

const SingleIssue = ({ issue, index, getIssues }) => {
  const [showEditIssueModal, setShowEditIssueModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deleteIssue = async (issueId) => {
    const res = await removeIssue({ issueId });
    if (res.data.success) {
      toast.success("Issue removed");
      await getIssues();
    }
  };

  const handleDocIssueClick = async () => {
    const res = await getIssueFileURL(issue.id);
    if (res.data.success) {
      window.open(res.data.data.tempUrl);
    }
  };

  return (
    <>
      <DeleteModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        onYesClick={() => deleteIssue(issue.id)}
        warningMessage="Are you sure you want to delete this issue?"
      />
      <EditIssueModal
        showEditIssueModal={showEditIssueModal}
        setShowEditIssueModal={setShowEditIssueModal}
        issue={issue}
        getIssues={getIssues}
      />
      <TableRow hover>
        <TableCell>{++index}</TableCell>
        <TableCell
          sx={{ cursor: "pointer" }}
          onClick={() => setShowEditIssueModal(true)}
        >
          {issue.name}
        </TableCell>
        <TableCell>{issue.id}</TableCell>
        <TableCell>{issue?.get_status?.status}</TableCell>
        <TableCell>{issue.start_date}</TableCell>
        <TableCell>{issue.end_date}</TableCell>
        <TableCell>
          {issue?.get_issue_assigned_to
            ? `${issue?.get_issue_assigned_to?.first_name} ${issue?.get_issue_assigned_to?.last_name}`
            : "-"}
        </TableCell>
        <TableCell
          sx={{
            maxWidth: "150px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            color: "blue",
            cursor: "pointer",
            "&:hover": {
              color: "blue",
              textDecoration: "underline",
            },
          }}
          onClick={issue.file_url ? handleDocIssueClick : null}
        >
          {issue?.file_url}
        </TableCell>
        <TableCell align="center">
          <PencilAltIcon
            className="action-icon"
            onClick={() => setShowEditIssueModal(true)}
          />
          <TrashIcon
            className="action-icon"
            onClick={() => setShowDeleteModal(true)}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default SingleIssue;
