import { Box } from "@mui/material";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  getPeopleReports,
  getSavedReports,
} from "../../../../api/reports/CustomReport";
import useAuth from "../../../../hooks/useAuth";
import SaveFilterModal from "./SaveFilterModal";
import SearchFilters from "./SearchFilters";

let configs = {};

// HELPER FOR DATE COMPARISON

const CustomPeople = ({ selectedFilter, setSelectedFilter }) => {
  const { user } = useAuth();
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [defaultGridState, setDefaultGridState] = useState(null);
  // const [configs, setConfigs] = useState({});
  const [rowData, setRowData] = useState(null);
  const [savedFilters, setSavedFilters] = useState([]);
  const [selectedSavedFilter, setSelectedSavedFilter] = useState("");
  const [saveFilterModalOpen, setSaveFilterModalOpen] = useState(false);
  const [isPriviewModeActive, setIsPriviewModeActive] = useState(false);
  const [colDefs, setColDefs] = useState([
    {
      field: "department",
      headerName: "Department",
      enableRowGroup: true,
      enablePivot: true,
      pivot: true,
      hide: true,
    },
    {
      field: "id",
      headerName: "Name",
      cellRenderer: (params) => {
        if (params.data) {
          return `${params.data.first_name} ${params.data.last_name}`;
        }
        return "";
      },
    },
    {
      field: "designation",
      headerName: "Designation",
      enableRowGroup: true,
      enablePivot: true,
    },
    {
      field: "hourly_rate",
      headerName: "Hourly rate",
      filter: "agNumberColumnFilter",
    },
    {
      field: "experience",
      headerName: "Experience",
    },
    {
      field: "email",
      headerName: "Email",
    },
    {
      field: "contact_person",
      headerName: "Contact Person",
    },
    {
      field: "count",
      headerName: "Count",
      aggFunc: "sum",
      hide: true,
    },
  ]);

  useEffect(() => {
    getTaskSavedReports();
  }, []);

  useEffect(() => {
    if (selectedFilter?.filter_data && gridColumnApi && gridApi) {
      update(selectedFilter.filter_data);
      setSelectedSavedFilter(selectedFilter);
      setSelectedFilter(null);
      activatePreview();
    }
  }, [gridColumnApi, gridApi]);

  const onGridReady = async (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    setDefaultGridState({
      filterModel: params?.api?.getFilterModel(),
      columnState: params?.columnApi?.getColumnState(),
      columnGroupState: params?.columnApi?.getColumnGroupState(),
      pivotMode: params?.columnApi?.isPivotMode(),
    });
    const updateData = (data) => {
      params.api.setRowData(data);
    };
    const res = await getPeopleReports();
    updateData(res.data.data.item);

    agGridEvent(params);
  };

  const onPivotMode = () => {
    console.log("onPivotMode");
  };

  const agGridEvent = (props) => {
    props.api.addEventListener("firstDataRendered", changeHandler);
    props.api.addEventListener("filterChanged", changeHandler);
    props.api.addEventListener("filterModified", changeHandler);
    props.api.addEventListener("sortChanged", changeHandler);
    props.api.addEventListener("columnValueChanged", changeHandler);
    props.api.addEventListener("columnResized", changeHandler);
    props.api.addEventListener("columnVisible", changeHandler);
    props.api.addEventListener("columnPinned", changeHandler);
    props.api.addEventListener("columnResized", changeHandler);
    props.api.addEventListener("newColumnsLoaded", changeHandler);
    props.api.addEventListener("gridColumnsChanged", changeHandler);
    props.api.addEventListener("displayedColumnsChanged", changeHandler);
    props.api.addEventListener("rowGroupOpened", changeHandler);
    props.api.addEventListener("expandOrCollapseAll", changeHandler);
  };

  const changeHandler = (params) => {
    configs.filterModel = params?.api?.getFilterModel();
    configs.columnState = params?.columnApi?.getColumnState();
    configs.columnGroupState = params?.columnApi?.getColumnGroupState();
    configs.pivotMode = params?.columnApi?.isPivotMode();
  };

  const getTaskSavedReports = async () => {
    const res = await getSavedReports("people");
    if (res?.data?.data?.items?.length > 0) {
      setSavedFilters(res.data.data.items);
    }
  };

  const updateFilters = (e, val) => {
    if (val) {
      console.log(val);
      const value = val.id;
      setSelectedSavedFilter(val);
      const selectedFilter = savedFilters.filter(
        (filter) => filter.id === value
      );
      if (selectedFilter.length && selectedFilter[0]?.filter_data) {
        update(selectedFilter[0]?.filter_data);
      }
      activatePreview();
    } else {
      setIsPriviewModeActive(false);
      activateDefaultState();
      setSelectedSavedFilter(null);
    }
  };

  const update = (filterData) => {
    const savedFilters = JSON.parse(filterData);
    const isPivotModeActive = savedFilters.pivotMode;
    gridColumnApi.setPivotMode(isPivotModeActive);
    gridApi.setFilterModel(savedFilters.filterModel);
    gridColumnApi.applyColumnState({
      state: savedFilters.columnState,
      applyOrder: true,
    });
    gridColumnApi.setColumnGroupState(savedFilters.columnGroupState);
  };

  const activatePreview = () => {
    setIsPriviewModeActive(true);
    closeToolPanel();
  };

  const activateDefaultState = () => {
    if (defaultGridState) {
      const isPivotModeActive = defaultGridState?.pivotMode;
      gridColumnApi.setPivotMode(isPivotModeActive);
      gridApi.setFilterModel(defaultGridState?.filterModel);
      gridColumnApi.applyColumnState({
        state: defaultGridState?.columnState,
        applyOrder: true,
      });
      gridColumnApi.setColumnGroupState(defaultGridState?.columnGroupState);
    }
  };

  const closeToolPanel = useCallback(() => {
    gridRef.current.api.closeToolPanel();
  }, []);

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  return (
    <>
      {saveFilterModalOpen && (
        <SaveFilterModal
          view="people"
          filterData={configs}
          saveFilterModalOpen={saveFilterModalOpen}
          setSaveFilterModalOpen={setSaveFilterModalOpen}
          getTaskSavedReports={getTaskSavedReports}
        />
      )}
      <Box sx={{ width: "100%", height: "85vh" }}>
        <div className="example-wrapper">
          <SearchFilters
            isPriviewModeActive={isPriviewModeActive}
            selectedSavedFilter={selectedSavedFilter}
            setSaveFilterModalOpen={setSaveFilterModalOpen}
            savedFilters={savedFilters}
            updateFilters={updateFilters}
            setIsPriviewModeActive={setIsPriviewModeActive}
            getTaskSavedReports={getTaskSavedReports}
          />

          <div
            id="myGrid"
            style={{
              height: "100%",
              width: "100%",
            }}
            className="ag-theme-alpine"
          >
            <AgGridReact
              defaultColDef={{
                flex: 1,
                minWidth: 150,
                sortable: true,
                resizable: true,
                filter: true,
              }}
              ref={gridRef}
              autoGroupColumnDef={{ minWidth: 150, pinned: true }}
              sideBar={true}
              onGridReady={onGridReady}
              rowData={rowData}
              onColumnPivotModeChanged={onPivotMode}
              columnDefs={colDefs}
              enableRangeSelection={true}
              enableCharts={true}
              popupParent={popupParent}
              suppressAggFuncInHeader={true}
              suppressExpandablePivotGroups={true}
              functionsReadOnly={user.custom_report === 1 ? false : true}
            ></AgGridReact>
          </div>
        </div>
      </Box>
    </>
  );
};

export default CustomPeople;
