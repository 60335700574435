import { PowerSettingsNewOutlined } from "@mui/icons-material";
import {
  AppBar,
  Box,
  IconButton,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import MenuIcon from "../icons/Menu";
import { isProd } from "../utils/isProd";
import AccountPopover from "./AccountPopover";
import Logo from "./Logo";
import MainNavInfo from "./MainNavInfo";
import NotificationsPopover from "./NotificationsPopover";
import ReminderPopover from "./ReminderPopover";
import TemplatePopover from "./TemplatePopover";
import VideoFloatButton from "./VideoFloatButton";
const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.background.default,
    boxShadow: "0px 2px 5px rgba(45, 62, 86, 0.08)",
    color: theme.palette.text.primary,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const MainNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const { logout } = useAuth();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (err) {
      console.error(err);
      toast.error("Unable to logout.");
    }
  };

  return (
    <>
      <DashboardNavbarRoot {...other}>
        <MainNavInfo />
        <Toolbar sx={{ minHeight: 64 }}>
          <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
            <IconButton color="inherit" onClick={onSidebarMobileOpen}>
              <MenuIcon fontSize="small" />
            </IconButton>
            <RouterLink to="/">
              <Logo sx={{ display: { lg: "inline", xs: "none" } }} />
            </RouterLink>
          </Box>
          <Box sx={{ flexGrow: 1, ml: 2 }} />
          <Box
            sx={{
              display: "flex",
              gap: { xs: 1, md: 3 },
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: isProd() ? "none" : "flex",
              }}
            >
              <VideoFloatButton hideDraggable />
            </Box>
            <Box sx={{}}>
              <TemplatePopover />
            </Box>
            <Box sx={{}}>
              <ReminderPopover />
            </Box>
            <Box sx={{}}>
              <NotificationsPopover />
            </Box>
            <Box sx={{}}>
              <AccountPopover />
            </Box>
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={handleLogout}
            >
              <PowerSettingsNewOutlined fontSize="medium" color="secondary" />
              <Typography
                sx={{
                  fontSize: "14px",
                  pb: 0.5,
                  display: { xs: "none", md: "inline-block" },
                }}
              >
                Logout
              </Typography>
            </Box>
          </Box>
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default MainNavbar;
