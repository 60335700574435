import { useState, useEffect } from "react";
import {
  Box,
  Card,
  InputAdornment,
  TableBody,
  Pagination,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import SearchIcon from "../../../icons/Search";
import SingleCredentialInfo from "./SingleCredentialInfo";
import axios from "axios";
import useTable from "../../../hooks/useTable";
import useLoader from "../../../hooks/useLoader";
import useAuth from "../../../hooks/useAuth";
// import permissions from "../../../@const/permissions";

const ViewAllPortfoliosListTable = (props) => {
  const { permissions } = useAuth();
  const { allPortfolio, handleAllPortfolio, ...other } = props;
  const headCells = [
    { id: "name", label: "PORTFOLIO NAME" },
    { id: "status", label: "	STATUS" },
    // { id: "projects", label: "	PROJECTS" },
    { id: "contact_person", label: "CONTACT PERSON" },
    { id: "email", label: "	EMAIL", disableSorting: true },
    { id: "created_on", label: "	DATE CREATED", align: "right" },
  ];
  if (permissions["PORTFOLIO_DELETE"]) {
    headCells.push({
      id: "action",
      label: "ACTION",
      disableSorting: true,
      align: "center",
    });
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [filterPortfolioType, setFilterPortfolioType] = useState(0);
  const [nameSearchValue, setNameSearchValue] = useState("");
  const [allPortfolioTypes, setAllPortfolioTypes] = useState([]);

  const { loaderState } = useLoader();

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    allPortfolio.data,
    headCells
  );

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleFilterPortfolioTypeChange = (e) => {
    setCurrentPage(1);
    setFilterPortfolioType(e.target.value);
  };

  const searchHandler = (e) => {
    e.preventDefault();
    setNameSearchValue(e.target.value);
  };

  useEffect(() => {
    getAllPortfolioTypes();
  }, []);

  useEffect(() => {
    getAllPortfolios();
  }, [currentPage, filterPortfolioType, nameSearchValue]);

  const getAllPortfolios = () => {
    let portfolioType;
    if (filterPortfolioType === 0) {
      portfolioType = "";
    } else {
      portfolioType = filterPortfolioType;
    }
    handleAllPortfolio(currentPage, portfolioType, nameSearchValue);
  };

  const getAllPortfolioTypes = async () => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/portfolio/type`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setAllPortfolioTypes(response.data.data.portfolioTypes);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Card {...other}>
      <Box
        sx={{
          m: -1,
          p: 3,
        }}
      >
        <Typography
          variant="dashboardContentHeader"
          sx={{
            color: "#2D3E56",
            mb: "18px",
            display: "block",
          }}
        >
          Portfolio List
        </Typography>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              label="Filter Portfolio"
              name="status"
              onChange={handleFilterPortfolioTypeChange}
              select
              SelectProps={{ native: true }}
              value={filterPortfolioType || "all"}
              variant="outlined"
            >
              <option
                aria-label="None"
                value={0}
                selected={filterPortfolioType === 0}
              >
                All
              </option>
              {allPortfolioTypes?.map((portfolioType) => (
                <option key={portfolioType.id} value={portfolioType.id}>
                  {portfolioType.type_name}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              label="Enter portfolio Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              onChange={searchHandler}
              placeholder="Enter portfolio Name"
              value={nameSearchValue}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            m: 1,
            maxWidth: "100%",
            width: 240,
          }}
        ></Box>
      </Box>
      <Box sx={{ pt: 3 }}>
        <Grid xs={12}>
          <TblContainer>
            <TblHead />
            {allPortfolio?.data?.length > 0 && (
              <TableBody>
                {recordsAfterPagingAndSorting().map((portfolio, index) => {
                  return (
                    <SingleCredentialInfo
                      portfolio={portfolio}
                      key={index}
                      index={index}
                      getAllPortfolios={getAllPortfolios}
                    />
                  );
                })}
              </TableBody>
            )}
          </TblContainer>
        </Grid>

        <Box sx={{ py: 4, display: "flex", justifyContent: "center" }}>
          {allPortfolio?.data?.length > 0 ? (
            <Pagination
              page={currentPage}
              onChange={handleChange}
              count={allPortfolio.last_page}
            />
          ) : (
            <Typography variant="h6" color="secondary">
              {loaderState ? "Loading..." : "No Records Available"}
            </Typography>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default ViewAllPortfoliosListTable;
