import { Box, Button, FormHelperText, TextField } from "@mui/material";
import { Formik } from "formik";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { resetPassword } from "../../api/user/User";
import useMounted from "../../hooks/useMounted";

const ResetPasswordForm = (props) => {
  const { setMessageModalErrorState, token } = props;
  const mounted = useMounted();

  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        password: "",
        c_password: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .min(7)
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{7,}$/,
            "Must Contain One Uppercase, One Lowercase, One Number and one special case Character"
          )
          .required("Password is required"),
        c_password: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const res = await resetPassword(
            token,
            values.password,
            values.c_password
          );
          if (res?.data?.data.success) {
            // setMessageModalState(true);
            toast.success(res?.data?.data?.message);
            navigate("/authentication/login");
          } else {
            setMessageModalErrorState(true);
          }
          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <TextField
            autoFocus
            label="Password *"
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            sx={{ mt: 0 }}
          />
          <TextField
            label="Confirm Password*"
            error={Boolean(touched.c_password && errors.c_password)}
            fullWidth
            helperText={touched.c_password && errors.c_password}
            margin="normal"
            name="c_password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.c_password}
            variant="outlined"
            sx={{ mt: 0 }}
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
