import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { createScoreRange } from "../../../api/project/Score";
import toast from "react-hot-toast";

const ScoreRange = ({ isOpen, setIsOpen, scoreRange, setScoreRange }) => {
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <Formik
          initialValues={{
            highRange: scoreRange["highRange"],
            lowRange: scoreRange["lowRange"],
          }}
          validationSchema={Yup.object().shape({
            highRange: Yup.number()
              .required("Required")
              .max(29, "Max 29")
              .min(1, "Min 1")
              .moreThan(Yup.ref("lowRange"), "Must be greater than low range"),
            lowRange: Yup.number()
              .required("Required")
              .min(0, "Min 0")
              .max(19, "Max 19")
              .lessThan(
                Yup.ref("highRange"),
                "Max must be less than high range"
              ),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const data = {
                highRange: values.highRange,
                lowRange: values.lowRange,
              };

              const res = await createScoreRange(data);
              if (res.data.success) {
                const t = res.data.data.item;
                setScoreRange({
                  highRange: t.high_range,
                  lowRange: t.low_range,
                });
                setIsOpen(false);
                toast.success("Score range updated successfully");
              }
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle>Change Score Range</DialogTitle>
              <DialogContent
                sx={{
                  p: 2,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Low Range"
                      name="lowRange"
                      type="number"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lowRange}
                      error={Boolean(touched.lowRange && errors.lowRange)}
                      helperText={touched.lowRange && errors.lowRange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="High Range"
                      name="highRange"
                      type="number"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.highRange}
                      error={Boolean(touched.highRange && errors.highRange)}
                      helperText={touched.highRange && errors.highRange}
                    />
                  </Grid>
                </Grid>
                <br />
                <b>New Score Range:</b>
                <Table
                  sx={{
                    opacity: 0.7,
                    mt: 2,
                    py: 2,
                    "& td": {
                      border: "1px solid #000",
                      padding: "10px",
                    },
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        Impact
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        Range
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Project looks feasible. Proceed </TableCell>
                      <TableCell
                        sx={{
                          whiteSpace: "nowrap",
                          color: "#0f0",
                          fontWeight: "bold",
                        }}
                      >
                        {values.highRange} to 30
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Project is feasible but keep a close eye on it{" "}
                      </TableCell>
                      <TableCell
                        sx={{
                          whiteSpace: "nowrap",
                          color: "orange",
                          fontWeight: "bold",
                        }}
                      >
                        {values.lowRange + 1} to {values.highRange - 1}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Project is risky, would not recommend - only proceed if
                        it meets strategic objective. Keep a very close eye{" "}
                      </TableCell>
                      <TableCell
                        sx={{
                          whiteSpace: "nowrap",
                          color: "#f00",
                          fontWeight: "bold",
                        }}
                      >
                        {"<"} {values.lowRange}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogActions>
                <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                  <Box sx={{ ml: 1 }}>
                    <Button
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      onClick={() => setIsOpen(false)}
                      sx={{ backgroundColor: "#C0CBDE" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default ScoreRange;
