import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import useMounted from "../../../hooks/useMounted";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CreateNewMemberModal = (props) => {
  const { memberModalState, handleCreateNewMemberModalClose, handleAllUser } =
    props;

  const mounted = useMounted();
  const classes = useStyles();

  return (
    <>
      <Dialog open={memberModalState} onClose={handleCreateNewMemberModalClose}>
        <Formik
          initialValues={{
            email: "",
            first_name: "",
            last_name: "",
            userName: "",
            password: "",
            role: 3,
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
            first_name: Yup.string()
              .max(255)
              .required("First Name is required"),
            role: Yup.number().max(255).required("User Role is required"),
            userName: Yup.string().max(255).nullable(),
            last_name: Yup.string().max(255).required("Last Name is required"),
            password: Yup.string()
              .min(7)
              .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{7,}$/,
                "Must Contain One Uppercase, One Lowercase, One Number and one special case Character"
              )
              .required("Password is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const accessToken = window.localStorage.getItem("accessToken");
              const res = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/user`,
                {
                  first_name: values.first_name,
                  last_name: values.last_name,
                  email: values.email,
                  username: values.userName,
                  password: values.password,
                  role: values.role,
                },
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              );

              if (res.data.success) {
                toast.success("New member credential added");
                handleCreateNewMemberModalClose();
                handleAllUser(1, 0, "");
              }
              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              toast.error("Something went wrong");
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form
              noValidate
              onSubmit={handleSubmit}
              autocomplete="off"
              {...props}
            >
              <DialogTitle>Create New User</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="First Name *"
                      error={Boolean(touched.first_name && errors.first_name)}
                      fullWidth
                      helperText={touched.first_name && errors.first_name}
                      margin="normal"
                      name="first_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.first_name}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Last Name *"
                      error={Boolean(touched.last_name && errors.last_name)}
                      fullWidth
                      helperText={touched.last_name && errors.last_name}
                      margin="normal"
                      name="last_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.last_name}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Email *"
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="User Name "
                      error={Boolean(touched.userName && errors.userName)}
                      fullWidth
                      helperText={touched.userName && errors.userName}
                      margin="normal"
                      name="userName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      autoComplete="new-password"
                      value={values.userName}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      className={classes.formControl}
                      error={Boolean(
                        touched.subscriptionType && errors.subscriptionType
                      )}
                    >
                      <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                        {" "}
                        Role{" "}
                      </InputLabel>
                      <Select
                        error={Boolean(touched.role && errors.role)}
                        fullWidth
                        helperText={touched.role && errors.role}
                        margin="normal"
                        name="role"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.role}
                        variant="outlined"
                        label="Role"
                        sx={{ mt: 0 }}
                      >
                        <MenuItem value={2}> Admin </MenuItem>
                        <MenuItem value={3}> Manager </MenuItem>
                        {/* <MenuItem value={4}> Power User </MenuItem> */}
                        <MenuItem value={5}> User </MenuItem>
                        <MenuItem value={6}> Viewer </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Password *"
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      autoComplete="new-password"
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                  <Box sx={{ ml: 1 }}>
                    <Button
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      onClick={handleCreateNewMemberModalClose}
                      sx={{ backgroundColor: "#C0CBDE" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default CreateNewMemberModal;
