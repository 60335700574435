import {
  Box,
  Card,
  InputAdornment,
  TableBody,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getSavedReports } from "../../../../api/reports/CustomReport";
import useTable from "../../../../hooks/useTable";
import SearchIcon from "../../../../icons/Search";
import SingleSavedFilter from "./SingleSavedFilter";
const headCells = [
  { id: "title", label: "SAVED FILTERS" },
  { id: "action", label: "ACTION", align: "center", disableSorting: true },
];

const SearchCustomReport = ({ setSelectedFilter }) => {
  const [savedFilters, setSavedFilters] = useState([]);
  const [nameSearchValue, setNameSearchValue] = useState("");

  useEffect(() => {
    getTaskSavedReports();
  }, [nameSearchValue]);

  const updateFilters = (val) => {
    if (val) {
      setSelectedFilter(val);
    }
  };

  const getTaskSavedReports = async () => {
    const res = await getSavedReports("all", nameSearchValue);
    if (res?.data?.data?.items?.length > 0) {
      setSavedFilters(res.data.data.items);
    }
  };

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    savedFilters,
    headCells
  );

  const searchHandler = (e) => {
    e.preventDefault();
    setNameSearchValue(e.target.value);
  };

  return (
    // <Grid container spacing={2} sx={{ py: 3, px: 1, minHeight: "500px" }}>
    //   <Grid item xs={12} sm={3}></Grid>
    //   <Grid item xs={12} sm={6}>
    //     <Box sx={{ width: "100%" }}>
    //       <Autocomplete
    //         focus={true}
    //         disablePortal
    //         id="combo-box-demo"
    //         blurOnSelect={true}
    //         options={savedFilters.sort((a, b) => a.level - b.level)}
    //         onChange={updateFilters}
    //         groupBy={(option) =>
    //           option.level === 1
    //             ? "Global"
    //             : option.level === 2
    //             ? "Company-Wide"
    //             : "Personal"
    //         }
    //         group
    //         getOptionLabel={(option) => option.title}
    //         renderOption={(props, option) => {
    //           return (
    //             <li {...props} key={`${option.id}`}>
    //               <div>
    //                 <p>
    //                   <span
    //                     style={{ fontWeight: "bold" }}
    //                   >{`${option.title}`}</span>{" "}
    //                   <br />
    //                   {`${option.description}`}
    //                 </p>
    //               </div>
    //             </li>
    //           );
    //         }}
    //         renderInput={(params) => (
    //           <TextField
    //             {...params}
    //             label="Search Saved Filters"
    //             name="savedFilters"
    //           />
    //         )}
    //       />
    //     </Box>
    //   </Grid>
    //   <titleGrid item xs={12} sm={3}></titleGrid>
    // </Grid>
    <Card>
      <Box
        sx={{
          m: -1,
          p: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box></Box>
        <Box
          sx={{
            m: 1,
            maxWidth: "100%",
            width: 300,
          }}
        >
          <TextField
            label="Search"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={searchHandler}
            placeholder="Search"
            value={nameSearchValue}
            variant="outlined"
          />
        </Box>
      </Box>
      {savedFilters?.length > 0 ? (
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((savedFilter) => {
              return (
                <SingleSavedFilter
                  key={savedFilter.id}
                  savedFilter={savedFilter}
                  updateFilters={updateFilters}
                  getTaskSavedReports={getTaskSavedReports}
                />
              );
            })}
          </TableBody>
        </TblContainer>
      ) : (
        <Typography variant="h6" color="secondary" align="center" sx={{ p: 3 }}>
          {"No Records Available"}
        </Typography>
      )}
    </Card>
  );
};

export default SearchCustomReport;
