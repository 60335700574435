import React from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import { Link as RouterLink } from "react-router-dom";
import ViewAllScoresListTable from "../../components/dashboard/ViewAllScoresListTable";

const ViewAllScores = () => {
  const { settings } = useSettings();
  return (
    <>
      <Helmet>
        <title>Dashboard: Scores |</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Project Intake
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                Project Intake
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                component={RouterLink}
                to="/dashboard/add-score"
              >
                Add New Score
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <ViewAllScoresListTable />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ViewAllScores;
