import React from "react";
import { Stepper, Step, StepLabel, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const steps = [
  { label: "Select Workflow", path: "workflow" },
  { label: "Project Details", path: "form" },
  { label: "Add Team Members", path: "team" },
  { label: "Success", path: "complete" },
];

const ProjectStepper = ({ activeStep }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (activeStep === 1) {
      navigate("/dashboard/projects/new/workflow");
    } else if (activeStep === 2) {
      navigate("/dashboard/projects/new/form");
    }
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Stepper activeStep={activeStep}>
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {(activeStep === 1 || activeStep === 2) && (
        <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 2 }}>
          <Button color="inherit" onClick={handleBack}>
            Back to{" "}
            {activeStep === 1 ? "Workflow Selection" : "Project Details"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ProjectStepper;
