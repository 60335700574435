import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
} from "@mui/material";
import React from "react";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide ref={ref} {...props} />;
});
const DeleteModal = ({ open, setOpen, warningMessage, onYesClick }) => {
  const handleClose = () => {
    setOpen(false);
  };
  const onSuccess = () => {
    onYesClick();
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          backgroundColor: "#222",
          color: "#ddd",
          borderRadius: "10px",
          position: "fixed",
          top: "5%",
        },
      }}
    >
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            color: "#222",
            lineHeight: "29px",
            fontWeight: "bold",
            color: "#f00",
          }}
        >
          {warningMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{
            color: "#ddd",
            fontWeight: "bold",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              backgroundColor: "#333",
              transform: "scale(1.05)",
            },
          }}
        >
          No
        </Button>
        <Button
          variant="contained"
          onClick={onSuccess}
          sx={{
            backgroundColor: "#f00",
            color: "#fff",
            fontWeight: "bold",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              backgroundColor: "#f00",
              transform: "scale(1.05)",
            },
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
