import React, { useState, useEffect } from "react";
import {
  FormHelperText,
  TextField,
  Box,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import useMounted from "../../../hooks/useMounted";
import toast from "react-hot-toast";
import {
  getMilestoneTypes,
  updateMilestone,
} from "../../../api/project/Project";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const EditMilestoneModal = ({
  milestone,
  showEditMilestoneModal,
  setShowEditMilestoneModal,
  getMileStones,
}) => {
  const mounted = useMounted();
  const [milestoneTypes, setMilestoneTypes] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const fetchMilestoneTypes = async () => {
      try {
        const res = await getMilestoneTypes();
        if (isMounted && res.data.success) {
          setMilestoneTypes(res.data.data.milestoneTypes);
        }
      } catch (error) {
        console.error("Error fetching milestone types:", error);
      }
    };

    fetchMilestoneTypes();

    return () => {
      isMounted = false;
    };
  }, []);

  const classes = useStyles();

  return (
    <>
      <Dialog
        open={showEditMilestoneModal}
        onClose={() => setShowEditMilestoneModal(false)}
        maxWidth={"md"}
      >
        <Formik
          initialValues={{
            name: milestone.name,
            type: milestone.type,
            color: milestone.color_code,
            notes: milestone.notes,
            budget: "",
            actual: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required("Milestone Name is required"),
            type: Yup.string().max(255).required("Milestone Type is required"),
            color: Yup.string()
              .max(255)
              .required("Milestone color is required"),
            notes: Yup.string().max(255).nullable(),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const res = await updateMilestone({
                mile_name: values.name,
                mile_type: values.type,
                mile_color: values.color,
                mile_notes: values.notes,
                mile_budget: values.budget,
                mile_actual: values.actual,
                mile_id: milestone.id,
              });

              if (res.data.success) {
                toast.success("Milestone updated");
                getMileStones();
                setShowEditMilestoneModal(false);
              }
              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              toast.error("Something went wrong");
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>Edit Milestone</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Milestone Name"
                      required
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box sx={{ width: "100%" }}>
                      {milestoneTypes.length > 0 && (
                        <FormControl className={classes.formControl}>
                          <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                            Milestone Type
                          </InputLabel>
                          <Select
                            labelId="select-status"
                            label="Milestone Type"
                            name="type"
                            value={values.type}
                            error={Boolean(touched.type && errors.type)}
                            fullWidth
                            helperText={touched.type && errors.type}
                            required
                            placeholder="Status"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            <MenuItem value="">none</MenuItem>
                            {milestoneTypes.map((mileType) => (
                              <MenuItem key={mileType.id} value={mileType.id}>
                                {mileType.type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Milestone color"
                      type="color"
                      required
                      error={Boolean(touched.color && errors.color)}
                      fullWidth
                      helperText={touched.color && errors.color}
                      margin="normal"
                      name="color"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.color}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Notes"
                      error={Boolean(touched.notes && errors.notes)}
                      fullWidth
                      helperText={touched.notes && errors.notes}
                      margin="normal"
                      name="notes"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Budget"
                      type="number"
                      error={Boolean(touched.budget && errors.budget)}
                      fullWidth
                      helperText={touched.budget && errors.budget}
                      margin="normal"
                      name="budget"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.budget}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Actual"
                      type="number"
                      error={Boolean(touched.actual && errors.actual)}
                      fullWidth
                      helperText={touched.actual && errors.actual}
                      margin="normal"
                      name="actual"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.actual}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                  <Box sx={{ ml: 1 }}>
                    <Button
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      onClick={() => setShowEditMilestoneModal(false)}
                      sx={{ backgroundColor: "#C0CBDE" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default EditMilestoneModal;
