import { Box, Button, Grid, Tooltip } from "@mui/material";
import { Undo } from "@mui/icons-material";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  checkUndoProjectMonthlyBudgetAvailability,
  undoProjectMonthlyBudget,
} from "../../../../api/budget/Budget";
import {
  addProjectBudget,
  updateProjectBudget,
  updateProjectDates,
} from "../../../../api/project/Project";
import useLoader from "../../../../hooks/useLoader";
import Budget from "./Budget";
import ProjectDateRange from "./ProjectDateRange";

const MonthWiseBudget = ({
  selectedProject,
  getProject,
  showOnlyDetailedView,
  shared,
}) => {
  const [monthWiseBudgets, setMonthWiseBudgets] = useState([]);
  const [projectStartDate, setProjectStartDate] = useState(
    // new Date(selectedProject?.start_date)
    moment(selectedProject.start_date, "YYYY-MM-DD").format("MM/DD/YYYY")
  );
  const [projectDueDate, setProjectDueDate] = useState(
    // new Date(selectedProject?.due_date)
    moment(selectedProject.due_date, "YYYY-MM-DD").format("MM/DD/YYYY")
  );
  const [total, setTotal] = useState({
    budget: 0,
    actual: 0,
  });
  const [showUndo, setShowUndo] = useState(false);
  const { loaderStart, loaderEnd } = useLoader();

  const [completeBudgetViewOnlyModal, setCompleteBudgetViewOnlyModal] =
    useState(false);

  const reset = () => {
    initialBudget();
    setProjectStartDate(new Date(selectedProject?.start_date));
    setProjectDueDate(new Date(selectedProject?.due_date));
  };

  const startDate = moment(selectedProject.start_date);
  const endDate = moment(selectedProject.due_date);
  const interim = startDate.clone();

  useEffect(() => {
    initialBudget();
    checkUndoActive();
  }, []);

  useEffect(() => {
    if (monthWiseBudgets.length) {
      calculateTotalBudget();
    }
  }, [monthWiseBudgets]);

  const checkUndoActive = async () => {
    const res = await checkUndoProjectMonthlyBudgetAvailability(
      selectedProject.id
    );
    if (res?.data) {
      setShowUndo(res?.data?.data?.value);
    }
  };

  const initialBudget = () => {
    if (selectedProject.get_project_budget) {
      setMonthWiseBudgets(JSON.parse(selectedProject.get_project_budget.data));
    } else {
      let timeValues = [];
      while (endDate > interim || interim.format("M") === endDate.format("M")) {
        timeValues.push({
          date: interim.format("YYYY-MM"),
          budget: 0,
          actual: 0,
          note: "",
        });
        interim.add(1, "month");
      }
      setMonthWiseBudgets(timeValues);
    }
  };

  const updateRecord = (event) => {
    let tempArr = [];
    event.api.forEachLeafNode((params) => {
      tempArr.push(params.data);
    });
    let tempElement = tempArr[event.rowIndex];
    let targetVal = event.value;
    tempElement[event.colDef.field] =
      event.colDef.field === "note" ? targetVal : parseInt(targetVal || 0);
    tempArr[event.rowIndex] = tempElement;
    setMonthWiseBudgets(tempArr);
  };

  const calculateTotalBudget = () => {
    let tempTotal = {
      budget: 0,
      actual: 0,
    };
    monthWiseBudgets.forEach((element) => {
      const totalBudget = tempTotal.budget + parseInt(element.budget) || 0;
      const totalActual = tempTotal.actual + parseInt(element.actual) || 0;

      tempTotal = {
        // budget: totalBudget, Making changes to show budget from project details after discussing with mathew
        // budget: selectedProject.budget, // Again Changing After the discussion with mathew 2025-03-20
        budget: totalBudget,
        actual: totalActual,
      };
    });
    setTotal(tempTotal);
  };

  const handleSubmit = async () => {
    let res = null;
    const body = {
      projectId: selectedProject.id,
      data: JSON.stringify(monthWiseBudgets),
      budgetTotal: total.budget,
      actualTotal: total.actual,
      isEdited: false,
      isCompleted: true,
    };
    if (
      startDate.format("YYYY-MM-DD") !==
        moment(projectStartDate).format("YYYY-MM-DD") ||
      endDate.format("YYYY-MM") !== moment(projectDueDate).format("YYYY-MM")
    ) {
      const output = await updateProjectDates(
        selectedProject.id,
        moment(projectStartDate).format("YYYY/MM/DD/"),
        moment(projectDueDate).format("YYYY/MM/DD/")
      );
    }
    if (selectedProject.get_project_budget) {
      res = await updateProjectBudget(body);
    } else {
      res = await addProjectBudget(body);
    }
    if (res.data) {
      toast.success(res.data.data.message);
      getProject();
    }
  };

  const updateTotalActualOfAMonth = (date, actual, budget) => {
    let tempArr = [...monthWiseBudgets];
    tempArr.forEach((month) => {
      if (month.date === date) {
        month.actual = actual;
        month.budget = budget;
      }
    });
    setMonthWiseBudgets(tempArr);
    setTimeout(() => {
      handleSubmit();
    }, 500);
  };

  const startUndo = async () => {
    loaderStart();
    const res = await undoProjectMonthlyBudget(selectedProject.id);
    if (res?.data) {
      window.location.reload();
    }
  };

  return (
    <Box sx={{ pb: 4, mb: 4, mt: 3, mb: 2 }}>
      {!showOnlyDetailedView ? (
        <Grid container spacing={2} sx={{ mb: 1, alignItems: "center" }}>
          <Grid item xs={12} sm={6} md={6}>
            <ProjectDateRange
              selectedProject={selectedProject}
              monthWiseBudgets={monthWiseBudgets}
              setMonthWiseBudgets={setMonthWiseBudgets}
              projectStartDate={projectStartDate}
              setProjectStartDate={setProjectStartDate}
              projectDueDate={projectDueDate}
              setProjectDueDate={setProjectDueDate}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} sx={{ pl: 1 }}>
            <Box sx={{ float: "right", mr: 1 }}>
              <Tooltip title="Undo">
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => startUndo()}
                  sx={{
                    mr: 2,
                    backgroundColor: "#6e6e6e",
                    transition: "all 0.3s ease-in-out",
                    ":hover": {
                      backgroundColor: "#7a7676",
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <Undo />
                </Button>
              </Tooltip>
              <Tooltip title="Detailed budget view">
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => setCompleteBudgetViewOnlyModal(true)}
                  sx={{
                    mr: 2,
                    backgroundColor: "#3f51b5",
                    transition: "all 0.3s ease-in-out",
                    ":hover": {
                      backgroundColor: "#5f51b5",
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <EyeIcon />
                </Button>
              </Tooltip>
              {/* <Button
                onClick={reset}
                sx={{ mr: 1 }}
                variant="outlined"
                size="large"
              >
                Reset
              </Button> */}
              {/* <Button onClick={handleSubmit} variant="contained" size="large">
                Submit
              </Button> */}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Box>
        {monthWiseBudgets.length > 0 && (
          <Budget
            monthWiseBudgets={monthWiseBudgets}
            updateRecord={updateRecord}
            selectedProject={selectedProject}
            total={total}
            projectStartDate={projectStartDate}
            projectDueDate={projectDueDate}
            completeBudgetViewOnlyModal={completeBudgetViewOnlyModal}
            setCompleteBudgetViewOnlyModal={setCompleteBudgetViewOnlyModal}
            updateTotalActualOfAMonth={updateTotalActualOfAMonth}
            showOnlyDetailedView={showOnlyDetailedView}
            shared={shared}
          />
        )}
      </Box>
    </Box>
  );
};

export default MonthWiseBudget;
