import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink, useParams } from "react-router-dom";
import { getSingleUser } from "../../api/user/User";
import AssignTask from "../../components/dashboard/scheduling/AssignTask";
import DateRangeView from "../../components/dashboard/scheduling/DateRangeView";
import MonthView from "../../components/dashboard/scheduling/MonthView";
import WeekView from "../../components/dashboard/scheduling/WeekView";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";

const tabs = [
  // { label: "Day View", value: "dayView" },
  { label: "Week View", value: "weekView" },
  { label: "Month View", value: "monthView" },
  { label: "Date Range", value: "dateRange" },
];

const PersonScheduling = () => {
  const [currentTab, setCurrentTab] = useState("weekView");
  const [userDetails, setuserDetails] = useState(null);
  const [showAssignTaskModal, setShowAssignTaskModal] = React.useState(false);
  const { settings } = useSettings();
  const { memberId } = useParams();
  const [utilizationDetails, setUtilizationDetails] = useState({
    utilization: 0,
    availability: 0,
  });

  useEffect(() => {
    getUserDetails();
  }, [memberId]);

  const getUserDetails = async () => {
    const res = await getSingleUser({ userId: memberId });
    if (res.data.success) {
      setuserDetails(res.data.data.user);
    }
  };

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Person Scheduling</title>
      </Helmet>
      {showAssignTaskModal && (
        <AssignTask
          showAssignTaskModal={showAssignTaskModal}
          setShowAssignTaskModal={setShowAssignTaskModal}
          userId={memberId}
        />
      )}
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/dashboard/scheduling/view-all"
                  variant="breadCrumbText"
                >
                  Scheduling
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  {`${userDetails?.first_name} ${userDetails?.last_name} `}
                </Typography>
              </Breadcrumbs>
              {/* <Typography color="textPrimary" variant="dashboardHeading">
                Scheduling
              </Typography> */}
            </Grid>
          </Grid>
          <Box>
            <Box
              sx={{
                mt: 3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography color="textPrimary" variant="dashboardHeading">
                  {`${userDetails?.first_name} ${userDetails?.last_name} `}
                </Typography>
                <Box>
                  <Typography
                    color="textSecondary"
                    component="span"
                    variant="dashboardInfo"
                  >
                    Role:
                  </Typography>
                  <Typography
                    component="span"
                    color="textPrimary"
                    variant="dashboardInfo"
                    sx={{
                      pl: 1,
                    }}
                  >
                    {userDetails?.extra_params?.designation || "Not Defined"}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    component="span"
                    variant="dashboardInfo"
                    sx={{ pl: 2 }}
                  >
                    Utilization:
                  </Typography>
                  <Typography
                    component="span"
                    color="textPrimary"
                    variant="dashboardInfo"
                    sx={{
                      pl: 1,
                      color:
                        utilizationDetails?.utilization >= 100
                          ? "#f00"
                          : "#2D3E56",
                    }}
                  >
                    {utilizationDetails?.utilization} %
                  </Typography>
                  <Typography
                    color="textPrimary"
                    component="span"
                    color="textSecondary"
                    variant="dashboardInfo"
                    sx={{ pl: 2 }}
                  >
                    Availability:
                  </Typography>
                  <Typography
                    component="span"
                    variant="dashboardInfo"
                    color="textPrimary"
                    sx={{
                      pl: 1,
                      color:
                        utilizationDetails?.availability === 0
                          ? "#f00"
                          : "#2D3E56",
                    }}
                  >
                    {utilizationDetails.availability === 0
                      ? "Over Assigned"
                      : `${utilizationDetails.availability} %`}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box sx={{ m: -1 }}>
                  <Button
                    color="primary"
                    onClick={() => setShowAssignTaskModal(true)}
                    sx={{ m: 1 }}
                    variant="contained"
                  >
                    Assign Task
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Card sx={{ mt: 2 }}>
            <Box>
              <Box sx={{ borderBottom: "1px solid #D7DFEC" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* {currentTab === "projectDetails" && (
                    <Box>
                      {!editActive && (
                        <Button
                          startIcon={<PencilAltIcon />}
                          onClick={() => {
                            setEditActive(true);
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </Box>
                  )} */}
                </Box>
                <Box>
                  <Tabs
                    indicatorColor="primary"
                    onChange={handleTabsChange}
                    scrollButtons="auto"
                    textColor="primary"
                    value={currentTab}
                    variant="scrollable"
                    sx={{
                      ".MuiTab-wrapper": {
                        textTransform: "none",
                        fontSize: "12px",
                      },
                      ".Mui-selected": {
                        fontWeight: "bold",
                        color: "#000 !important",
                      },
                      button: {
                        minWidth: "80px",
                        width: "33.33%",
                        "&:hover": {
                          backgroundColor: "#eee",
                        },
                      },
                      ".MuiTabs-indicator": {
                        minWidth: "60px",
                        width: "33.33%",
                      },
                    }}
                  >
                    {tabs.map((tab) => (
                      <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                      />
                    ))}
                  </Tabs>
                </Box>
              </Box>
              <Box sx={{ mt: 1, p: 2 }}>
                {/* {currentTab === "dayView" && <DayView memberId={memberId} />} */}
                {currentTab === "weekView" && (
                  <WeekView
                    memberId={memberId}
                    userView={true}
                    setUtilizationDetails={setUtilizationDetails}
                  />
                )}
                {currentTab === "monthView" && (
                  <MonthView
                    memberId={memberId}
                    userView={true}
                    setUtilizationDetails={setUtilizationDetails}
                  />
                )}
                {currentTab === "dateRange" && (
                  <DateRangeView
                    memberId={memberId}
                    userView={true}
                    setUtilizationDetails={setUtilizationDetails}
                  />
                )}
              </Box>
            </Box>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default PersonScheduling;
