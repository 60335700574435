import { handleApiRequest } from "../../hooks/callApi";

// Get all budgets
export const getBudgetData = async ({ projectId, portfolioId, page }) => {
  const controller = new AbortController();
  const signal = controller.signal;

  try {
    const body = {
      proj_id: projectId || "",
      pf_id: portfolioId || "",
    };
    const res = await handleApiRequest(
      `/api/budget?page=${page || 1}`,
      "post",
      body,
      {},
      signal
    );

    return res;
  } catch (error) {
    if (error.name === "AbortError") {
      // Handle abort error
      return null;
    }
    throw error;
  }
};

// Dropdown projects
export const dropdownProjects = async ({
  portfolioId,
  memberId,
  startDate,
  endDate,
  onlyProjectsWhereUserIsMember,
}) => {
  const res = await handleApiRequest(
    `/api/project-dropdown?pf_id=${portfolioId || ""}&member_id=${
      memberId || ""
    }&start_date=${startDate || ""}&end_date=${
      endDate || ""
    }&onlyProjectsWhereUserIsMember=${onlyProjectsWhereUserIsMember || ""}`,
    "get"
  );

  return res;
};

// Get dropdown portfolios
export const dropdownPortfolios = async () => {
  const res = await handleApiRequest(`/api/portfolio-dropdown`, "get");

  return res;
};

// Get budget datas
export const totalBudgetData = async () => {
  const body = {
    admin_id: "",
  };
  const res = await handleApiRequest(`/api/totalBudgetData`, "post", body);
  return res;
};

export const getAllMonthlyBudgetData = async (projectId, shared, authToken) => {
  const header = authToken ? { sharedKey: authToken } : {};
  const URL = shared
    ? `/api/shared/project-monthly-budget`
    : `/api/project-monthly-budget`;
  const res = await handleApiRequest(
    `${URL}/${projectId}`,
    "get",
    null,
    header
  );
  return res;
};

export const createMonthlyBudgetData = async (
  projectId,
  date,
  budget,
  actual,
  summery,
  notes
) => {
  const body = {
    project_id: projectId,
    date: date,
    budget: budget,
    actual: actual,
    summery: summery,
    notes: notes,
  };
  const res = await handleApiRequest(
    `/api/project-monthly-budget`,
    "post",
    body
  );
  return res;
};

export const updateMonthlyBudgetData = async (
  id,
  summery,
  budget,
  actual,
  notes
) => {
  const body = {
    id: id,
    summery: summery,
    budget: budget,
    actual: actual,
    notes: notes,
  };
  const res = await handleApiRequest(
    `/api/project-monthly-budget`,
    "put",
    body
  );
  return res;
};

export const deleteMonthlyBudgetData = async (id) => {
  const res = await handleApiRequest(
    `/api/project-monthly-budget/${id}`,
    "delete"
  );
  return res;
};

export const checkUndoProjectMonthlyBudgetAvailability = async (projectId) => {
  const res = await handleApiRequest(
    `/api/check-undo-project-monthly-budget-availability/${projectId}`,
    "get"
  );
  return res;
};

export const undoProjectMonthlyBudget = async (projectId) => {
  const body = {};
  const res = await handleApiRequest(
    `/api/undo-project-monthly-budget/${projectId}`,
    "post",
    body
  );
  return res;
};
export const checkUndoProjectTaskAvailability = async (projectId) => {
  const res = await handleApiRequest(
    `/api/check-undo-project-task-availability/${projectId}`,
    "get"
  );
  return res;
};

export const undoProjectTask = async (projectId) => {
  const body = {};
  const res = await handleApiRequest(
    `/api/undo-project-task/${projectId}`,
    "post",
    body
  );
  return res;
};
