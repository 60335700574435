import React, { useState } from "react";
import {
  TableCell,
  TableRow,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import PencilAltIcon from "../../../icons/PencilAlt";
import TrashIcon from "../../../icons/Trash";
import { removeMilestone } from "../../../api/project/Project";
import toast from "react-hot-toast";
import EditMilestoneModal from "./EditMilestoneModal";

const SingleMilestone = ({ milestone, index, getMileStones }) => {
  const [showEditMilestoneModal, setShowEditMilestoneModal] = useState(false);
  const [open, setOpen] = useState(false);

  const deleteMilestone = async (milestoneId) => {
    if (milestone.hasTask) {
      return setOpen(true);
    }
    const res = await removeMilestone({ milestoneId });
    if (res.data.success) {
      toast.success("Milestone removed");
      await getMileStones();
    }
  };

  const deleteMilestoneWithTaskRef = async () => {
    const res = await removeMilestone({ milestoneId: milestone.id });
    if (res.data.success) {
      toast.success("Milestone removed");
      await getMileStones();
    }
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              pt: 3,
              maxWidth: "350px",
              textAlign: "center",
              color: "#222",
              lineHeight: "29px",
              fontWeight: "600",
            }}
          >
            Are you sure you want to delete this milestone? Tasks under this
            milestone will be disassociated.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button
            onClick={deleteMilestoneWithTaskRef}
            color="secondary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {showEditMilestoneModal && (
        <EditMilestoneModal
          milestone={milestone}
          showEditMilestoneModal={showEditMilestoneModal}
          setShowEditMilestoneModal={setShowEditMilestoneModal}
          getMileStones={getMileStones}
        />
      )}
      <TableRow hover>
        <TableCell>{++index}</TableCell>
        <TableCell
          onClick={() => setShowEditMilestoneModal(true)}
          sx={{ cursor: "pointer" }}
        >
          {milestone.name}
        </TableCell>
        <TableCell>
          <Box
            sx={{
              height: "18px",
              width: "18px",
              borderRadius: "18px",
              backgroundColor: milestone.color_code,
              mx: "auto",
            }}
          ></Box>
        </TableCell>
        <TableCell>{milestone?.get_type?.type}</TableCell>
        <TableCell>{milestone.budget}</TableCell>
        <TableCell>{milestone.actual}</TableCell>
        <TableCell>{milestone.notes}</TableCell>
        <TableCell>{milestone.start_date}</TableCell>
        <TableCell>{milestone.end_date}</TableCell>

        <TableCell align="center">
          <PencilAltIcon
            title="Edit"
            className="action-icon"
            onClick={() => setShowEditMilestoneModal(true)}
          />
          <TrashIcon
            title="Delete"
            className="action-icon"
            onClick={() => deleteMilestone(milestone.id)}
          />
        </TableCell>
      </TableRow>
    </>
  );
};
export default SingleMilestone;
