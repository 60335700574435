import React from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import { CheckmarkIcon } from "react-hot-toast";
import WarningIcon from "@mui/icons-material/Warning";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const SuccessView = ({ projectData, selectedWorkFlow, onCreateAnother }) => {
  const navigate = useNavigate();

  const getWorkflowSpecificOptions = () => {
    const options = {
      essentialOptions: [
        {
          label: !projectData.has_contact
            ? "Assign Team Members (Required)"
            : "Manage Team Members",
          action: `/dashboard/projects/view-and-edit-project/${projectData.id}?p=members`,
          primary: !projectData.has_contact,
          required: !projectData.has_contact,
          description: "Add team members and assign roles",
        },
      ],
      additionalOptions: [],
    };

    // Common essential options
    const commonEssentials = [
      {
        label: "Project Details",
        action: `/dashboard/projects/view-and-edit-project/${projectData.id}?p=projectDetails`,
        primary: true,
        description: "Configure project information and settings",
      },
      {
        label: "Setup Budget",
        action: `/dashboard/projects/view-and-edit-project/${projectData.id}?p=budget`,
        primary: true,
        description: "Configure project budget and financial details",
      },
    ];

    // Workflow specific options
    if (selectedWorkFlow === "Waterfall or Task Based Approach") {
      options.essentialOptions.push(...commonEssentials, {
        label: "Add Milestones",
        action: `/dashboard/projects/view-and-edit-project/${projectData.id}?p=milestones`,
        primary: true,
        description: "Define key project milestones and deadlines",
      });
    } else {
      options.essentialOptions.push(...commonEssentials, {
        label: "Create Sprint",
        action: `/dashboard/projects/view-and-edit-project/${projectData.id}?p=sprints`,
        primary: true,
        description: "Set up sprints and manage sprint cycles",
      });
    }

    // Common additional options
    options.additionalOptions = [
      {
        label: "View Project Dashboard",
        action: `/dashboard/projects/view-and-edit-project/${projectData.id}?p=dashboard`,
        description: "Overview of project progress and metrics",
      },
      {
        label: "Manage Documents",
        action: `/dashboard/projects/view-and-edit-project/${projectData.id}?p=documents`,
        description: "Upload and manage project documents",
      },
      {
        label: "Track Issues",
        action: `/dashboard/projects/view-and-edit-project/${projectData.id}?p=issues`,
        description: "Track and manage project issues",
      },
    ];

    // Add portfolio link option if not already linked
    if (!projectData.has_portfolio) {
      options.additionalOptions.push({
        label: "Link to Portfolio",
        action: `/dashboard/projects/view-and-edit-project/${projectData.id}?p=settings`,
        description: "Associate project with a portfolio",
      });
    }

    return options;
  };

  const { essentialOptions, additionalOptions } = getWorkflowSpecificOptions();

  return (
    <Box sx={{ textAlign: "center", py: 4 }}>
      <Typography
        variant="h4"
        sx={{
          mb: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <CheckmarkIcon />
        Project Created Successfully
      </Typography>

      <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
        {projectData.project_name} - {selectedWorkFlow} Setup
      </Typography>

      <Typography variant="body1" sx={{ mb: 1 }}>
        Project Details:
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mb: 4 }}>
        {`${projectData.project_type} | Priority: ${
          projectData.project_priority
        } | ${moment(projectData.project_start).format("MMM D")} - ${moment(
          projectData.project_due
        ).format("MMM D, YYYY")}`}
      </Typography>

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <Card
            sx={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Essential Setup
              </Typography>
              <Grid container spacing={2}>
                {essentialOptions.map((option, index) => (
                  <Grid item xs={12} key={index}>
                    <Button
                      fullWidth
                      variant={option.primary ? "contained" : "outlined"}
                      color={option.required ? "error" : "primary"}
                      onClick={() => navigate(option.action)}
                      startIcon={option.required ? <WarningIcon /> : null}
                    >
                      {option.label}
                    </Button>
                    {option.description && (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={{ display: "block", mt: 0.5, textAlign: "left" }}
                      >
                        {option.description}
                      </Typography>
                    )}
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card
            sx={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Additional Options
              </Typography>
              <Grid container spacing={2}>
                {additionalOptions.map((option, index) => (
                  <Grid item xs={12} key={index}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={() => navigate(option.action)}
                    >
                      {option.label}
                    </Button>
                    {option.description && (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={{ display: "block", mt: 0.5, textAlign: "left" }}
                      >
                        {option.description}
                      </Typography>
                    )}
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={onCreateAnother}
                  >
                    Create Another Project
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Typography variant="body2" color="textSecondary">
        You can access all these options later from the project dashboard
      </Typography>
    </Box>
  );
};

export default SuccessView;
