import { styled } from "@mui/material";
import { memo } from "react";
import { Outlet } from "react-router-dom";
import ConstantLayout from "./ConstantLayout";
import Footer from "./Footer";
import Loader from "./Loader";
const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const MainLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const MainLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const MainLayout = () => {
  const MainLayoutWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: "105px",
    paddingLeft: "20px",
  }));

  return (
    <DashboardLayoutRoot>
      <Loader />
      {/* <Box
        sx={{
          position: "fixed",
          bottom: "30px",
          right: "30px",
          zIndex: 1000000,
          backgroundColor: "#333",
          padding: "2px 8px",
          borderRadius: "30px",
        }}
      >
        <Typography
          color="primary"
          sx={{ fontSize: "14px", fontWeight: "bold" }}
        >
          3.0
        </Typography>
      </Box> */}
      <ConstantLayout />
      <MainLayoutWrapper id="main-wrapper">
        <MainLayoutContainer>
          <MainLayoutContent>
            <Outlet />
            <Footer />
          </MainLayoutContent>
        </MainLayoutContainer>
      </MainLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default memo(MainLayout);
