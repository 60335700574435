import React, { useState, useEffect } from "react";
import {
  FormHelperText,
  TextField,
  Box,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import useMounted from "../../../hooks/useMounted";
import toast from "react-hot-toast";
import {
  addProjectMember,
  getUsersUnderAdmin,
} from "../../../api/project/Project";
import useProject from "../../../hooks/useProject";

const CreateNewMemberModal = (props) => {
  const { showAddMemberModal, setShowAddMemberModal, selectedProject } = props;
  const { getAllProjectMembers } = useProject();
  const mounted = useMounted();
  const [userType, setUserType] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [taskAssignedTo, setTaskAssignedTo] = useState(null);

  useEffect(() => {
    getMemberList();
  }, []);

  const getMemberList = async () => {
    const res = await getUsersUnderAdmin(1, 0);
    if (res.data.success) {
      setMemberList(res.data.data.peopleDropdown);
    } else {
      console.log("No Members");
    }
  };

  const handleUChange = (e, val) => {
    setTaskAssignedTo(val?.id || "");
  };
  return (
    <>
      <Dialog
        open={showAddMemberModal}
        onClose={() => setShowAddMemberModal(false)}
        fullWidth
        maxWidth="sm"
        sx={{
          minHeight: "300px",
        }}
      >
        <Formik
          enableReinitialize
          initialValues={{
            projectHours: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            projectHours: Yup.number()
              .max(255)
              .required("Project Hours is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const memberData = {
                proj_id: selectedProject.id,
                proj_time: values.projectHours,
                existing: 1,
                member_id: taskAssignedTo,
              };

              const res = await addProjectMember(memberData);

              if (res.data.success) {
                toast.success("New member added");
                getAllProjectMembers(selectedProject.id);
                setShowAddMemberModal(false);
                setUserType("");
                setTaskAssignedTo(null);
              }
              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              toast.error("Something went wrong");
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Box sx={{ pb: 4 }}>
              <form noValidate onSubmit={handleSubmit} {...props}>
                <DialogTitle>Add Member To Project</DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box sx={{ width: "100%" }}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          blurOnSelect={true}
                          options={memberList}
                          onChange={handleUChange}
                          ListboxProps={{ style: { maxHeight: "150px" } }}
                          getOptionLabel={(option) =>
                            `${option.first_name} ${option.last_name}`
                          }
                          renderOption={(props, option) => {
                            return (
                              <li
                                {...props}
                                key={`${option.first_name} ${option.last_name} ${option.id}`}
                              >
                                {`${option.first_name} ${option.last_name}`}
                              </li>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Member" />
                          )}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="Daily project hours"
                        required
                        error={Boolean(
                          touched.projectHours && errors.projectHours
                        )}
                        fullWidth
                        helperText={touched.projectHours && errors.projectHours}
                        margin="normal"
                        name="projectHours"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.projectHours}
                        variant="outlined"
                        disabled={!taskAssignedTo}
                        sx={{ mt: 2 }}
                      />
                    </Grid>
                  </Grid>

                  {errors.submit && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}
                </DialogContent>
                <DialogActions>
                  <Box
                    sx={{ display: "flex", mr: "auto", px: 2, pb: 1, pt: 2 }}
                  >
                    <Box>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Save
                      </Button>
                    </Box>
                    <Box sx={{ ml: 1 }}>
                      <Button
                        disabled={isSubmitting}
                        size="large"
                        variant="contained"
                        onClick={() => setShowAddMemberModal(false)}
                        sx={{ backgroundColor: "#C0CBDE" }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </DialogActions>
              </form>
            </Box>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default CreateNewMemberModal;
