import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link, TableCell, TableRow, Box } from "@mui/material";
import TrashIcon from "../../../icons/Trash";
import { deleteProject } from "../../../api/project/Project";
import toast from "react-hot-toast";
import moment from "moment";
import PermissionGuard from "../../PermissionGuard";
import DeleteModal from "../../DeleteModal";

const SingleProjectInfo = ({ project, index, getAllProject, currentPage }) => {
  const [open, setOpen] = useState(false);
  const removeProject = async () => {
    const res = await deleteProject(project.id);
    if (res.data.success) {
      toast.success("Project removed successfully");
      getAllProject(currentPage);
    }
  };

  return (
    <>
      <DeleteModal
        open={open}
        setOpen={setOpen}
        onYesClick={removeProject}
        warningMessage="Are you sure you want to delete this project?"
      />
      <TableRow hover>
        <TableCell>{++index}</TableCell>
        <TableCell>
          <Link
            color="textPrimary"
            component={RouterLink}
            to={`/dashboard/projects/view-and-edit-project/${project.id}?p=dashboard`}
            underline="none"
            variant="subtitle2"
            className="redirect-link"
          >
            {project.name}
          </Link>
        </TableCell>
        <TableCell>
          {project?.workflow_type === "agile" ? "Sprint" : "Task"}
        </TableCell>
        <TableCell>
          {project?.get_portfolio ? (
            <Link
              color="textPrimary"
              component={RouterLink}
              to={`/dashboard/portfolios/view-and-edit-portfolio/${project?.get_portfolio?.id}`}
              underline="none"
              variant="subtitle2"
              className="redirect-link"
            >
              {project?.get_portfolio?.name}
            </Link>
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell>{project?.get_priority?.priority}</TableCell>
        <TableCell>
          {moment(project.start_date).format("MM-DD-YYYY")}{" "}
        </TableCell>
        <TableCell>{moment(project.due_date).format("MM-DD-YYYY")}</TableCell>
        <TableCell>
          <Box
            sx={{
              height: "18px",
              width: "18px",
              borderRadius: "18px",
              backgroundColor:
                project?.get_status?.id === 1
                  ? "rgb(123, 223, 229)"
                  : project?.get_status?.id === 2
                  ? "green"
                  : "#FB6C7E",
              mx: "auto",
            }}
          ></Box>
        </TableCell>
        <TableCell>{project?.budget}</TableCell>
        <TableCell>{project?.spent_till_date}</TableCell>
        <PermissionGuard permissionCheck="PROJECT_DELETE" returnEmpty={true}>
          <TableCell align="center">
            <TrashIcon
              title="Delete"
              onClick={() => {
                setOpen(true);
              }}
              className="action-icon"
            />
          </TableCell>
        </PermissionGuard>
      </TableRow>
    </>
  );
};

export default SingleProjectInfo;
