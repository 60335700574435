import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getTaskById, deleteTask } from "../../api/project/Project";
import ViewAndEditTaskModal from "../../components/dashboard/projects/ViewAndEditTaskModal";
import DeleteModal from "../../components/DeleteModal";
import {
  Box,
  Button,
  Typography,
  Container,
  TextField,
  Grid,
} from "@mui/material";
import toast from "react-hot-toast";
import moment from "moment";

const TaskPage = () => {
  const { taskId } = useParams(); // Get taskId from URL
  const navigate = useNavigate();

  const [task, setTask] = useState(null);
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Fetch task details
  useEffect(() => {
    const fetchTask = async () => {
      try {
        const res = await getTaskById(taskId);
        if (res.data.success) {
          setTask(res.data.data.item);
        } else {
          toast.error("Failed to load task details");
        }
      } catch (err) {
        toast.error("An error occurred while fetching the task.");
      }
    };
    fetchTask();
  }, [taskId]);

  // Delete task
  const handleDelete = async () => {
    try {
      const res = await deleteTask(taskId);
      if (res.data.success) {
        toast.success("Task deleted successfully");
        navigate("/dashboard/projects");
      } else {
        toast.error("Failed to delete task");
      }
    } catch (err) {
      toast.error("An error occurred while deleting the task.");
    }
  };

  if (!task) {
    return <Typography>Loading task details...</Typography>;
  }

  return (
    <Container>
      {/* Delete Modal */}
      <DeleteModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        onYesClick={handleDelete}
        warningMessage="Are you sure you want to delete this task?"
      />

      {/* Edit Task Modal */}
      {showEditTaskModal && (
        <ViewAndEditTaskModal
          task={task}
          selectedProject={{
            id: task.proj_id,
          }}
          showEditTaskModal={showEditTaskModal}
          setShowEditTaskModal={setShowEditTaskModal}
          refreshTask={() => {
            // Refresh task details after editing
            const fetchTask = async () => {
              const res = await getTaskById(taskId);
              if (res.data.success) {
                setTask(res.data.data.item);
              }
            };
            fetchTask();
          }}
        />
      )}

      <Box mt={4} p={4} boxShadow={3} borderRadius={4} bgcolor="white">
        <Grid container spacing={3}>
          {/* Task Name */}
          <Grid item xs={12}>
            <TextField
              label="Task Name"
              value={task.name || ""}
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
            />
          </Grid>

          {/* Assigned To */}
          <Grid item xs={12}>
            <TextField
              label="Assigned To"
              value={
                task?.get_assigned_to
                  ? `${task.get_assigned_to.first_name} ${task.get_assigned_to.last_name}`
                  : "Not Assigned"
              }
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
            />
          </Grid>

          {/* Start Date */}
          <Grid item xs={6}>
            <TextField
              label="Start Date"
              value={moment(task.start_date).format("MM/DD/YYYY")}
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
            />
          </Grid>

          {/* End Date */}
          <Grid item xs={6}>
            <TextField
              label="End Date"
              value={moment(task.end_date).format("MM/DD/YYYY")}
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
            />
          </Grid>

          {/* Status */}
          <Grid item xs={12}>
            <TextField
              label="Status"
              value={task.get_status?.status || "Unknown"}
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
            />
          </Grid>

          {/* Task Priority */}
          <Grid item xs={12}>
            <TextField
              label="Priority"
              value={task.priority || "Not Set"}
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
            />
          </Grid>

          {/* Milestone */}
          <Grid item xs={12}>
            <TextField
              label="Milestone"
              value={task.milestone || "Not Set"}
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
            />
          </Grid>

          {/* Parent Task */}
          <Grid item xs={12}>
            <TextField
              label="Parent Task"
              value={
                task.parent_task ? task.parent_task.name : "No Parent Task"
              }
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
            />
          </Grid>
        </Grid>

        {/* Actions */}
        <Box mt={4} display="flex" justifyContent="center" gap={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowEditTaskModal(true)}
          >
            Edit Task
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete Task
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default TaskPage;
