import React from "react";
import {
  Dialog,
  DialogContent,
  Box,
  DialogTitle,
  Typography,
} from "@mui/material";
import XIcon from "../../icons/X";
const RegistrationSuccessfulModal = ({
  registrationModalState,
  handleRegistrationModalClose,
}) => {
  return (
    <Dialog open={registrationModalState}>
      <DialogTitle id="scroll-dialog-title">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box></Box>
          <Box>
            <XIcon
              sx={{ color: "#C0CBDE", cursor: "pointer" }}
              onClick={handleRegistrationModalClose}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pb: 3 }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                height: "15px",
                width: "15px",
                borderRadius: "15px",
                backgroundColor: "#FFDC99",
                mx: 1,
              }}
            ></Box>
            <Box
              sx={{
                height: "15px",
                width: "15px",
                borderRadius: "15px",
                backgroundColor: "#FFDC99",
                mx: 1,
              }}
            ></Box>
            <Box
              sx={{
                height: "15px",
                width: "15px",
                borderRadius: "15px",
                backgroundColor: "#FFDC99",
                mx: 1,
              }}
            ></Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography
              align="center"
              sx={{
                fontSize: "18px",
                lineHeight: "36px",
                fontWeight: "bold",
                color: "primary",
              }}
            >
              Registration in process!
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "24px",
                fontWeight: "bold",
                color: "primary",
                mt: 3,
              }}
            >
              We have received your registration form and it’s under process.
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "24px",
                fontWeight: "400",
                color: "primary",
                mt: 3,
              }}
            >
              Once you registration is complete, you will receive an email
              confirming your registration and you will be able to login to the
              system.
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "24px",
                fontWeight: "400",
                color: "primary",
                mt: 3,
              }}
            >
              Thank you for your patience. Cheers!
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RegistrationSuccessfulModal;
