import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { createReminder } from "../../../api/calender/Calender";
import SavedReminders from "./SavedReminders";

const AddReminderModal = (props) => {
  const { date, showAddReminderModal, onClose, userId } = props;
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Dialog
      open={showAddReminderModal}
      onClose={() => onClose()}
      fullWidth={true}
      maxWidth={"sm"}
      sx={{
        "& .MuiDialog-paper": {
          minHeight: "400px",
        },
      }}
    >
      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              mb: 2,
              "& .MuiTabs-indicator": {
                backgroundColor: "#7a4b8f",
              },
            }}
          >
            <Tab
              label={
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  Saved TO DOs
                </Typography>
              }
              sx={{
                textTransform: "none",
                "&.Mui-selected": {
                  color: "#7a4b8f",
                },
              }}
            />
            <Tab
              label={
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  Add TO DO
                </Typography>
              }
              sx={{
                textTransform: "none",
                "&.Mui-selected": {
                  color: "#7a4b8f",
                },
              }}
            />
          </Tabs>

          <Box sx={{ p: 2 }}>
            <TabPanel value={selectedTab} index={0}>
              <SavedReminders
                userId={userId}
                date={moment(date).format("YYYY-MM-DD")}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <Formik
                initialValues={{
                  reminder: "",
                  tag: "",
                  isPublic: false,
                }}
                validationSchema={Yup.object().shape({
                  reminder: Yup.string().required("TODO is required"),
                  tag: Yup.string().required("Tag is required"),
                  isPublic: Yup.boolean(),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    const res = await createReminder({
                      user_id: userId,
                      reminder: values.reminder,
                      tag: values.tag,
                      is_public: values.isPublic,
                      date: moment(date).format("YYYY-MM-DD"),
                    });
                    toast.success("Reminder added successfully");
                    setSubmitting(false);
                    onClose();
                  } catch (err) {
                    console.error(err);
                    toast.error("Something went wrong");
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Box sx={{ mt: 1 }}>
                      <TextField
                        label="TO DO"
                        required
                        error={Boolean(touched.reminder && errors.reminder)}
                        fullWidth
                        helperText={touched.reminder && errors.reminder}
                        name="reminder"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.reminder}
                        variant="outlined"
                        rows={3}
                        multiline
                      />
                      <FormControl
                        fullWidth
                        error={Boolean(touched.tag && errors.tag)}
                        sx={{ mt: 2 }}
                      >
                        <InputLabel id="tag-label">Tag</InputLabel>
                        <Select
                          labelId="tag-label"
                          id="tag"
                          name="tag"
                          value={values.tag}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Tag"
                        >
                          <MenuItem value="" disabled>
                            Select Tag
                          </MenuItem>
                          <MenuItem value="activities">Activities</MenuItem>
                          <MenuItem value="tasks">Tasks</MenuItem>
                          <MenuItem value="meetings">Meetings</MenuItem>
                          <MenuItem value="vacation">Vacation</MenuItem>
                          <MenuItem value="public_holiday">
                            Public Holiday
                          </MenuItem>
                        </Select>
                        {touched.tag && errors.tag && (
                          <FormHelperText>{errors.tag}</FormHelperText>
                        )}
                      </FormControl>

                      <Box display="flex" alignItems="center" mt={2}>
                        <Checkbox
                          id="isPublic"
                          name="isPublic"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.isPublic}
                          color="primary"
                        />
                        <Typography variant="body2">Visible to all</Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        mt: 3,
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: 1,
                      }}
                    >
                      <Button
                        onClick={() => onClose()}
                        disabled={isSubmitting}
                        variant="outlined"
                        sx={{
                          color: "#7a4b8f",
                          borderColor: "#7a4b8f",
                          "&:hover": {
                            borderColor: "#6a3b80",
                            backgroundColor: "rgba(122, 75, 143, 0.04)",
                          },
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        variant="contained"
                        sx={{
                          backgroundColor: "#7a4b8f",
                          "&:hover": {
                            backgroundColor: "#6a3b80",
                          },
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </TabPanel>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default AddReminderModal;
