import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Card,
  Container,
  Grid,
  Link,
  Typography,
  Tab,
  Tabs,
} from "@mui/material";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import CustomPortfolio from "../../components/dashboard/report/custom-report/CustomPortfolio";
import CustomTask from "../../components/dashboard/report/custom-report/CustomTask";
import CustomBudget from "../../components/dashboard/report/custom-report/CustomBudget";
import CustomProject from "../../components/dashboard/report/custom-report/CustomProject";
import CustomPeople from "../../components/dashboard/report/custom-report/CustomPeople";
import CustomTimeSheet from "../../components/dashboard/report/custom-report/CustomTimeSheet";
import SearchCustomReport from "../../components/dashboard/report/custom-report/SearchCustomReport";
import useCommons from "../../hooks/useCommons";
import CustomProjectMembers from "../../components/dashboard/report/custom-report/CustomProjectMembers";
const tabs = [
  { label: "Project", value: "project" },
  { label: "Portfolio", value: "portfolio" },
  { label: "Budget", value: "budget" },
  { label: "Task", value: "task" },
  { label: "Project Member", value: "projectMembers" },
  { label: "People", value: "people" },
  { label: "Time Sheet", value: "timesheet" },
  { label: "All Saved Reports", value: "search" },
];

const CustomReport = () => {
  const queryString = window.location.search;
  const parameters = new URLSearchParams(queryString);
  const activeTabParam = parameters.get("p");

  const { settings } = useSettings();
  const [currentTab, setCurrentTab] = useState(activeTabParam || "project");
  const [selectedFilter, setSelectedFilter] = useState(null);

  const { updateCurrentPath } = useCommons();

  const handleTabsChange = (event, value) => {
    window.history.pushState(
      null,
      null,
      `/dashboard/reports/custom?p=${value}`
    );
    updateCurrentPath();
    setCurrentTab(value);
  };

  useEffect(() => {
    if (selectedFilter) {
      setCurrentTab(selectedFilter?.view);
    }
  }, [selectedFilter]);

  return (
    <>
      <Helmet>
        <title>Dashboard: Custom Reports</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Custom Reports
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                Reports
              </Typography>
            </Grid>
          </Grid>
          <Card sx={{ pt: 3, mt: 2 }}>
            <Box>
              <Tabs
                indicatorColor="primary"
                onChange={handleTabsChange}
                scrollButtons="auto"
                textColor="primary"
                value={currentTab}
                variant="scrollable"
                sx={{
                  borderBottom: "1px solid #ccc",
                  ".MuiTab-wrapper": {
                    textTransform: "none",
                    fontSize: "12px",
                  },
                  ".Mui-selected": {
                    fontWeight: "bold",
                    color: "#000",
                  },
                  button: {
                    minWidth: "80px",
                    width: "12.5%",
                  },
                  ".MuiTabs-indicator": {
                    minWidth: "80px",
                    width: "12.5%",
                  },
                }}
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
            </Box>
            <Box>
              {currentTab === "portfolio" && (
                <CustomPortfolio
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                />
              )}
              {currentTab === "project" && (
                <CustomProject
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                />
              )}
              {currentTab === "budget" && (
                <CustomBudget
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                />
              )}
              {currentTab === "task" && (
                <CustomTask
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                />
              )}
              {currentTab === "projectMembers" && (
                <CustomProjectMembers
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                />
              )}
              {currentTab === "people" && (
                <CustomPeople
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                />
              )}
              {currentTab === "timesheet" && (
                <CustomTimeSheet
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                />
              )}
              {currentTab === "search" && (
                <SearchCustomReport setSelectedFilter={setSelectedFilter} />
              )}
            </Box>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default CustomReport;
