import React from "react";
import {
  Dialog,
  DialogContent,
  Box,
  DialogTitle,
  Typography,
} from "@mui/material";
import XIcon from "../icons/X";
const MessageModal = ({
  messageModalState,
  handleMessageModalClose,
  message,
}) => {
  return (
    <Dialog open={messageModalState}>
      <DialogTitle id="scroll-dialog-title">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box></Box>
          <Box>
            <XIcon
              sx={{ color: "#C0CBDE", cursor: "pointer" }}
              onClick={handleMessageModalClose}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pb: 3 }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                height: "15px",
                width: "15px",
                borderRadius: "15px",
                backgroundColor: "#333",
                mx: 1,
              }}
            ></Box>
            <Box
              sx={{
                height: "15px",
                width: "15px",
                borderRadius: "15px",
                backgroundColor: "#333",
                mx: 1,
              }}
            ></Box>
            <Box
              sx={{
                height: "15px",
                width: "15px",
                borderRadius: "15px",
                backgroundColor: "#333",
                mx: 1,
              }}
            ></Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "24px",
                fontWeight: "bold",
                color: "#333",
                mt: 1,
              }}
              align="center"
            >
              {message}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MessageModal;
