import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

function currencyFormatter(currency) {
  let sansDec = Math.round(currency) + "";
  let formatted = sansDec?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${formatted}`;
}

const styles = {
  backgroundColor: "#F5F5F5",
  "& th": {
    fontSize: "16px !important",
    color: "#0f63b0 !important",
    fontWeight: "bold !important",
  },
  "& td": {
    fontSize: "16px !important",
    color: "#0f63b0 !important",
    fontWeight: "bold !important",
  },
};

const ProjectRevenue = () => {
  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow sx={styles}>
            <TableCell>Product Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>YR 1</TableCell>
            <TableCell>YR 2</TableCell>
            <TableCell>YR 3</TableCell>
            <TableCell>YR 4</TableCell>
            <TableCell>YR 5</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={styles}>
            <TableCell>Holo X</TableCell>
            <TableCell></TableCell>
            <TableCell>FY 1</TableCell>
            <TableCell>FY 2</TableCell>
            <TableCell>FY 3</TableCell>
            <TableCell>FY 4</TableCell>
            <TableCell>FY 5</TableCell>
            <TableCell></TableCell>
          </TableRow>

          <TableRow>
            <TableCell></TableCell>
            <TableCell>Income/Revenue</TableCell>
            <TableCell></TableCell>
            <TableCell>{currencyFormatter(1200000)}</TableCell>
            <TableCell>{currencyFormatter(12000000)}</TableCell>
            <TableCell>{currencyFormatter(58800000)}</TableCell>
            <TableCell>{currencyFormatter(756000000)}</TableCell>
            <TableCell
              sx={{
                fontWeight: "bold !important",
              }}
            >
              {currencyFormatter(72000000)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell># of units sold</TableCell>
            <TableCell></TableCell>
            <TableCell>6</TableCell>
            <TableCell>60</TableCell>
            <TableCell>294</TableCell>
            <TableCell>756</TableCell>
            <TableCell
              sx={{
                fontWeight: "bold !important",
              }}
            >
              360
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Investement </TableCell>
            <TableCell>{currencyFormatter(2000000)}</TableCell>
            <TableCell>{currencyFormatter(1000000)}</TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold !important",
              }}
            >
              {currencyFormatter(3000000)}
            </TableCell>
          </TableRow>
          <TableRow sx={styles}>
            <TableCell>AdvaNan</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>

          <TableRow>
            <TableCell></TableCell>
            <TableCell>Income/Revenue</TableCell>
            <TableCell></TableCell>
            <TableCell>{currencyFormatter(1335360)} </TableCell>
            <TableCell>{currencyFormatter(1437181)} </TableCell>
            <TableCell>{currencyFormatter(16206263)}</TableCell>
            <TableCell>{currencyFormatter(81510374)}</TableCell>
            <TableCell
              sx={{
                fontWeight: "bold !important",
              }}
            >
              {currencyFormatter(100489178)}{" "}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell># of units sold</TableCell>
            <TableCell></TableCell>
            <TableCell>{currencyFormatter(417)}</TableCell>
            <TableCell>{currencyFormatter(8346)} </TableCell>
            <TableCell>{currencyFormatter(102339)} </TableCell>
            <TableCell>{currencyFormatter(525798)} </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold !important",
              }}
            >
              {currencyFormatter(636900)}{" "}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>SaaS Fees</TableCell>
            <TableCell></TableCell>
            <TableCell>{currencyFormatter(1251900)}</TableCell>
            <TableCell>{currencyFormatter(13770900)}</TableCell>
            <TableCell>{currencyFormatter(75114000)}</TableCell>
            <TableCell>{currencyFormatter(232853400)}</TableCell>
            <TableCell
              sx={{
                fontWeight: "bold !important",
              }}
            >
              {currencyFormatter(322990200)}{" "}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell># of treatments </TableCell>
            <TableCell></TableCell>
            <TableCell>{currencyFormatter(41730)}</TableCell>
            <TableCell>{currencyFormatter(417300)}</TableCell>
            <TableCell>{currencyFormatter(2044770)}</TableCell>
            <TableCell>{currencyFormatter(5257980)}</TableCell>
            <TableCell
              sx={{
                fontWeight: "bold !important",
              }}
            >
              {currencyFormatter(7761780)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Investement </TableCell>
            <TableCell>{currencyFormatter(1000000)}</TableCell>
            <TableCell>{currencyFormatter(1000000)}</TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold !important",
              }}
            >
              {currencyFormatter(2000000)}
            </TableCell>
          </TableRow>

          <TableRow sx={styles}>
            <TableCell>HET MRI Scanner</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>

          <TableRow>
            <TableCell></TableCell>
            <TableCell>Income</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>{currencyFormatter(6000000)}</TableCell>
            <TableCell>{currencyFormatter(12000000)}</TableCell>
            <TableCell
              sx={{
                fontWeight: "bold !important",
              }}
            >
              {currencyFormatter(18000000)}{" "}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell># of units sold</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>2</TableCell>
            <TableCell>4</TableCell>
            <TableCell
              sx={{
                fontWeight: "bold !important",
              }}
            >
              4
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Investement </TableCell>
            <TableCell>{currencyFormatter(2500000)}</TableCell>
            <TableCell>{currencyFormatter(3000000)}</TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold !important",
              }}
            >
              {currencyFormatter(5500000)}{" "}
            </TableCell>
          </TableRow>

          <TableRow sx={styles}>
            <TableCell>Portable HET Scanner</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>

          <TableRow>
            <TableCell></TableCell>
            <TableCell>Income</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>{currencyFormatter(3000000)}</TableCell>
            <TableCell
              sx={{
                fontWeight: "bold !important",
              }}
            >
              {currencyFormatter(3000000)}{" "}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell># of units sold</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>3</TableCell>
            <TableCell
              sx={{
                fontWeight: "bold !important",
              }}
            >
              3
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Investement </TableCell>
            <TableCell></TableCell>
            <TableCell>{currencyFormatter(1500000)}</TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold !important",
              }}
            >
              {currencyFormatter(1500000)}
            </TableCell>
          </TableRow>

          <TableRow sx={styles}>
            <TableCell>Holographic Display</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>

          <TableRow>
            <TableCell></TableCell>
            <TableCell>Income</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell
              sx={{
                fontWeight: "bold !important",
              }}
            >
              0{" "}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell># of units sold</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell
              sx={{
                fontWeight: "bold !important",
              }}
            >
              0
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Investement </TableCell>
            <TableCell>{currencyFormatter(1500000)}</TableCell>
            <TableCell>{currencyFormatter(1000000)}</TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold !important",
              }}
            >
              {currencyFormatter(2500000)}{" "}
            </TableCell>
          </TableRow>

          <TableRow sx={styles}>
            <TableCell>Corporate Operating expenses</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>

          <TableRow>
            <TableCell></TableCell>
            <TableCell>Income</TableCell>
            <TableCell></TableCell>
            <TableCell>18,500</TableCell>
            <TableCell>14,100</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell
              sx={{
                fontWeight: "bold !important",
              }}
            >
              3,2600{" "}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell># of units sold</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell
              sx={{
                fontWeight: "bold !important",
              }}
            >
              0
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Investement </TableCell>
            <TableCell>{currencyFormatter(1500000)}</TableCell>
            <TableCell>{currencyFormatter(1000000)}</TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold !important",
              }}
            >
              {currencyFormatter(2000000)}{" "}
            </TableCell>
          </TableRow>
          <TableRow></TableRow>
          <TableRow></TableRow>
          <TableRow></TableRow>

          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell
              sx={{
                fontSize: "20px !important",
                fontWeight: "bold !important",
              }}
            >
              TOTAL INVESTMENT{" "}
            </TableCell>
            <TableCell></TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell
              sx={{
                fontSize: "20px !important",
                fontWeight: "bold !important",
                color: "#FF0000",
              }}
            >
              {currencyFormatter(13500000)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell
              sx={{
                fontSize: "20px !important",
                fontWeight: "bold !important",
              }}
            >
              TOTAL REVENUES
            </TableCell>
            <TableCell></TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell
              sx={{
                fontSize: "20px !important",
                fontWeight: "bold !important",
                color: "#00FF00",
              }}
            >
              {currencyFormatter(516479378)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell
              sx={{
                fontSize: "20px !important",
                fontWeight: "bold !important",
              }}
            >
              RO1
            </TableCell>
            <TableCell></TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell
              sx={{
                fontSize: "20px !important",
                fontWeight: "bold !important",
              }}
            >
              3825.8%{" "}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default ProjectRevenue;
