import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import highchartsGantt from "highcharts/modules/gantt";
import moment from "moment";
import { getPortfolioDashboardGanttChart } from "../../../api/portfolio/Portfolio";
import useMounted from "../../../hooks/useMounted";

highchartsGantt(Highcharts);

const DashboardGanttChart = ({ id, authToken = null }) => {
  const mounted = useMounted();
  const [ganttData, setGanttData] = useState([]);
  const [chartOptions, setChartOptions] = useState({
    series: [{ data: [] }],
  });
  const chartRef = useRef(null);

  const fetchGanttData = useCallback(async () => {
    if (!mounted.current) return;

    try {
      const res = await getPortfolioDashboardGanttChart(id, authToken);
      if (mounted.current && res?.data?.data?.items.length > 0) {
        const ganttData = res?.data?.data?.items;

        const ganttDataWithUnixDate = ganttData.map((obj) => {
          if (obj.x && obj.x2) {
            const x1date = moment(obj.x, "YYYY-MM-DD")
              .format("YYYY-MM-DD")
              .split("-");
            const nextEndDay = moment(obj.x2, "YYYY-MM-DD")
              .add(1, "days")
              .format("YYYY-MM-DD");
            const x2date = nextEndDay.split("-");

            const x = Date.UTC(x1date[0], x1date[1], x1date[2]);
            const x2 = Date.UTC(x2date[0], x2date[1], x2date[2]);

            return { ...obj, x, x2 };
          }
          return obj;
        });

        if (mounted.current) {
          setChartOptions({
            chart: {
              type: "xrange",
            },
            rangeSelector: {
              enabled: true,
              buttons: [
                {
                  type: "month",
                  count: 12,
                  text: "12m",
                },
                {
                  type: "all",
                  text: "All",
                },
              ],
            },
            yAxis: {
              labels: {
                formatter: function () {
                  const projectId = ganttDataWithUnixDate.find(
                    (item) => item.name === this.value
                  )?.id;
                  if (projectId) {
                    return `<a href="/dashboard/projects/view-and-edit-project/${projectId}?p=dashboard">${this.value}</a>`;
                  }
                  return this.value;
                },
              },
            },
            scrollbar: { enabled: true, liveRedraw: false },
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true,
                },
                connectors: {
                  lineWidth: 1,
                  type: "fastAvoid",
                  startMarker: {
                    enabled: true,
                    symbol: "arrow-filled",
                    align: "left",
                  },
                },
              },
            },
            tooltip: {
              formatter: function () {
                const dt1 = new Date(this.x);
                const dt2 = new Date(this.x2);
                dt2.setDate(dt2.getDate() - 1);

                return `<b>Name: ${this.key}</b><br/>Start Date: <b>${
                  dt1.getMonth() === 0 ? 12 : dt1.getMonth()
                }-${dt1.getDate()}-${dt1.getFullYear()}</b>
            <br/>End Date: <b>${
              dt2.getMonth() === 0 ? 12 : dt2.getMonth()
            }-${dt2.getDate()}-${dt2.getFullYear()}</b><br/>
            `;
              },
            },
            series: [{ data: ganttDataWithUnixDate }],
          });
          setGanttData(ganttDataWithUnixDate);
        }
      }
    } catch (error) {
      console.error("Error fetching gantt data:", error);
    }
  }, [id, authToken, mounted]);

  useEffect(() => {
    let isActive = true;
    const abortController = new AbortController();

    fetchGanttData();

    return () => {
      isActive = false;
      abortController.abort();

      // Cleanup chart instance
      if (chartRef.current) {
        const chart = chartRef.current.chart;
        if (chart) {
          chart.destroy();
        }
      }

      // Reset state
      setGanttData([]);
      setChartOptions({ series: [{ data: [] }] });
    };
  }, [fetchGanttData]);

  return (
    <div>
      {ganttData?.length > 0 ? (
        <HighchartsReact
          constructorType="ganttChart"
          highcharts={Highcharts}
          options={chartOptions}
          ref={chartRef}
        />
      ) : (
        <Typography align="center" sx={{ p: 4 }} variant="h6" color="secondary">
          No projects available
        </Typography>
      )}
    </div>
  );
};

export default DashboardGanttChart;
