import {
  Avatar,
  Badge,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { Event } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { getTaskReminders } from "../api/common/Common";
import ChatAltIcon from "../icons/ChatAlt";
import CreditCardIcon from "../icons/CreditCard";
import ShoppingCartIcon from "../icons/ShoppingCart";

const iconsMap = {
  item_shipped: ShoppingCartIcon,
  new_message: ChatAltIcon,
  order_placed: CreditCardIcon,
};

const ReminderPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [reminders, setReminders] = useState([]);

  useEffect(() => {
    getReminders();
  }, []);

  const getReminders = async () => {
    const res = await getTaskReminders();
    const values = res?.data?.data?.values;
    if (!values) return;
    const data = values[Object.keys(values)[0]];
    if (data?.length) {
      setReminders(data);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Reminders">
        <IconButton
          size="small"
          color="inherit"
          ref={anchorRef}
          onClick={handleOpen}
        >
          <Badge color="error" badgeContent={reminders.length}>
            <Event fontSize="medium" sx={{ color: "#A0AABB" }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 370, maxHeight: "600px" },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="h6">
            Reminders
          </Typography>
        </Box>
        {reminders.length === 0 ? (
          <Box sx={{ p: 2 }}>
            <Typography color="textPrimary" variant="subtitle2">
              There are no reminders
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {reminders?.map((notification) => {
                const Icon = iconsMap["new_message"];

                return (
                  <>
                    <ListItem divider key={notification.id}>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            backgroundColor: notification.readAt
                              ? "#8A94A6"
                              : "primary.main",
                            color: "primary.contrastText",
                          }}
                        >
                          <Icon fontSize="small" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              color: notification.readAt
                                ? "#8A94A6"
                                : "primary",
                              fontWeight: "bold",
                            }}
                            variant="body2"
                          >
                            {notification.title}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            sx={{
                              color: notification.readAt
                                ? "#8A94A6"
                                : "primary",
                            }}
                            variant="body2"
                          >
                            {notification.description}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </>
                );
              })}
            </List>
          </>
        )}
      </Popover>
    </>
  );
};

export default ReminderPopover;
