import React, { useState } from "react";
import {
  FormHelperText,
  Box,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import useMounted from "../../../hooks/useMounted";
import { Formik } from "formik";
import toast from "react-hot-toast";
import FileUploader from "../../FileUploader";
import { importProject, importTasks } from "../../../api/project/Project";
const ImportTaskModal = ({
  showImportTaskModal,
  setShowImportTaskModal,
  onSuccess,
  selectedProject,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [overRide, setOverRide] = useState(true);
  const mounted = useMounted();

  const handleOverRide = () => {
    setOverRide((currVal) => !currVal);
  };

  return (
    <Dialog
      open={showImportTaskModal}
      onClose={() => setShowImportTaskModal(false)}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <Formik
        // enableReinitialize
        initialValues={{
          submit: null,
        }}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          let data = new FormData();
          data.append("file", selectedFile);
          data.append("projectId", selectedProject.id);
          data.append("overRide", overRide ? 1 : 0);

          try {
            const res = await importTasks(data, selectedProject.id, overRide);

            if (res.data.success) {
              toast.success("Project imported successfully");
              onSuccess();
              setShowImportTaskModal(false);
            }
            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({
              submit:
                "Unsupported file format. Please upload an excel file (.xls or .xlsx)",
            });
            setSubmitting(false);
          }
        }}
      >
        {({ errors, handleChange, handleSubmit, isSubmitting }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            enctype="multipart/form-data"
          >
            <DialogTitle>Import Project Plan</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FileUploader
                    onFileSelectSuccess={(file) => setSelectedFile(file)}
                    onFileSelectError={({ error }) => alert(error)}
                  />
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      ml: -1,
                      mt: 2,
                    }}
                  >
                    <Checkbox
                      checked={overRide}
                      color="primary"
                      name="policy"
                      onClick={handleOverRide}
                    />
                    <Typography color="textSecondary" variant="body2">
                      Do you want to override existing data?
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box sx={{ mt: 2 }}>
                <Link
                  to="/static/task_import_sample.xlsx"
                  target="_blank"
                  download
                  className="sample-download-btn"
                >
                  {" "}
                  Download Sample{" "}
                </Link>
              </Box>
            </DialogContent>
            <DialogActions>
              <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                <Box>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
                <Box sx={{ ml: 1 }}>
                  <Button
                    disabled={isSubmitting}
                    size="large"
                    variant="contained"
                    onClick={() => setShowImportTaskModal(false)}
                    sx={{ backgroundColor: "#C0CBDE" }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ImportTaskModal;
