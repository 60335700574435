import React, { useState, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink, useParams } from "react-router-dom";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import { getSingleUser } from "../../api/user/User";
import { fetchUserTasks } from "../../api/scheduling/Scheduling";
import AssignTask from "../../components/dashboard/scheduling/AssignTask";

const UserTasks = () => {
  const [userDetails, setuserDetails] = useState(null);
  const [userTasks, setUserTasks] = useState([]);
  const [showAssignTaskModal, setShowAssignTaskModal] = React.useState(false);
  const { settings } = useSettings();
  const { memberId } = useParams();

  const queryString = window.location.search;
  const parameters = new URLSearchParams(queryString);
  const projectId = parameters.get("projectId");

  useEffect(() => {
    getUserDetails();
    getUserTasks();
  }, [memberId]);

  const getUserDetails = async () => {
    const res = await getSingleUser({ userId: memberId });
    if (res.data.success) {
      setuserDetails(res.data.data.user);
    }
  };
  const getUserTasks = async () => {
    const res = await fetchUserTasks({
      userId: memberId,
      projectId: projectId,
    });
    if (res.data.success) {
      setUserTasks(res.data.data.userTasks);
    }
  };

  return (
    <>
      {showAssignTaskModal && (
        <AssignTask
          showAssignTaskModal={showAssignTaskModal}
          setShowAssignTaskModal={setShowAssignTaskModal}
          userId={memberId}
          onSuccess={getUserTasks}
        />
      )}
      <Helmet>
        <title>Dashboard: User tasks</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/dashboard/scheduling/view-all"
                  variant="breadCrumbText"
                >
                  scheduling
                </Link>
              </Breadcrumbs>
              {/* <Typography color="textPrimary" variant="dashboardHeading">
                Scheduling
              </Typography> */}
            </Grid>
          </Grid>
          <Box>
            <Box
              sx={{
                mt: 3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography color="textPrimary" variant="dashboardHeading">
                  {`${userDetails?.first_name} ${userDetails?.last_name} `}
                </Typography>
                <Box
                  sx={{
                    mt: 0.5,
                  }}
                >
                  <Typography
                    color="textPrimary"
                    component="span"
                    variant="dashboardInfo"
                  >
                    Role
                  </Typography>
                  <Typography
                    component="span"
                    variant="dashboardInfo"
                    sx={{
                      color: "#00000E",
                      pl: 1,
                    }}
                  >
                    {userDetails?.role_name}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box sx={{ m: -1 }}>
                  <Button
                    color="primary"
                    onClick={() => setShowAssignTaskModal(true)}
                    sx={{ m: 1 }}
                    variant="contained"
                  >
                    Assign Task
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Card sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>PROJECT TITLE</TableCell>
                  <TableCell>TASK NAME</TableCell>
                  <TableCell>STATUS</TableCell>
                  <TableCell>START DATE</TableCell>
                  <TableCell>END DATE</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userTasks.map((task) => (
                  <TableRow>
                    <TableCell>{task.get_project.name}</TableCell>
                    <TableCell>{task.name}</TableCell>
                    <TableCell>{task.status}</TableCell>
                    <TableCell>{task.start_date}</TableCell>
                    <TableCell>{task.end_date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default UserTasks;
