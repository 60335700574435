import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { ArrowDropDown, ArrowDropUp, PersonAddAlt } from "@mui/icons-material";
import React, { useState } from "react";
import AssignTaskToMember from "./AssignTaskToMember";
const checkHasActiveProjects = (projects) => {
  return Object.values(projects).some((project) => {
    return Object.values(project).some((projectData) => {
      return projectData?.project_id !== undefined;
    });
  });
};

const UtilizationRow = ({
  userUtilization,
  id,
  viewPercentage,
  view,
  calculateHours,
  allDateKeys,
  startDate,
  endDate,
}) => {
  const [open, setOpen] = useState(false);
  const [assignTaskTo, setAssignTaskTo] = useState(null);

  // Convert projects to a format that is easier to work with and make it array of objects instead of object of objects, each object is a project which has a project_id, project_name, and dates object. Compare the dates object to the allDateKeys array to make sure that all dates are accounted for.
  const convertProjects = (projects) => {
    const defaultDates = {};
    allDateKeys.forEach((dateKey) => {
      defaultDates[dateKey] = 0;
    });
    const convertedProjects = [];
    Object.keys(projects).forEach((dateKey) => {
      Object.keys(projects[dateKey]).forEach((projectKey) => {
        const project = projects[dateKey][projectKey];
        const existingProject = convertedProjects.find(
          (project) => project.project_id === projectKey
        );
        if (existingProject) {
          existingProject.dates[dateKey] = project.utilization;
        } else {
          convertedProjects.push({
            project_id: projectKey,
            project_name: project.project_name,
            dates: {
              ...defaultDates,
              [dateKey]: project.utilization,
            },
          });
        }
      });
    });
    return convertedProjects;
  };

  // useEffect(() => {
  //   if (allDateKeys.length) {
  //     convertProjects(tempProjects);
  //   }
  // }, [allDateKeys]);

  // useEffect(() => {
  //   if (assignTaskTo) {
  //     setOpen(true);
  //   }
  // }, [assignTaskTo]);

  const renderIndividualProjectsWithUtilization = (projects) => {
    const convertedProjects = convertProjects(projects);
    if (convertedProjects.length) {
      return convertedProjects.map((project, index) => (
        <TableRow
          key={project.project_id}
          sx={{
            backgroundColor: index % 2 === 0 ? "white" : "#f5f5f5",
            "& td": {
              py: 1.5,
              px: 0,
            },
          }}
        >
          <TableCell></TableCell>
          <TableCell>{project.project_name}</TableCell>
          {Object.keys(project.dates).map((dateKey, dateIndex) => (
            <TableCell
              sx={{
                color: project.dates[dateKey] >= 100 ? "red" : "black",
                fontSize: "12px",
                fontWeight: "bold",
              }}
              key={dateKey}
            >
              {viewPercentage ? (
                <span>{Math.round(+project.dates[dateKey])}</span>
              ) : (
                <span>
                  {calculateHours(dateKey, project.dates[dateKey], view)}
                </span>
              )}
            </TableCell>
          ))}
        </TableRow>
      ));
    }
  };

  return (
    <React.Fragment>
      {assignTaskTo && (
        <AssignTaskToMember
          isModalOpen={assignTaskTo}
          setIsModalOpen={setAssignTaskTo}
          userId={id}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      <TableRow>
        <TableCell
          sx={{
            px: 0,
          }}
        >
          {open ? (
            <>
              <IconButton onClick={() => setOpen(false)}>
                <ArrowDropUp />
              </IconButton>
            </>
          ) : (
            <IconButton onClick={() => setOpen(true)}>
              <ArrowDropDown />
            </IconButton>
          )}
          <IconButton onClick={() => setAssignTaskTo(id)}>
            <Tooltip title="Assign Task">
              <PersonAddAlt />
            </Tooltip>
          </IconButton>
        </TableCell>
        <TableCell>
          {userUtilization[id].first_name} {userUtilization[id].last_name}
        </TableCell>
        {Object.keys(userUtilization[id].dates).map((dateKey, dateIndex) => (
          <TableCell
            sx={{
              color:
                userUtilization[id].dates[dateKey] >= 100 ? "red" : "black",
              fontSize: "12px",
              fontWeight: "bold",
            }}
            key={dateKey}
          >
            {viewPercentage
              ? Math.round(userUtilization[id].dates[dateKey])
              : calculateHours(
                  dateKey,
                  userUtilization[id].dates[dateKey],
                  view
                )}
          </TableCell>
        ))}
      </TableRow>
      {open && (
        <>
          {viewPercentage && (
            <TableRow>
              <TableCell
                sx={{
                  px: 0,
                }}
              ></TableCell>
              <TableCell>Availability</TableCell>
              {Object.keys(userUtilization[id].dates).map(
                (dateKey, dateIndex) => (
                  <TableCell
                    sx={{
                      color:
                        userUtilization[id].dates[dateKey] >= 100
                          ? "red"
                          : "black",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                    key={dateKey}
                  >
                    {viewPercentage
                      ? 100 - Math.round(userUtilization[id].dates[dateKey])
                      : ""}
                  </TableCell>
                )
              )}
            </TableRow>
          )}
          {checkHasActiveProjects(userUtilization[id].projects) ? (
            <>
              <TableRow
                sx={{
                  "& td": {
                    py: 1,
                    px: 0,
                  },
                }}
              >
                <TableCell></TableCell>
                <TableCell>Active Projects</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>

              {allDateKeys.length &&
                renderIndividualProjectsWithUtilization(
                  userUtilization[id].projects
                )}
            </>
          ) : (
            <TableRow>
              <TableCell></TableCell>
              <TableCell
                sx={{ color: "red", fontSize: "12px", fontWeight: "bold" }}
              >
                No Active Projects
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default UtilizationRow;
