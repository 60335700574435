import { useState, useEffect } from "react";
import {
  Box,
  Card,
  InputAdornment,
  TableBody,
  Pagination,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import useTable from "../../../hooks/useTable";
import SingleMember from "./SingleMember";
import { getAllPeople } from "../../../api/people/People";
import SearchIcon from "../../../icons/Search";
import CreateNewMemberModal from "./CreateNewMemberModal";
import useLoader from "../../../hooks/useLoader";
import useAuth from "../../../hooks/useAuth";

const ViewAllPeopleListTable = (props) => {
  const { showAddMemberModal, setShowAddMemberModal, ...other } = props;
  const { permissions } = useAuth();
  const headCells = [
    { id: "first_name", label: "MEMBER NAME" },
    { id: "email", label: "EMAIL" },
    { id: "designation", label: "	DESIGNATION" },
    { id: "department ", label: "	DEPARTMENT " },
  ];

  if (permissions["PEOPLE_UPDATE"] || permissions["PEOPLE_DELETE"]) {
    headCells.push({
      id: "action",
      label: "ACTION",
      disableSorting: true,
      align: "center",
    });
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [nameSearchValue, setNameSearchValue] = useState("");
  const [filter, setFilter] = useState("");

  const [peoples, setPeoples] = useState([]);

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    peoples.data,
    headCells
  );

  const { loaderState, loaderStart, loaderEnd } = useLoader();

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    getAllPeopleUnderAdmin();
  }, [currentPage, nameSearchValue, filter]);

  const getAllPeopleUnderAdmin = async () => {
    loaderStart();
    const res = await getAllPeople({
      page: currentPage,
      uname: nameSearchValue,
      sort: filter,
    });
    if (res?.data?.success) {
      setPeoples(res.data.data.users);
    }
    loaderEnd();
  };

  const searchHandler = (e) => {
    e.preventDefault();
    setNameSearchValue(e.target.value);
    setCurrentPage(1);
  };

  return (
    <>
      {showAddMemberModal && (
        <CreateNewMemberModal
          showAddMemberModal={showAddMemberModal}
          setShowAddMemberModal={setShowAddMemberModal}
          getAllPeopleUnderAdmin={getAllPeopleUnderAdmin}
        />
      )}
      <Card {...other}>
        <Box
          sx={{
            m: -1,
            p: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              variant="dashboardContentHeader"
              sx={{
                color: "#2D3E56",
                mb: "18px",
                display: "inline",
              }}
            >
              All People
            </Typography>
            <Box sx={{ mt: 2 }}>
              <FormControl>
                <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                  Sort By{" "}
                </InputLabel>
                <Select
                  label="Sort By"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  sx={{
                    width: "200px",
                  }}
                >
                  <MenuItem selected value={""}>
                    None
                  </MenuItem>
                  <MenuItem value={"first_name"}>Name</MenuItem>
                  <MenuItem value={"designation"}>Designation</MenuItem>
                  <MenuItem value={"department"}>Department</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              m: 1,
              maxWidth: "100%",
              width: 240,
            }}
          >
            <TextField
              label="Search User"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              onChange={searchHandler}
              placeholder="Enter user name"
              value={nameSearchValue}
              variant="outlined"
            />
          </Box>
        </Box>
        <Box sx={{ pt: 3 }}>
          <TblContainer>
            <TblHead />
            {peoples?.data?.length > 0 && (
              <TableBody>
                {recordsAfterPagingAndSorting().map((member, index) => {
                  return (
                    <SingleMember
                      getAllPeopleUnderAdmin={getAllPeopleUnderAdmin}
                      member={member}
                      key={index}
                      index={index}
                    />
                  );
                })}
              </TableBody>
            )}
          </TblContainer>
          <Box sx={{ py: 4, display: "flex", justifyContent: "center" }}>
            {peoples?.data?.length > 0 ? (
              <Pagination
                page={currentPage}
                onChange={handleChange}
                count={peoples.last_page}
              />
            ) : (
              <Typography variant="h6" color="secondary">
                {loaderState ? "Loading..." : "No Records Available"}
              </Typography>
            )}
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default ViewAllPeopleListTable;
