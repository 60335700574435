import {
  Box,
  Card,
  TableBody,
  Typography,
  InputAdornment,
  TextField,
} from "@mui/material";
import useTable from "../../../hooks/useTable";
import SingleCompanyAccessInfo from "./SingleCompanyAccessInfo";
import SearchIcon from "../../../icons/Search";

const headCells = [
  { id: "company_name", label: "COMPANY NAME" },
  { id: "code", label: "CODE" },
  { id: "valid_from_date", label: "VALID FROM" },
  { id: "valid_to_date", label: "VALID TO" },
  { id: "current_status", label: "Subscription type" },
  { id: "paymentdone", label: "STATE" },
  { id: "custom_report", label: "REPORTS" },
  { id: "subscription_notes", label: "Notes" },
  { id: "action", label: "ACTION", disableSorting: true, align: "center" },
];

const CompanyAccessLevelListTable = ({
  companies,
  getCompanies,
  nameSearchValue,
  searchHandler,
}) => {
  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    companies,
    headCells
  );

  return (
    <Card>
      <Box
        sx={{
          m: -1,
          p: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            variant="dashboardContentHeader"
            sx={{
              color: "#2D3E56",
              mb: "18px",
              display: "block",
            }}
          >
            Access Level Settings
          </Typography>
        </Box>
        <Box
          sx={{
            m: 1,
            maxWidth: "100%",
            width: 240,
          }}
        >
          <TextField
            label="Search Company"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={searchHandler}
            placeholder="Enter company name"
            value={nameSearchValue}
            variant="outlined"
          />
        </Box>
      </Box>
      <Box>
        {companies?.length ? (
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((companyDetails) => {
                return (
                  <SingleCompanyAccessInfo
                    key={companyDetails.id}
                    companyDetails={companyDetails}
                    getCompanies={getCompanies}
                  />
                );
              })}
            </TableBody>
          </TblContainer>
        ) : (
          <Box sx={{ py: 4, display: "flex", justifyContent: "center" }}>
            <Typography variant="h6" color="secondary">
              No Records Available
            </Typography>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default CompanyAccessLevelListTable;
