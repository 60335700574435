import React from "react";
import { Box, Button, Tooltip } from "@mui/material";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
export default ({ params, setDate, setViewOnlyDate }) => {
  const addClicked = () => {
    setDate(params.data.date);
  };
  const viewClicked = () => {
    setViewOnlyDate(params.data);
  };

  return (
    <span>
      {!params?.node?.rowPinned ? (
        <Box>
          <Tooltip title="Add Details">
            <Button
              variant="contained"
              size="small"
              onClick={() => addClicked()}
              sx={{
                padding: "4px 4px",
                minWidth: "30px",
                backgroundColor: "#7a7af9",
                color: "#ffffff !important",
                border: "1px solid blue !important",
                ":hover": {
                  backgroundColor: "#7a7af9",
                  border: "1px solid #fff !important",
                },
              }}
            >
              +
            </Button>
          </Tooltip>
          <Tooltip title="View Details">
            <Button
              variant="outlined"
              size="small"
              onClick={() => viewClicked()}
              sx={{
                padding: "4px 4px",
                minWidth: "30px",
                ml: 1,
                backgroundColor: "#fff",
                color: "#7a7af9 !important",
                border: "1px solid blue !important",
                ":hover": {
                  backgroundColor: "#7a7af9",
                  color: "#fff !important",
                  border: "1px solid #fff !important",
                },
              }}
            >
              <EyeIcon
                fontSize="small"
                sx={{
                  padding: "2px 2px",
                }}
              />
            </Button>
          </Tooltip>
        </Box>
      ) : (
        <></>
      )}
    </span>
  );
};
