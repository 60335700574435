import {
  Box,
  Breadcrumbs,
  Card,
  Container,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import Report from "../sprint/Report";
const tabs = [{ label: "Sprint Reports", value: "sprint-reports" }];

export default function MoreReports() {
  const { settings } = useSettings();
  const [currentTab, setCurrentTab] = useState(tabs[0].value);

  return (
    <>
      <Helmet>
        <title>Dashboard: More Reports</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  More Reports
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Card sx={{ pt: 3, mt: 2 }}>
            <Box>
              <Tabs
                indicatorColor="primary"
                onChange={(_e, value) => setCurrentTab(value)}
                scrollButtons="auto"
                textColor="primary"
                value={currentTab}
                variant="scrollable"
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
            </Box>
            <Box>{currentTab === "sprint-reports" && <Report />}</Box>
          </Card>
        </Container>
      </Box>
    </>
  );
}
