import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import {
  getAllProjectTasks,
  orderProjectTasks,
} from "../../../../api/project/Project";
import useLoader from "../../../../hooks/useLoader";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import SingleTask from "./SingleTask";

const RearrangeModal = ({
  showModal,
  setShowModal,
  selectedProject,
  onSuccess,
}) => {
  const [allTasks, setAllTasks] = useState([]);
  const { loaderStart, loaderEnd } = useLoader();
  useEffect(() => {
    getTaskList();
  }, []);

  const getTaskList = async () => {
    try {
      loaderStart();
      const res = await getAllProjectTasks(selectedProject.id);
      if (res.data.success) {
        setAllTasks(res.data.data.tasks);
      }
      loaderEnd();
    } catch (error) {
      loaderEnd();
    }
  };

  const reOrderTasks = async (taskId, order) => {
    try {
      loaderStart();
      const res = await orderProjectTasks(taskId, order);
      if (res.data.success) {
        getTaskList();
      }
      loaderEnd();
    } catch (error) {
      console.log(error);
      loaderEnd();
    }
  };

  const handleClose = () => {
    setShowModal(false);
    onSuccess();
  };

  return (
    <Dialog open={showModal} onClose={handleClose} maxWidth={"lg"} fullWidth>
      <DialogContent>
        <Box sx={{ background: "background.default", px: 1, py: 2 }}>
          <Grid container sx={{ pb: 2, borderBottom: "1px solid #444" }}>
            <Grid item xs={8}>
              <Typography className="treeview-header">NAME</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="treeview-header">START DATE</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="treeview-header">END DATE</Typography>
            </Grid>
          </Grid>
          {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 1,
          }}
        >
          <Box>
            <Typography
              sx={{
                minWidth: "140px",
                maxWidth: "500px",
                width: "50%",
              }}
              className="treeview-header"
            >
              NAME
            </Typography>
          </Box>
          <Box sx={{ width: "20%" }}>
            <Typography className="treeview-header">START DATE</Typography>
          </Box>
          <Box sx={{ width: "20%" }}>
            <Typography className="treeview-header">END DATE</Typography>
          </Box>
          <Box sx={{ width: "10%" }}>
            <Typography className="treeview-header">STATUS</Typography>
          </Box>
        </Box> */}
          {allTasks.length > 0 && (
            <DragDropContext
              onDragEnd={(param) => {
                const srcI = param.source.index + 1;
                const desI = param.destination?.index + 1;
                const draggableId = param.draggableId.split("#");

                if (draggableId[0] === "m" && desI) {
                  if (srcI !== desI) {
                    //   reorderMilestone(draggableId[1], desI););
                    console.log("First drag", srcI, desI, draggableId[1]);
                    reOrderTasks(draggableId[1], desI);
                  }
                  // console.log("Milestone " + draggableId[1]);
                  // console.log("Prev Order = " + srcI);
                  // console.log("Curr Order = " + desI);
                }
              }}
            >
              <Box>
                <Droppable droppableId="droppable-1" type="droppableItem">
                  {(provided, _) => (
                    <Box ref={provided.innerRef}>
                      {allTasks.map((item, i) => (
                        <SingleTask
                          key={item.id}
                          item={item}
                          i={i}
                          selectedProject={selectedProject}
                          getTaskList={getTaskList}
                        />
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </Box>
            </DragDropContext>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            p: 1,
          }}
        >
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default RearrangeModal;
