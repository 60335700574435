import {
  Card,
  CardHeader,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import Chart from "react-apexcharts";

const BurnDownChart = ({ data }) => {
  // State for chart type
  const [chartType, setChartType] = useState("line");

  // Static data for two sprints
  const sprints = data;

  const options = {
    chart: {
      type: chartType,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM dd",
          day: "MMM dd",
          hour: "HH:mm",
        },
        format: "MMM dd",
        style: {
          fontSize: "12px",
        },
        rotate: 0,
      },
      tickAmount: 6,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
    },
    yaxis: {
      title: {
        text: "Remaining Points",
        style: {
          fontSize: "14px",
          fontWeight: 500,
        },
      },
      min: 0,
      tickAmount: 5,
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: 4,
      colors: undefined,
      strokeColors: "#fff",
      strokeWidth: 2,
      hover: {
        size: 6,
      },
    },
    tooltip: {
      x: {
        format: "MMM dd",
      },
    },
    legend: {
      show: true,
      position: "top",
      fontSize: "14px",
    },
  };

  return (
    <>
      <FormControl variant="outlined" sx={{ mb: 2, width: 200 }}>
        <InputLabel>Select Chart Type</InputLabel>
        <Select
          value={chartType}
          onChange={(e) => setChartType(e.target.value)}
          label="Select Chart Type"
          fullWidth
        >
          <MenuItem value="line">Line</MenuItem>
          <MenuItem value="area">Area</MenuItem>
          <MenuItem value="bar">Bar</MenuItem>
        </Select>
      </FormControl>
      <Grid container>
        {sprints.length === 0 ? (
          <></>
        ) : (
          sprints.map((sprint, index) => {
            const series = [
              {
                name: `Burn down chart of ${sprint.name}`,
                data: Object.keys(sprint.burn_down).map((key) => ({
                  x: new Date(key).getTime(),
                  y: sprint.burn_down[key],
                })),
              },
            ];

            return (
              <Grid item md={6} key={index}>
                <Card sx={{ mb: 1, boxShadow: "none" }}>
                  <CardHeader
                    subheader={`Sprint Burn-Down Chart: ${sprint.name}`}
                  />
                  <Chart
                    options={options}
                    series={series}
                    type={chartType}
                    height={350}
                  />
                </Card>
              </Grid>
            );
          })
        )}
      </Grid>
    </>
  );
};

export default BurnDownChart;
