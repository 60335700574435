import { Grid, TextField, FormControl } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: theme.shape.borderRadius,
    },
  },
  datePicker: {
    width: "100%",
  },
}));

const ProjectDateRange = ({
  selectedProject,
  monthWiseBudgets,
  setMonthWiseBudgets,
  projectStartDate,
  setProjectStartDate,
  projectDueDate,
  setProjectDueDate,
}) => {
  const classes = useStyles();
  const initialDates = {
    startDate: moment(selectedProject?.start_date),
    dueDate: moment(selectedProject?.due_date),
  };
  const [rangeStartDate, setRangeStartDate] = useState(moment());
  const [rangeEndDate, setRangeEndDate] = useState(moment());

  const handleStartDateChange = (date) => {
    setProjectStartDate(date);
    const currentStartDate = moment(date);
    if (
      initialDates.startDate.format("YYYY-MM") !==
      currentStartDate.format("YYYY-MM")
    ) {
      const monthDiff = monthWiseBudgets.length - 1;
      const currentDueDate = currentStartDate.clone().add(monthDiff, "month");
      const tempDate = currentStartDate.clone();
      const budgetData = monthWiseBudgets;
      let tempBudgets = [];
      let i = 0;
      while (
        currentDueDate > tempDate ||
        tempDate.format("M") === currentDueDate.format("M")
      ) {
        tempBudgets.push({
          ...budgetData[i],
          date: tempDate.format("YYYY-MM"),
        });
        i++;
        tempDate.add(1, "month");
      }
      setMonthWiseBudgets(tempBudgets);
      setProjectDueDate(currentDueDate);
    }
  };

  const handleProjectDueDateChange = (date) => {
    const currentStartDate = moment(projectStartDate);
    const currentDueDate = moment(date);
    setProjectDueDate(date);

    if (
      initialDates.startDate.format("YYYY-MM") !==
        currentStartDate.format("YYYY-MM") ||
      initialDates.dueDate.format("YYYY-MM") !==
        currentDueDate.format("YYYY-MM")
    ) {
      const tempDate = currentStartDate.clone();
      const budgetData = monthWiseBudgets;
      let tempBudgets = [];
      while (
        currentDueDate > tempDate ||
        tempDate.format("M") === currentDueDate.format("M")
      ) {
        const matchPrevDates = budgetData.filter(
          (obj) => obj?.date === tempDate.format("YYYY-MM")
        );
        if (matchPrevDates?.length) {
          tempBudgets.push(matchPrevDates[0]);
        } else {
          tempBudgets.push({
            date: tempDate.format("YYYY-MM"),
            budget: 0,
            actual: 0,
            note: "",
          });
        }
        tempDate.add(1, "month");
      }
      setMonthWiseBudgets(tempBudgets);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <FormControl
            fullWidth
            className={classes.formControl}
            required
            error={!rangeStartDate}
          >
            <DatePicker
              label="Range Start Date"
              value={rangeStartDate}
              onChange={(newValue) => {
                if (newValue && moment.isMoment(newValue)) {
                  setRangeStartDate(newValue);
                  setRangeEndDate(newValue);
                }
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: "outlined",
                  className: `${classes.datePicker} pt-datepicker-ip`,
                },
              }}
            />
          </FormControl>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <FormControl
            fullWidth
            className={classes.formControl}
            required
            error={!rangeEndDate}
          >
            <DatePicker
              label="Range End Date"
              value={rangeEndDate}
              onChange={(newValue) => {
                if (newValue && moment.isMoment(newValue)) {
                  setRangeEndDate(newValue);
                }
              }}
              minDate={rangeStartDate}
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: "outlined",
                  className: `${classes.datePicker} pt-datepicker-ip`,
                },
              }}
            />
          </FormControl>
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};

export default ProjectDateRange;
