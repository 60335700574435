import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import axios from "axios";
import { Field, Formik } from "formik";
import moment from "moment";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import * as Yup from "yup";
import currencyMaskOptions from "../../../@const/currencyMaskOptions";
import portfolioSize from "../../../@const/portfolioSize";
import portfolioStatus from "../../../@const/portfolioStatus";
import useCommons from "../../../hooks/useCommons";
import useSettings from "../../../hooks/useSettings";
import { getNumbers } from "../../../utils/getNumber";
import CustomAutocomplete from "../../CustomAutocomplete";
import CommonAddMemberModal from "../CommonAddMemberModal";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ViewAndEditPortfolioForm = (props) => {
  const { editActive, setEditActive, selectedPortfolio, ...others } = props;
  const [allPortfolioTypes, setAllPortfolioTypes] = useState([]);
  const [portFolioTypeData, setPortFolioTypeData] = useState("");
  const [newPortfolioName, setNewPortfolioName] = useState("");
  const [portfolioCreatedOn, setPortfolioCreatedOn] = useState(new Date());
  const [taskAssignedTo, setTaskAssignedTo] = useState(null);
  const [blurActive, setBlurActive] = useState("");
  const [portfolioStartDate, setPortfolioStartDate] = useState(
    moment(selectedPortfolio?.created_at)
  );
  const [portfolioEndDate, setPortfolioEndDate] = useState(
    moment(selectedPortfolio?.created_at)
  );

  const navigate = useNavigate();
  const classes = useStyles();

  const [formInitialValues, setFormInitialValues] = useState({
    portfolioName: "",
    estimatedBudget: "",
    estimatedWorkHours: "",
    portfolioSize: "",
    portfolioStatus: "",
    porfolioLocation: "",
    porfolioDescription: "",
  });

  const { portfolioId } = useParams();
  const {
    showAddMemberModal,
    setShowAddMemberModal,
    dropdownMemberList,
    getDropdownMemberList,
  } = useCommons();

  const { settings } = useSettings();
  const currencyMask = createNumberMask({
    ...currencyMaskOptions,
  });

  useEffect(() => {
    if (allPortfolioTypes.length > 0) {
      getSinglePortfolioDetails();
    }
  }, [allPortfolioTypes]);

  useEffect(() => {
    getPortfolioType();
    if (dropdownMemberList.length === 0) {
      getDropdownMemberList();
    }
  }, []);
  const handlePortFolioType = async () => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/portfolio/type`,
        {
          type_name: newPortfolioName,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setNewPortfolioName("");
      if (response?.data?.success) {
        getPortfolioType();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getPortfolioType = async () => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/portfolio/type`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response?.data?.success) {
        setAllPortfolioTypes(response.data.data.portfolioTypes);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getSinglePortfolioDetails = async () => {
    try {
      setFormInitialValues({
        portfolioName: selectedPortfolio?.name,
        estimatedBudget: selectedPortfolio?.estimated_budget || 0,
        estimatedWorkHours: selectedPortfolio?.estimated_hours || 0,
        portfolioSize: selectedPortfolio?.portfolio_size,
        portfolioStatus: selectedPortfolio?.status,
        porfolioLocation: selectedPortfolio?.address,
        porfolioDescription: selectedPortfolio?.description,
        portfolioActualCost: selectedPortfolio?.actualCost,
      });
      setTaskAssignedTo(selectedPortfolio?.get_contact_person);

      const separateDateToArr = selectedPortfolio?.created_on.split("-");
      setPortfolioCreatedOn(
        `${separateDateToArr[0]}/${separateDateToArr[1]}/${separateDateToArr[2]}`
      );
      const portfolioTypeObj = allPortfolioTypes.filter((portfolioType) => {
        if (portfolioType.id === selectedPortfolio?.portfolio_type) {
          return portfolioType;
        }
      });
      setPortFolioTypeData(portfolioTypeObj[0]);
      setNewPortfolioName("");
    } catch (err) {
      console.error(err);
    }
  };

  const handleProtfolioTypeChange = (e) => {
    e.preventDefault();
    setPortFolioTypeData(e.target.value);
  };

  const handleUChange = (e, val) => {
    setTaskAssignedTo(val);
  };

  return (
    <>
      {showAddMemberModal && (
        <CommonAddMemberModal
          showAddMemberModal={showAddMemberModal}
          setShowAddMemberModal={setShowAddMemberModal}
        />
      )}

      <Box sx={{ mt: 3 }}>
        <>
          <Box
            sx={{
              m: -1,
              p: 3,
            }}
          >
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                pb: 6,
              }}
            >
              <Typography
                variant="dashboardContentHeader"
                sx={{
                  color: "#2D3E56",
                  display: "block",
                }}
              >
                {editActive ? "Edit Portfolio" : "Portfolio Details"}
              </Typography>
            </Box> */}
            <Formik
              enableReinitialize
              initialValues={{
                ...formInitialValues,

                submit: null,
              }}
              validationSchema={Yup.object().shape({
                portfolioName: Yup.string()
                  .max(255)
                  .required("Portfolio Name is required"),
                estimatedWorkHours: Yup.number()
                  .typeError("you must specify a number")
                  .nullable(),
                // portfolioType: Yup.object().required(
                //   "Portfolio Type is required"
                // ),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  const accessToken =
                    window.localStorage.getItem("accessToken");

                  const response = await axios.put(
                    `${process.env.REACT_APP_BACKEND_URL}/api/portfolio/${portfolioId}`,
                    {
                      name: values.portfolioName,
                      portfolio_type: portFolioTypeData.id,
                      contact_person: taskAssignedTo?.id || "",
                      estimated_budget: getNumbers(values?.estimatedBudget),
                      estimated_hours: values.estimatedWorkHours,
                      portfolio_size: values.portfolioSize,
                      created_on:
                        moment(portfolioCreatedOn).format("YYYY/MM/DD/"),
                      status: values.portfolioStatus || "",
                      address: values.porfolioLocation,
                      description: values.porfolioDescription,
                      actual_cost: values.portfolioActualCost,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${accessToken}`,
                      },
                    }
                  );
                  if (response.data.success) {
                    toast.success("Portfolio Updated Successfully");
                    navigate("/dashboard/portfolios/view-all-portfolios");
                  }
                } catch (err) {
                  console.error(err);
                  toast.error("Can't update portfolio");
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit} {...others}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="Portfolio Name"
                        required
                        disabled={!editActive}
                        error={Boolean(
                          touched.portfolioName && errors.portfolioName
                        )}
                        fullWidth
                        helperText={
                          touched.portfolioName && errors.portfolioName
                        }
                        margin="normal"
                        name="portfolioName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.portfolioName}
                        variant="outlined"
                        sx={{ mt: 0 }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        error={Boolean(
                          touched.portfolioType && errors.portfolioType
                        )}
                        required
                      >
                        <InputLabel id="demo-simple-select-label">
                          Portfolio Type
                        </InputLabel>
                        <Select
                          label="Portfolio Type *"
                          error={Boolean(
                            touched.portfolioType && errors.portfolioType
                          )}
                          disabled={!editActive}
                          fullWidth
                          helperText={
                            touched.portfolioType && errors.portfolioType
                          }
                          margin="normal"
                          name="portfolioType"
                          value={portFolioTypeData}
                          onChange={handleProtfolioTypeChange}
                          renderValue={() => portFolioTypeData.type_name}
                        >
                          {allPortfolioTypes.map((portfolioType) => (
                            <MenuItem
                              key={portfolioType.id}
                              value={portfolioType}
                            >
                              <Radio
                                checked={
                                  portfolioType.id === portFolioTypeData.id
                                }
                              />
                              <ListItemText primary={portfolioType.type_name} />
                            </MenuItem>
                          ))}
                          <Box sx={{ px: 3, py: 2 }}>
                            <TextField
                              fullWidth
                              onKeyDown={(e) => e.stopPropagation()}
                              margin="normal"
                              name="addNewPortfolioType"
                              onChange={(e) =>
                                setNewPortfolioName(e.target.value)
                              }
                              value={newPortfolioName}
                              variant="outlined"
                              sx={{ mt: 0 }}
                            ></TextField>
                            <Button
                              color="primary"
                              size="large"
                              variant="contained"
                              fullWidth
                              sx={{ py: 2, mt: 1 }}
                              onClick={handlePortFolioType}
                            >
                              Save
                            </Button>
                          </Box>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Autocomplete
                        disabled={!editActive}
                        disablePortal
                        id="combo-box-demo"
                        blurOnSelect={true}
                        options={dropdownMemberList}
                        value={taskAssignedTo}
                        onChange={handleUChange}
                        PaperComponent={CustomAutocomplete}
                        getOptionLabel={(option) =>
                          `${option.first_name} ${option.last_name}`
                        }
                        renderOption={(props, option) => {
                          return (
                            <li
                              {...others}
                              key={`${option.first_name} ${option.last_name} ${option.id}`}
                            >
                              {`${option.first_name} ${option.last_name}`}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Contact Person"
                            name="cPerson"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl
                        fullWidth
                        className={`${classes.formControl} formikInputWrapper ${
                          blurActive === "projectBudget" ? "blured" : ""
                        }`}
                        error={
                          errors.estimatedBudget && touched.estimatedBudget
                        }
                      >
                        <InputLabel
                          id="demo-simple-select-label"
                          sx={{ backgroundColor: "#fff", px: 1 }}
                          shrink
                        >
                          Estimated Budget
                        </InputLabel>

                        <Box className="custom-mui-input-wrapper">
                          <Field
                            name="estimatedBudget"
                            render={({ field }) => (
                              <MaskedInput
                                {...field}
                                mask={currencyMask}
                                id="estimatedBudget"
                                placeholder="$"
                                type="text"
                                onChange={handleChange}
                                value={values.estimatedBudget}
                                onBlur={(e) => {
                                  setBlurActive(false);
                                  handleBlur(e);
                                }}
                                onFocus={() => {
                                  setBlurActive(true);
                                }}
                                disabled={!editActive}
                                className={
                                  errors.estimatedBudget &&
                                  touched.estimatedBudget
                                    ? "text-input error custom-mui-input"
                                    : "text-input custom-mui-input"
                                }
                              />
                            )}
                          />
                        </Box>
                        <FormHelperText>
                          {touched.estimatedBudget && errors.estimatedBudget}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="Estimated Work Hours"
                        disabled={!editActive}
                        error={Boolean(
                          touched.estimatedWorkHours &&
                            errors.estimatedWorkHours
                        )}
                        fullWidth
                        helperText={
                          touched.estimatedWorkHours &&
                          errors.estimatedWorkHours
                        }
                        margin="normal"
                        name="estimatedWorkHours"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.estimatedWorkHours}
                        variant="outlined"
                        sx={{ mt: 0 }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="Portfolio Size"
                        disabled={!editActive}
                        error={Boolean(
                          touched.portfolioSize && errors.portfolioSize
                        )}
                        fullWidth
                        helperText={
                          touched.portfolioSize && errors.portfolioSize
                        }
                        margin="normal"
                        name="portfolioSize"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.portfolioSize}
                        variant="outlined"
                        sx={{ mt: 0 }}
                        select
                        SelectProps={{ native: true }}
                      >
                        <option value=""></option>
                        {portfolioSize.map((individualSize) => (
                          <option value={individualSize.value}>
                            {individualSize.title}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <FormControl
                          fullWidth
                          className={classes.formControl}
                          required
                          error={!portfolioStartDate}
                        >
                          <DatePicker
                            label="Portfolio Start Date"
                            value={portfolioStartDate}
                            onChange={(newValue) => {
                              if (newValue && moment.isMoment(newValue)) {
                                setPortfolioStartDate(newValue);
                                setPortfolioEndDate(newValue);
                              }
                            }}
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                variant: "outlined",
                                className: "pt-datepicker-ip",
                              },
                            }}
                          />
                        </FormControl>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <FormControl
                          fullWidth
                          className={classes.formControl}
                          required
                          error={!portfolioEndDate}
                        >
                          <DatePicker
                            label="Portfolio End Date"
                            value={portfolioEndDate}
                            onChange={(newValue) => {
                              if (newValue && moment.isMoment(newValue)) {
                                setPortfolioEndDate(newValue);
                              }
                            }}
                            minDate={portfolioStartDate}
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                variant: "outlined",
                                className: "pt-datepicker-ip",
                              },
                            }}
                          />
                        </FormControl>
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="Portfolio Status"
                        disabled={!editActive}
                        error={Boolean(
                          touched.portfolioStatus && errors.portfolioStatus
                        )}
                        fullWidth
                        helperText={
                          touched.portfolioStatus && errors.portfolioStatus
                        }
                        margin="normal"
                        name="portfolioStatus"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.portfolioStatus}
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        sx={{ mt: 0 }}
                      >
                        {portfolioStatus.map((status) => (
                          <option value={status.value}>{status.title}</option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Portfolio Location"
                        disabled={!editActive}
                        error={Boolean(
                          touched.porfolioLocation && errors.porfolioLocation
                        )}
                        fullWidth
                        helperText={
                          touched.porfolioLocation && errors.porfolioLocation
                        }
                        margin="normal"
                        name="porfolioLocation"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.porfolioLocation}
                        variant="outlined"
                        sx={{ mt: 0 }}
                        rows={2}
                        multiline
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label=" Portfolio Description"
                        disabled={!editActive}
                        error={Boolean(
                          touched.porfolioDescription &&
                            errors.porfolioDescription
                        )}
                        fullWidth
                        helperText={
                          touched.porfolioDescription &&
                          errors.porfolioDescription
                        }
                        margin="normal"
                        name="porfolioDescription"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.porfolioDescription}
                        variant="outlined"
                        sx={{ mt: 0 }}
                        rows={2}
                        multiline
                      />
                    </Grid>
                  </Grid>

                  {errors.submit && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}

                  {editActive && (
                    <Box sx={{ display: "flex", mr: "auto", pb: 1, mt: 3 }}>
                      <Box>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Save
                        </Button>
                      </Box>

                      <Box sx={{ px: 1 }}>
                        <Button
                          color="secondary"
                          size="large"
                          variant="contained"
                          onClick={() => setEditActive(false)}
                        >
                          cancel
                        </Button>
                      </Box>
                    </Box>
                  )}
                </form>
              )}
            </Formik>
          </Box>
        </>
      </Box>
    </>
  );
};

export default ViewAndEditPortfolioForm;
