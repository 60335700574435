import { Backdrop, CircularProgress } from "@mui/material";
import useLoader from "../hooks/useLoader";
import { useEffect } from "react";

const Loader = () => {
  // loader
  const { loaderState, loaderEnd } = useLoader();

  useEffect(() => {
    let timeoutId;
    
    if (loaderState) {
      // Set a 10-second timeout to end the loader
      timeoutId = setTimeout(() => {
        loaderEnd();
      }, 10000); // 10 seconds in milliseconds
    }

    // Cleanup timeout on unmount or when loaderState changes
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [loaderState, loaderEnd]);

  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 100000000000,
          opacity: "0.1 !important",
        }}
        open={loaderState}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Loader;
