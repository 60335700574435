import { Box, TableCell, TableRow, Tooltip } from "@mui/material";
import { Close } from "@mui/icons-material";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { approveTime, deleteTimeEntry } from "../../../api/time/Time";
import useAuth from "../../../hooks/useAuth";
import CheckIcon from "../../../icons/Check";
import AddPreveiousTime from "./AddPreviousTime";
import EditTimeEntry from "./EditTimeEntry";
const SinglePendingProjectHour = ({
  singleTask,
  week,
  year,
  selectedUser,
  getUserTimeLogHistory,
}) => {
  const { user } = useAuth();
  const [showPreviousTimeModal, setShowPreviousTimeModal] = useState(false);
  const [showEditTimeModal, setShowEditTimeModal] = useState(false);

  const approveUserTime = async () => {
    const res = await approveTime({
      groupID: singleTask.id,
      adminID: user.id,
    });
    if (res.data.success) {
      getUserTimeLogHistory();
      toast.success("Time Log Approved");
    }
  };

  const disApproveUserTime = async () => {
    const res = await approveTime({
      groupID: singleTask.id,
      adminID: user.id,
      approved: 0,
    });
    if (res.data.success) {
      getUserTimeLogHistory();
      toast.success("Time Log UnApproved");
    }
  };

  const deleteTimeEntryRecoed = async () => {
    const res = await deleteTimeEntry({
      groupID: singleTask.id,
    });
    if (res.data.success) {
      toast.success("Log Deleted");
      getUserTimeLogHistory();
    }
  };

  return (
    <>
      {showPreviousTimeModal && (
        <AddPreveiousTime
          showPreviousTimeModal={showPreviousTimeModal}
          setShowPreviousTimeModal={setShowPreviousTimeModal}
          week={week}
          year={year}
          selectedUser={selectedUser}
          getUserTimeLogHistory={getUserTimeLogHistory}
        />
      )}
      {showEditTimeModal && (
        <EditTimeEntry
          showEditTimeModal={showEditTimeModal}
          setShowEditTimeModal={setShowEditTimeModal}
          week={week}
          year={year}
          selectedUser={selectedUser}
          getUserTimeLogHistory={getUserTimeLogHistory}
          currentTimeEntry={singleTask}
        />
      )}
      <TableRow
        sx={{
          td: {
            borderBottom: "1px solid #ECF1F9",
          },
        }}
      >
        <TableCell>
          <Box sx={{ display: "flex" }}>
            {!singleTask.isApproved ? (
              <Tooltip title="Approve">
                <Box
                  component="span"
                  sx={{
                    p: 1,
                    backgroundColor: "#c6fef46b",
                    display: "inherit",
                    lineHeight: 0,
                    borderRadius: "6px",
                    cursor: "pointer",
                  }}
                  onClick={approveUserTime}
                >
                  <CheckIcon className="action-icon time-check" />
                </Box>
              </Tooltip>
            ) : (
              <Tooltip title="Disapprove">
                <Box
                  component="span"
                  sx={{
                    p: 1,
                    backgroundColor: "#FB6C7E26",
                    display: "inherit",
                    lineHeight: 0,
                    borderRadius: "6px",
                  }}
                  onClick={disApproveUserTime}
                >
                  <Close className="action-icon time-delete" />
                </Box>
              </Tooltip>
            )}
          </Box>
        </TableCell>
        <TableCell sx={{ maxWidth: "150px" }}>
          <b>Project:</b> {singleTask?.projectName} <br />
          <b>Billable:</b> {singleTask?.billable ? "Yes" : "No"}
          <br />
          <b>Override Rate:</b> {singleTask?.overrideRate ? "Yes" : "No"}
          <br />
          <b>Description:</b> {singleTask?.description}
        </TableCell>
        <TableCell></TableCell>
        {singleTask?.weeklyReport.map((singleDayInAWeek) => (
          <TableCell>{singleDayInAWeek.hrs}</TableCell>
        ))}
        <TableCell sx={{ fontWeight: "600" }}>
          {singleTask?.totalWeekTaskHours}
        </TableCell>
      </TableRow>
    </>
  );
};

export default SinglePendingProjectHour;
