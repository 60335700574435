// import { Box, Button, Step, StepLabel, Stepper } from '@mui/material'
// import React, { useState } from 'react'
// import { Formik, Form } from 'formik';
// import RoomStep1 from './RoomStep1';

// const BookRoomIntakeForm = () => {
//   const [activeStep, setActiveStep] = useState(0);
//   const steps = ['Shipping address', 'Payment details', 'Review your order'];
//   const isLastStep = activeStep === steps.length - 1;

//   function _sleep(ms) {
//     return new Promise(resolve => setTimeout(resolve, ms));
//   }
//   async function _submitForm(values, actions) {
//     await _sleep(1000);
//     alert(JSON.stringify(values, null, 2));
//     actions.setSubmitting(false);

//     setActiveStep(activeStep + 1);
//   }
//   function _handleNext() {
//     if (isLastStep) {
//       _submitForm(values, actions);
//     } else {
//       setActiveStep(activeStep + 1);
//     //   actions.setTouched({});
//     //   actions.setSubmitting(false);
//     }
//   }

//   function _renderStepContent(step) {
//     switch (step) {
//       case 0:
//         return <RoomStep1 />;
//       case 1:
//         return <RoomStep1 />;
//       case 2:
//         return <RoomStep1 />;
//       default:
//         return <div>Not Found</div>;
//     }
//   }

//   function _handleBack() {
//     setActiveStep(activeStep - 1);
//   }

//     return (
//         <>
//          {/* MUI Multistep form for booking a room */}
//         <Box>
//             <Stepper activeStep={activeStep}>
//                 {steps.map(label => (
//                 <Step key={label}>
//                     <StepLabel>{label}</StepLabel>
//                 </Step>
//                 ))}
//                 {_renderStepContent(activeStep)}
//                 {activeStep !== 0 && (
//                     <Button onClick={_handleBack} >
//                       Back
//                     </Button>
//                   )}
//                 <Button
//                     //   disabled={isSubmitting}
//                     //   type="submit"
//                       variant="contained"
//                       color="primary"
//                       onClick={_handleNext}
//                     >
//                       {isLastStep ? 'Place order' : 'Next'}
//                     </Button>
//         </Stepper>
//         </Box>
//     </>
//   )
// }

// export default BookRoomIntakeForm

import React from "react";

const BookRoomIntakeForm = () => {
  return <div>BookRoomIntakeForm</div>;
};

export default BookRoomIntakeForm;
