import React, { useState } from "react";
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography,
  Card,
  Tab,
  Tabs,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
// import DayView from "../../components/dashboard/scheduling/DayView";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import MonthView from "../../components/dashboard/scheduling/MonthView";
import WeekView from "../../components/dashboard/scheduling/WeekView";
import useCommons from "../../hooks/useCommons";

const tabs = [
  // { label: "Day View", value: "dayView" },
  { label: "Week View", value: "weekView" },
  { label: "Month View", value: "monthView" },
];

const Scheduling = () => {
  const queryString = window.location.search;
  const parameters = new URLSearchParams(queryString);
  const activeTabParam = parameters.get("p");

  const [currentTab, setCurrentTab] = useState(activeTabParam || "weekView");

  const { settings } = useSettings();
  const { updateCurrentPath } = useCommons();

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
    window.history.pushState(
      null,
      null,
      `/dashboard/scheduling/view-all?p=${value}`
    );
    updateCurrentPath();
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Scheduling</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Scheduling
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                Scheduling
              </Typography>
            </Grid>
          </Grid>
          <Card sx={{ mt: 2 }}>
            <Box>
              <Box sx={{ borderBottom: "1px solid #D7DFEC" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* {currentTab === "projectDetails" && (
                    <Box>
                      {!editActive && (
                        <Button
                          startIcon={<PencilAltIcon />}
                          onClick={() => {
                            setEditActive(true);
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </Box>
                  )} */}
                </Box>
                <Box>
                  <Tabs
                    indicatorColor="primary"
                    onChange={handleTabsChange}
                    scrollButtons="auto"
                    textColor="primary"
                    value={currentTab}
                    variant="scrollable"
                    sx={{
                      ".MuiTab-wrapper": {
                        textTransform: "none",
                        fontSize: "12px",
                      },
                      ".Mui-selected": {
                        fontWeight: "bold",
                        color: "#000 !important",
                      },
                      button: {
                        minWidth: "80px",
                        width: "33.33%",
                      },
                      ".MuiTabs-indicator": {
                        minWidth: "60px",
                        width: "33.33%",
                      },
                    }}
                  >
                    {tabs.map((tab) => (
                      <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                      />
                    ))}
                  </Tabs>
                </Box>
              </Box>
              <Box sx={{ mt: 1, p: 2 }}>
                {/* {currentTab === "dayView" && <DayView />} */}
                {currentTab === "weekView" && <WeekView />}
                {currentTab === "monthView" && <MonthView />}
              </Box>
            </Box>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Scheduling;
