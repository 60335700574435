import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Provider as ReduxProvider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { AuthProvider } from "./contexts/JWTContext";
import { PrtfolioProvider } from "./contexts/PortfolioContext";
import { ProjectProvider } from "./contexts/ProjectContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { LoaderProvider } from "./contexts/LoaderContext";
import { CommonProvider } from "./contexts/Common";
import store from "./store";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <HelmetProvider>
    <ReduxProvider store={store}>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <SettingsProvider>
            <BrowserRouter>
              <LoaderProvider>
                <AuthProvider>
                  <CommonProvider>
                    <PrtfolioProvider>
                      <ProjectProvider>
                        <App />
                      </ProjectProvider>
                    </PrtfolioProvider>
                  </CommonProvider>
                </AuthProvider>
              </LoaderProvider>
            </BrowserRouter>
          </SettingsProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </ReduxProvider>
  </HelmetProvider>
);
