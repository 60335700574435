import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import { getAllDesignationUtilization } from "../../../api/calender/Calender";

const AvailabilityChart = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const chartRef = useRef(null);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const res = await getAllDesignationUtilization();
        const allData = res.data.data.values || [];

        if (isMounted) {
          setData(allData);
          setLoading(false);
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message || "An error occurred");
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      if (chartRef.current) {
        try {
          chartRef.current.destroy();
        } catch (error) {
          console.error("Error destroying chart:", error);
        }
      }
    };
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  const options = {
    chart: {
      id: "availability-bar",
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: data.map((item) => item.designation),
      title: { text: "Designation" },
      labels: { style: { fontSize: "8px" } },
    },
    yaxis: {
      title: { text: "Availability %" },
      labels: { formatter: (val) => Math.round(val) },
    },
    title: {
      text: "Availability % by Designation",
      align: "left",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        distributed: false,
      },
    },
  };

  const series = [
    {
      name: "Availability %",
      data: data.map((item) =>
        100 - item.utilization_percentage > 0
          ? Math.round(100 - item.utilization_percentage)
          : 0
      ),
    },
  ];

  return (
    <Box style={{ width: "100%", maxWidth: "1200px", margin: "0 auto" }}>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        width="100%"
        height={400}
      />
    </Box>
  );
};

export default AvailabilityChart;
