import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  Button,
  TextField,
  DialogTitle,
  Grid,
  FormHelperText,
  Checkbox,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import toast from "react-hot-toast";
import useMounted from "../../../../hooks/useMounted";
import { updateMonthlyBudgetData } from "../../../../api/budget/Budget";

const ShowEditMonthlyBudgetDetailsModal = ({
  showModal,
  setShowModal,
  budgetDetails,
  onSuccess,
}) => {
  const [addOnlyExpenses, setAddOnlyExpenses] = useState(
    budgetDetails.budget === 0
  );
  const mounted = useMounted();
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <Formik
        initialValues={{
          summery: budgetDetails.summery,
          budget: budgetDetails.budget,
          actual: budgetDetails.actual,
          notes: budgetDetails.notes,
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          summery: Yup.string().max(255).required("Reason is required"),
          budget: Yup.number().required("Budget is required"),
          actual: Yup.number().required("Actual is required"),
          notes: Yup.string().max(255).nullable(),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const res = await updateMonthlyBudgetData(
              budgetDetails.id,
              values.summery,
              values.budget,
              values.actual,
              values.notes
            );

            if (res.data.success) {
              onSuccess(
                budgetDetails.id,
                values.budget,
                values.actual,
                budgetDetails.date
              );
              toast.success("Budget edited successfully", {
                duration: 5000,
              });
              handleClose();
            }
            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            toast.error("Something went wrong");
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogTitle>Edit Budget Detail</DialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    label="Reason"
                    required
                    error={Boolean(touched.summery && errors.summery)}
                    fullWidth
                    helperText={touched.summery && errors.summery}
                    margin="normal"
                    name="summery"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.summery}
                    variant="outlined"
                    sx={{ mt: 0 }}
                  />
                </Grid>
                {!addOnlyExpenses && (
                  <Grid item xs={12}>
                    <TextField
                      label="Budget"
                      required
                      error={Boolean(touched.budget && errors.budget)}
                      fullWidth
                      helperText={touched.budget && errors.budget}
                      margin="normal"
                      name="budget"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.budget}
                      variant="outlined"
                      sx={{ mt: 0 }}
                      type="number"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    label="Actual"
                    required
                    error={Boolean(touched.actual && errors.actual)}
                    fullWidth
                    helperText={touched.actual && errors.actual}
                    margin="normal"
                    name="actual"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.actual}
                    variant="outlined"
                    sx={{ mt: 0 }}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Notes"
                    error={Boolean(touched.notes && errors.notes)}
                    fullWidth
                    helperText={touched.notes && errors.notes}
                    margin="normal"
                    name="notes"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.notes}
                    variant="outlined"
                    sx={{ mt: 0 }}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    checked={addOnlyExpenses}
                    color="primary"
                    onChange={(e) => {
                      setAddOnlyExpenses(e.target.checked);
                      handleChange({
                        target: {
                          name: "budget",
                          value: 0,
                        },
                      });
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <label>Actuals Only</label>
                </Grid>
              </Grid>

              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                <Box>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
                <Box sx={{ ml: 1 }}>
                  <Button
                    disabled={isSubmitting}
                    size="large"
                    variant="contained"
                    onClick={handleClose}
                    sx={{ backgroundColor: "#C0CBDE" }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ShowEditMonthlyBudgetDetailsModal;
