import { handleApiRequest } from "../../hooks/callApi";

// getAllUsers
export const getAllUsersAPI = async (pageNum, roleFilter, userNameSearch) => {
  const currentPage = pageNum || 1;
  const res = await handleApiRequest(
    `/api/user/search?page=${currentPage}&memberName=${
      userNameSearch || ""
    }&memberType=${roleFilter || ""}`,
    "get"
  );
  return res;
};

export const getAllUserRequestsAPI = async (pageNum) => {
  const currentPage = pageNum || 1;
  const res = await handleApiRequest(
    `/api/user/unauthorized-users?page=${currentPage}`,
    "get"
  );
  return res;
};

export const addCredentialAPI = async (credential) => {
  const body = {
    first_name: credential.fName,
    last_name: credential.lName,
    email: credential.email,
    username: credential.username,
    password: credential.password,
  };
  const res = await handleApiRequest(`/api/user`, "post", body);
  return res;
};

export const acceptUserRequestAPI = async (uid, userState, isActive) => {
  const body = {
    is_authorized: userState,
    is_active: isActive,
  };
  const res = await handleApiRequest(`/api/user/${uid}`, "put", body);
  return res;
};

// Get Single User
export const getSingleUser = async ({ userId }) => {
  const res = await handleApiRequest(`/api/user/${userId}`, "get");
  return res;
};

// Edit user cred
export const editUserCredential = async ({
  userId,
  firstName,
  lastName,
  email,
  company,
  password,
  role,
}) => {
  const body = {
    first_name: firstName,
    last_name: lastName,
    email: email,
    company: company || null,
    password: password,
    role: role,
  };

  const res = await handleApiRequest(`/api/user/${userId}`, "put", body);

  return res;
};

// Delete Single User
export const deleteUser = async ({ userId }) => {
  const res = await handleApiRequest(`/api/user/${userId}`, "delete");
  return res;
};

// Forget password
export const forgotPassword = async (email) => {
  const body = {
    email: email,
  };
  const res = await handleApiRequest(`/api/forgot`, "post", body);

  return res;
};

export const resetPassword = async (token, password, cPassword) => {
  const body = {
    token: token,
    password: password,
    password_confirm: cPassword,
  };
  const res = await handleApiRequest(`/api/reset`, "post", body);

  return res;
};

export const getAllCompanies = async (companyName) => {
  const res = await handleApiRequest(
    `/api/adminAccess/comapnyData?name=${companyName || ""}`,
    "get"
  );

  return res;
};

export const updateCompanyAccess = async (companyId, hasReportAccess) => {
  const body = {
    company_id: companyId,
    value: hasReportAccess,
  };
  const res = await handleApiRequest(
    `/api/user/company-subscription`,
    "post",
    body
  );

  return res;
};

export const updateCompanyPaymentStatus = async (companyId, companyState) => {
  const body = {
    company_id: companyId,
    companyState: companyState,
  };
  const res = await handleApiRequest(`/api/user/company-status`, "post", body);

  return res;
};

// Only for super admin
export const changeCompanyID = async (companyId) => {
  const body = {
    company_id: companyId,
  };
  const res = await handleApiRequest(`/api/user/change-company`, "post", body);

  return res;
};

export const updatValidityDates = async (
  companyId,
  fromDate,
  toDate,
  currentStatus,
  notes
) => {
  const body = {
    company_id: companyId,
    valid_from_date: fromDate,
    valid_to_date: toDate,
    subscription_notes: notes,
    current_status: currentStatus,
  };
  const res = await handleApiRequest(
    `/api/user/company-subscription`,
    "post",
    body
  );

  return res;
};

export const updateUserNotificationPreference = async (
  userId,
  notificationsEnabled
) => {
  try {
    const response = await handleApiRequest(
      `/api/notification/preference`,
      "put",
      {
        user_id: userId,
        notifications_enabled: notificationsEnabled,
      }
    );
    return response;
  } catch (err) {
    console.error("Error updating notification preference:", err);
    throw err;
  }
};
