import React, { useState } from "react";
import TrashIcon from "../../../icons/Trash";
import { TableCell, TableRow, Box, Typography } from "@mui/material";

import PDFThumb from "../../../assets/doc_icons/pdf.png";
import DOCThumb from "../../../assets/doc_icons/doc.png";
import PPTThumb from "../../../assets/doc_icons/ppt.png";
import XLSThumb from "../../../assets/doc_icons/xls.png";
import FileThumb from "../../../assets/doc_icons/file.png";
import useLoader from "../../../hooks/useLoader";
import { deleteDocument, downloadDocument } from "../../../api/project/Project";
import toast from "react-hot-toast";
import DeleteModal from "../../DeleteModal";

const SingleDocument = ({ document, index, getProjectDocuments }) => {
  const { loaderStart, loaderEnd } = useLoader();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const downloadDoc = async () => {
    try {
      if (document.doc_link.startsWith("http")) {
        window.open(document.doc_link);
      } else {
        loaderStart();
        const res = await downloadDocument({ documentId: document.id });
        window.open(res.data.data.tempLink);
        loaderEnd();
      }
    } catch (error) {
      loaderEnd();
      console.log(error);
    }
  };

  const deleteDocumentFromProject = async () => {
    loaderStart();
    try {
      const res = await deleteDocument({ documentId: document.id });
      if (res.data.success) {
        toast.success("Document Deleted Successfully");
        getProjectDocuments();
      }
      loaderEnd();
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
      loaderEnd();
    }
  };

  return (
    <>
      <DeleteModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        onYesClick={deleteDocumentFromProject}
        warningMessage="Are you sure you want to delete this document?"
      />

      <TableRow hover>
        <TableCell>{++index}</TableCell>
        <TableCell>
          <Typography
            title={document.title}
            sx={{
              width: "1000%",
            }}
            color="textPrimary"
            underline="none"
            variant="subtitle2"
          >
            {document.title}
          </Typography>
        </TableCell>
        <TableCell>{document.get_uploaded_by.email}</TableCell>
        <TableCell>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              component="img"
              sx={{ width: "40px" }}
              src={
                document.extension === "pdf"
                  ? PDFThumb
                  : document.extension === "doc"
                  ? DOCThumb
                  : document.extension === "ppt"
                  ? PPTThumb
                  : document.extension === "xlsx" ||
                    document.extension === "xlsm" ||
                    document.extension === "xlsb" ||
                    document.extension === "xls"
                  ? XLSThumb
                  : FileThumb
              }
            />
          </Box>
        </TableCell>
        <TableCell
          sx={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "120px",
            overflow: "hidden",

            cursor: "pointer",
          }}
          title={document.doc_link}
        >
          <Box>
            <Typography onClick={downloadDoc}>{document.doc_link}</Typography>
          </Box>
        </TableCell>
        <TableCell align="center">
          {/* <PencilAltIcon className="action-icon" /> */}
          <TrashIcon
            title="Delete"
            className="action-icon"
            onClick={() => setShowDeleteModal(true)}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default SingleDocument;
