import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import experience from "../../../@const/experience";
import phoneNumberMask from "../../../@const/phoneNumberMaskOptions";
import skills from "../../../@const/skills";
import { getDepartmentsForDropdown } from "../../../api/department/Department";
import { addNewPeople } from "../../../api/people/People";
import useAuth from "../../../hooks/useAuth";
import useCommons from "../../../hooks/useCommons";
import useMounted from "../../../hooks/useMounted";
import { getPhoneNumber } from "../../../utils/getPhoneNumber";
import { getAllDesignations } from "../../../api/admin/Designation";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

// Custom Phone Input Component
const PhoneInput = ({ field, form, ...props }) => {
  const handleChange = (e) => {
    const value = e.target.value;
    form.setFieldValue(field.name, value);
  };

  return (
    <TextField
      {...field}
      {...props}
      value={field.value}
      onChange={handleChange}
      inputProps={{
        maxLength: 14,
        placeholder: "(XXX) XXX-XXXX",
      }}
    />
  );
};

const CreateNewMemberModal = (props) => {
  const { showAddMemberModal, setShowAddMemberModal, getAllPeopleUnderAdmin } =
    props;

  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [blurActive, setBlurActive] = useState(false);

  const mounted = useMounted();
  const { user } = useAuth();
  const classes = useStyles();
  const { getCounts } = useCommons();

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      if (!user?.id) return;

      try {
        const [designationsRes, departmentsRes] = await Promise.all([
          getAllDesignations(),
          getDepartmentsForDropdown({ adminId: user.id }),
        ]);

        if (!isSubscribed) return;

        if (designationsRes?.data) {
          setDesignations(designationsRes.data);
        }

        if (departmentsRes?.data?.success) {
          setDepartments(departmentsRes.data.data.departments);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, [user?.id]);

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const handleFormSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    if (!mounted.current) return;

    try {
      const memberData = {
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        phone: getPhoneNumber(values.phone),
        department: values.department,
        role: "3",
        hourly_rate: values.hourly_rate,
        skills: `[${values.skills.toString()}]`,
        experience: values.experience,
        designation: values.designation,
        daily_work_hours: values.dailyWorkHours,
      };

      const res = await addNewPeople(memberData);

      if (!mounted.current) return;

      if (res.data.success) {
        toast.success("New member added");
        getCounts();
        setShowAddMemberModal(false);
        if (getAllPeopleUnderAdmin) {
          getAllPeopleUnderAdmin();
        }
      }

      setStatus({ success: true });
      setSubmitting(false);
    } catch (err) {
      console.error(err);
      if (!mounted.current) return;

      setStatus({ success: false });
      setErrors({ submit: "Invalid attempt" });
      setSubmitting(false);
    }
  };

  return (
    <>
      <Dialog
        open={showAddMemberModal}
        onClose={() => setShowAddMemberModal(false)}
      >
        <Formik
          initialValues={{
            email: "",
            first_name: "",
            last_name: "",
            phone: "",
            department: "",
            role: "",
            hourly_rate: "",
            skills: [],
            experience: "",
            dailyWorkHours: "",
            designation: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
            first_name: Yup.string()
              .max(255)
              .required("First Name is required"),
            last_name: Yup.string().max(255).required("Last Name is required"),
            // department: Yup.string().max(255).nullable(),
            designation: Yup.string()
              .max(255)
              .required("Job Title is required"),
            hourly_rate: Yup.number()
              .max(9999)
              .required("Hourly Rate is required"),
            dailyWorkHours: Yup.number()
              .max(12)
              .required("Hours Per Day is required")
              .typeError("Hours Per Day Must be a number"),
            skills: Yup.array().nullable(),
            experience: Yup.string().max(255).nullable(),
            phone: Yup.string()
              .test("len", "Invalid phone number", (val = "") => {
                const phoneNoLen = getPhoneNumber(val).toString().length;
                if (phoneNoLen === 0) {
                  return true;
                }

                return phoneNoLen === 10;
              })
              .nullable(),
          })}
          onSubmit={handleFormSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          }) => (
            <form noValidate onSubmit={handleSubmit} {...props}>
              <DialogTitle>Create New People 1</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="First Name"
                      required
                      error={Boolean(touched.first_name && errors.first_name)}
                      fullWidth
                      helperText={touched.first_name && errors.first_name}
                      margin="normal"
                      name="first_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.first_name}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Last Name"
                      required
                      error={Boolean(touched.last_name && errors.last_name)}
                      fullWidth
                      helperText={touched.last_name && errors.last_name}
                      margin="normal"
                      name="last_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.last_name}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Email"
                      required
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      className={`${classes.formControl} formikInputWrapper ${
                        blurActive ? "blured" : ""
                      }`}
                      error={errors.phone && touched.phone}
                    >
                      <Field
                        name="phone"
                        component={PhoneInput}
                        label="Phone Number"
                        fullWidth
                        variant="outlined"
                        error={Boolean(touched.phone && errors.phone)}
                        helperText={touched.phone && errors.phone}
                        onBlur={(e) => {
                          setBlurActive(false);
                          handleBlur(e);
                        }}
                        onFocus={() => {
                          setBlurActive(true);
                        }}
                        onChange={(e) => {
                          const formattedValue = formatPhoneNumber(
                            e.target.value
                          );
                          setFieldValue("phone", formattedValue);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ width: "100%" }}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                          Department{" "}
                        </InputLabel>
                        <Select
                          labelId="select-status"
                          label="department *"
                          name="department"
                          value={values.department}
                          error={Boolean(
                            touched.department && errors.department
                          )}
                          fullWidth
                          helperText={touched.department && errors.department}
                          placeholder="department"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <MenuItem value="">none</MenuItem>
                          {departments.length > 0 &&
                            departments.map((singleDepartment) => (
                              <MenuItem
                                key={singleDepartment.id}
                                value={singleDepartment.id}
                              >
                                {singleDepartment.department}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ width: "100%" }}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                          Designation
                        </InputLabel>
                        <Select
                          labelId="select-status"
                          label="designation *"
                          name="designation"
                          value={values.designation}
                          error={Boolean(
                            touched.designation && errors.designation
                          )}
                          fullWidth
                          helperText={touched.designation && errors.designation}
                          placeholder="designation"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <MenuItem value="">none</MenuItem>
                          {designations.length > 0 &&
                            designations.map((des) => (
                              <MenuItem key={des.id} value={des.name}>
                                {des.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Hourly Rate"
                      required
                      error={Boolean(touched.hourly_rate && errors.hourly_rate)}
                      fullWidth
                      helperText={touched.hourly_rate && errors.hourly_rate}
                      margin="normal"
                      name="hourly_rate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.hourly_rate}
                      variant="outlined"
                      sx={{ mt: 0 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyIcon sx={{ color: "#555" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Hours Per Day"
                      required
                      error={Boolean(
                        touched.dailyWorkHours && errors.dailyWorkHours
                      )}
                      fullWidth
                      helperText={
                        touched.dailyWorkHours && errors.dailyWorkHours
                      }
                      margin="normal"
                      name="dailyWorkHours"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.dailyWorkHours}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box sx={{ width: "100%" }}>
                      <FormControl
                        className={classes.formControl}
                        error={Boolean(touched.skills && errors.skills)}
                      >
                        <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                          Skills
                        </InputLabel>
                        <Select
                          labelId="select-status"
                          label="skills *"
                          name="skills"
                          value={values.skills}
                          placeholder="skills"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          className="multiselect-dropdown"
                          multiple
                        >
                          {skills.length > 0 &&
                            skills.map((skill) => (
                              <MenuItem
                                className="multiselect-dropdown-menu"
                                key={skill.value}
                                value={skill.value}
                              >
                                {skill.title}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                          {touched.skills && errors.skills}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box sx={{ width: "100%" }}>
                      <FormControl
                        className={classes.formControl}
                        error={Boolean(touched.experience && errors.experience)}
                      >
                        <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                          Experience
                        </InputLabel>
                        <Select
                          labelId="select-status"
                          label="experience *"
                          name="experience"
                          value={values.experience}
                          fullWidth
                          placeholder="experience"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          {experience.length > 0 &&
                            experience.map((exp) => (
                              <MenuItem key={exp.value} value={exp.value}>
                                {exp.title}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                          {touched.experience && errors.experience}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                  <Box sx={{ ml: 1 }}>
                    <Button
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      onClick={() => setShowAddMemberModal(false)}
                      sx={{ backgroundColor: "#C0CBDE" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default CreateNewMemberModal;
