import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import projectStatus from "../../../@const/projectStatus";
import toast from "react-hot-toast";
import { updateProjectStatus } from "../../../api/project/Project";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ProjectStatus = ({ selectedProject }) => {
  const [selectedStatus, setselectedStatus] = useState(null);

  const classes = useStyles();

  const handleChange = (e) => {
    setselectedStatus(e.target.value);
  };

  useEffect(() => {
    setselectedStatus(
      projectStatus.find((status) => status.value === selectedProject.status)
    );
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await updateProjectStatus(
        selectedProject.id,
        selectedStatus.value
      );
      if (res.data.success) {
        toast.success("Project Status Updated Successfully");
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid spacing={2} container sx={{ pb: 3 }}>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#9BD143",
              clipPath: "polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)",
              width: "100%",
              borderRadius: "4px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 700,
                color: "#fff",
                pl: 2,
                py: 2,
              }}
            >
              PROJECT STATUS
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <FormControl className={classes.formControl}>
              {!selectedStatus && (
                <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                  Select Project status
                </InputLabel>
              )}
              <Select
                labelId="select-status"
                name="projectStatus"
                value={selectedStatus}
                variant="outlined"
                fullWidth
                required
                placeholder="Status"
                onChange={handleChange}
                renderValue={() => selectedStatus?.label}
              >
                {projectStatus.map((status) => (
                  <MenuItem selected key={status.value} value={status}>
                    <Box
                      sx={{
                        height: "18px",
                        width: "18px",
                        borderRadius: "18px",
                        backgroundColor: status.color,
                        mr: 1,
                      }}
                    ></Box>
                    <Typography>{status.label}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <Box SX={{ pt: 8 }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!selectedStatus}
          size="large"
          sx={{ px: 7, py: 1.5 }}
        >
          SUBMIT
        </Button>
      </Box>
    </form>
  );
};

export default ProjectStatus;
