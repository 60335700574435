import { Box, Typography } from "@mui/material";
import React from "react";

const GenerateProjectMessage = () => {
  return (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      <Typography
        variant="heading1"
        sx={{
          py: 8,
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "30px",
          width: "60%",
          display: "inline-block",
          color: "primary.blue",
        }}
      >
        Hit Generate Project to create a new project from this template.
      </Typography>
    </Box>
  );
};

export default GenerateProjectMessage;
