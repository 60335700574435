import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Divider, Drawer } from "@mui/material";
import useAuth from "../hooks/useAuth";
import NavSection from "./NavSection";
import Scrollbar from "./Scrollbar";
import superAdminRoutes from "../@const/superAdminRoutes";
import adminRoutes from "../@const/adminRoutes";
import userRoutes from "../@const/userRoutes";

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const { user } = useAuth();
  const location = useLocation();
  // const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const sections =
    user?.role === 1
      ? superAdminRoutes
      : user?.role === 2
      ? adminRoutes
      : userRoutes;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, []);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        "::-webkit-scrollbar ": {
          width: "5px",
        },
      }}
    >
      <Scrollbar options={{ suppressScrollY: true }}>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section, index) => (
            <NavSection
              key={index}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 2,
                },
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  // if (lgUp) {
  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      BackdropProps={{ invisible: true }}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          height: "calc(100% - 100px) !important",
          top: "100px !Important",
          width: openMobile ? 0 : 250,
        },
      }}
      variant="permanent"
    >
      {content}
    </Drawer>
  );
  // }

  // return (
  //   <Drawer
  //     anchor="left"
  //     onClose={onMobileClose}
  //     open={openMobile}
  //     PaperProps={{
  //       sx: {
  //         backgroundColor: 'background.paper',
  //         width: 280
  //       }
  //     }}
  //     variant="temporary"
  //   >
  //     {content}
  //   </Drawer>
  // );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
