import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import PlusIcon from "../../icons/Plus";
import ShareIcon from "../../icons/Share";
import ViewAllCredentialsListTable from "../../components/dashboard/admin/ViewAllCredentialsListTable";
import CreateNewMemberModal from "../../components/dashboard/admin/CreateNewMemberModal";
import useAuth from "../../hooks/useAuth";
import useCommons from "../../hooks/useCommons";
import toast from "react-hot-toast";
import ChangeCompanyModal from "../../components/dashboard/admin/ChangeCompanyModal";

const ViewAllCredentials = () => {
  const { settings } = useSettings();
  const [memberModalState, setMemberModalState] = useState(false);
  const [changeCompanyModalState, setChangeCompanyModalState] = useState(false);

  const { getAllUsers, allUsers, acceptUserRequest, user } = useAuth();
  useEffect(() => {
    getAllUsers();
  }, []);

  const handleCreateNewMemberModalOpen = () => {
    setMemberModalState(true);
  };

  const handleCreateNewMemberModalClose = () => {
    setMemberModalState(false);
  };

  const handleAllUser = async (pageNum, roleFilter, userNameSearch) => {
    try {
      await getAllUsers(pageNum, roleFilter, userNameSearch);
    } catch (error) {
      console.error("Error fetching users:", error);
      toast.error("Failed to fetch users");
    }
  };

  const handleAdminRequest = async (uid, userState) => {
    const res = await acceptUserRequest(uid, userState);
    return res;
  };

  const { allPeopleCount } = useCommons();

  const copyInviteLink = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_FRONTEND_URL}/authentication/register?cid=${user?.company_code}`
    );
    toast.success("Invite link copied");
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: View All Credentials |</title>
      </Helmet>

      {memberModalState && (
        <CreateNewMemberModal
          handleCreateNewMemberModalClose={handleCreateNewMemberModalClose}
          memberModalState={memberModalState}
          handleAllUser={handleAllUser}
        />
      )}

      {changeCompanyModalState && (
        <ChangeCompanyModal
          setChangeCompanyModalState={setChangeCompanyModalState}
          changeCompanyModalState={changeCompanyModalState}
        />
      )}

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Admin Settings
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                User Access Level Settings
              </Typography>
              <Box
                sx={{
                  mt: 0.5,
                }}
              >
                <Typography
                  color="textSecondary"
                  component="span"
                  variant="dashboardInfo"
                >
                  Total Users:
                </Typography>
                <Typography
                  component="span"
                  variant="dashboardInfo"
                  sx={{
                    color: "#00000E",
                    pl: 1,
                  }}
                >
                  {allPeopleCount}
                </Typography>
                {user?.role !== 1 && (
                  <>
                    <Typography
                      color="textPrimary"
                      component="span"
                      variant="dashboardInfo"
                      sx={{
                        pl: 1,
                      }}
                    >
                      | Company Name
                    </Typography>
                    <Typography
                      component="span"
                      variant="dashboardInfo"
                      sx={{
                        color: "#00000E",
                        pl: 1,
                      }}
                    >
                      {user?.company_name}
                    </Typography>
                    <Typography
                      color="textPrimary"
                      component="span"
                      variant="dashboardInfo"
                      sx={{
                        pl: 1,
                      }}
                    >
                      | Company Code
                    </Typography>
                    <Typography
                      component="span"
                      variant="dashboardInfo"
                      sx={{
                        color: "#00000E",
                        pl: 1,
                        cursor: "pointer",
                      }}
                      onClick={copyInviteLink}
                    >
                      {user?.company_code}
                    </Typography>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                {user?.role === 2 && (
                  <Button
                    startIcon={<ShareIcon fontSize="small" />}
                    sx={{
                      m: 1,
                      backgroundColor: "#092bb1",
                      ":hover": {
                        backgroundColor: "#071a62",
                      },
                    }}
                    variant="contained"
                    onClick={copyInviteLink}
                  >
                    INVITE
                  </Button>
                )}
                {user?.role === 1 && (
                  <Button
                    variant="outlined"
                    onClick={() => setChangeCompanyModalState(true)}
                  >
                    Change Company
                  </Button>
                )}
                {(user?.role === 1 || user?.role === 2) && (
                  <Button
                    color="primary"
                    startIcon={<PlusIcon fontSize="small" />}
                    sx={{ m: 1 }}
                    variant="contained"
                    onClick={handleCreateNewMemberModalOpen}
                  >
                    CREATE NEW USER
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <ViewAllCredentialsListTable
              allUsers={allUsers}
              handleAllUser={handleAllUser}
              handleAdminRequest={handleAdminRequest}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ViewAllCredentials;
