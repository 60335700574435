import React, { useEffect, useState, useRef } from "react";
import {
  Dialog,
  Box,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import ExportTable from "./ExportTable";
import html2canvas from "html2canvas";
import numberFormatter from "../../../../utils/numberFormatter";
import moment from "moment";

const formatNumber = (num) => {
  if (isNaN(num)) {
    return 0;
  }
  if (num < 0) {
    return `(${Math.floor(num) * -1})`;
  }
  return Math.floor(num);
};

const CompleteBudgetDetailedView = ({
  showModal,
  setShowModal,
  setData,
  allBudget,
  monthWiseBudgets,
  showWithoutModal,
}) => {
  const tableRef = useRef(null);
  const [structuredOutput, setStructuredOutput] = useState([]);
  const [total, setTotal] = useState({
    totalBudget: 0,
    totalActual: 0,
    totalVariance: 0,
    percentage: 0,
  });
  //
  useEffect(() => {
    if (monthWiseBudgets.length) {
      const finalData = [];
      const totalBudget = monthWiseBudgets.reduce(
        (acc, item) => acc + item.budget,
        0
      );
      const totalActual = monthWiseBudgets.reduce(
        (acc, item) => acc + item.actual,
        0
      );
      const totalVariance = totalBudget - totalActual;
      const percentage =
        totalBudget > 0 ? (totalActual / totalBudget) * 100 : 0;
      setTotal({
        totalBudget,
        totalActual,
        totalVariance,
        percentage,
      });
      monthWiseBudgets.forEach((item) => {
        const variance = calculateVariance(item.budget, item.actual);
        const percentage =
          item.budget > 0 ? (item.actual / item.budget) * 100 : 0;
        const childrenWithSameDate = allBudget.filter(
          (child) => child.date === item.date
        );

        const children = childrenWithSameDate.map((child) => {
          const variance = calculateVariance(child.budget, child.actual);
          const percentage =
            child.budget > 0 ? (child.actual / child.budget) * 100 : 0;
          return {
            ...child,
            variance,
            percentage,
          };
        });
        finalData.push({
          ...item,
          variance,
          percentage,
          children,
        });
      });
      setStructuredOutput([...finalData]);
    }
  }, [monthWiseBudgets, allBudget]);

  const handleClose = () => {
    setShowModal(false);
    setData(null);
  };

  const calculateVariance = (budget, actual) => {
    return budget - actual;
  };

  const takeScreenShot = () => {
    html2canvas(tableRef.current).then((canvas) => {
      let createCanvas = document.createElement("canvas");
      createCanvas.width = canvas.width;
      createCanvas.height = canvas.height;
      let ctx = createCanvas.getContext("2d");
      ctx.drawImage(canvas, 0, 0);
      let img = createCanvas.toDataURL("image/png");
      let a = document.createElement("a");
      a.href = img;
      const date = moment().format("DD-MM-YYYY");
      a.download = `Budget Report Snapshot ${date}.png`;
      a.click();
    });
  };

  return (
    <>
      {!showWithoutModal ? (
        <Dialog
          open={showModal}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"md"}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            {structuredOutput.length && (
              <ExportTable
                total={total}
                takeScreenShot={takeScreenShot}
                data={structuredOutput}
              />
            )}
          </DialogTitle>
          <DialogContent
            sx={{
              pt: "0 !important",
            }}
            dividers={true}
          >
            <DetailedBudgetTable
              structuredOutput={structuredOutput}
              total={total}
              tableRef={tableRef}
            />
          </DialogContent>
          <DialogActions>
            <Box sx={{ pt: 2, pb: 1 }}>
              <Button variant="contained" color="primary" onClick={handleClose}>
                Close
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      ) : (
        <Box>
          <DetailedBudgetTable
            structuredOutput={structuredOutput}
            total={total}
            tableRef={tableRef}
            showWithoutModal={showWithoutModal}
          />
        </Box>
      )}
    </>
  );
};

export const DetailedBudgetTable = ({
  structuredOutput,
  total,
  tableRef,
  showWithoutModal,
}) => {
  return (
    <Table
      hover
      exportExcel={true}
      exportButton={true}
      sx={{
        border: "1px solid #555",
      }}
      id="table-to-xls"
      ref={tableRef}
      stickyHeader
    >
      <TableHead
        sx={{
          th: {
            padding: "8px !important",
            fontSize: "14px !important",
            backgroundColor: "#f8f8f8 !important",
          },
        }}
      >
        <TableCell>Date</TableCell>
        <TableCell>Budget ($)</TableCell>
        <TableCell>Actual ($)</TableCell>
        <TableCell>Variance ($)</TableCell>
        <TableCell>Percentage (%)</TableCell>
        <TableCell
          sx={{
            width: "30%",
          }}
          align="center"
        >
          Notes
        </TableCell>
      </TableHead>
      <TableBody>
        <TableRow
          sx={{
            td: {
              padding: "8px !important",
              backgroundColor: "#bbd6ff !important",
              fontWeight: "bold !important",
            },
          }}
        >
          <TableCell>Summary</TableCell>
          <TableCell>{numberFormatter(total.totalBudget)}</TableCell>
          <TableCell>{numberFormatter(total.totalActual)}</TableCell>
          <TableCell
            sx={{
              backgroundColor:
                total.totalVariance < 0
                  ? "#dc7c73 !important"
                  : "#5bc198 !important",
            }}
          >
            {numberFormatter(total.totalVariance)}
          </TableCell>
          <TableCell
            sx={{
              backgroundColor:
                total.totalVariance < 0
                  ? "#dc7c73 !important"
                  : "#5bc198 !important",
            }}
          >
            {formatNumber(total.percentage)} %
          </TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>
        {structuredOutput.length &&
          structuredOutput.map((item) => (
            <React.Fragment key={item.id}>
              <TableRow
                sx={{
                  fontWeight: "bold !important",
                  td: {
                    backgroundColor: "#fff",
                    color: "#222",
                    fontWeight: "bold !important",
                    padding: "6px !important",
                  },
                }}
              >
                <TableCell>{item.date}</TableCell>
                <TableCell>{numberFormatter(item.budget)}</TableCell>
                <TableCell>{numberFormatter(item.actual)}</TableCell>
                <TableCell
                  sx={{
                    backgroundColor:
                      item.variance < 0
                        ? "#f5b7b1 !important"
                        : "#d4edda !important",
                  }}
                >
                  {numberFormatter(item.variance)}
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor:
                      item.variance < 0
                        ? "#f5b7b1 !important"
                        : "#d4edda !important",
                  }}
                >
                  {formatNumber(item.percentage)} %
                </TableCell>
                <TableCell align="right">{item.notes}</TableCell>
              </TableRow>
              {!showWithoutModal &&
                item.children.map((child) => (
                  <TableRow
                    sx={{
                      color: "#000",
                      td: {
                        padding: "6px",
                      },
                    }}
                    key={child.id}
                  >
                    <TableCell
                      sx={{
                        paddingLeft: "34px !important",
                        fontWeight: "600 !important",
                      }}
                    >
                      {child.summery}
                    </TableCell>
                    <TableCell>{numberFormatter(child.budget)}</TableCell>
                    <TableCell>{numberFormatter(child.actual)}</TableCell>
                    <TableCell
                      sx={{
                        backgroundColor:
                          child.variance < 0 ? "#f5b7b1" : "#d4edda",
                      }}
                    >
                      {numberFormatter(child.variance)}
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor:
                          child.variance < 0 ? "#f5b7b1" : "#d4edda",
                      }}
                    >
                      {formatNumber(child.percentage)} %
                    </TableCell>
                    <TableCell align="right">{child.notes}</TableCell>
                  </TableRow>
                ))}
            </React.Fragment>
          ))}
      </TableBody>
    </Table>
  );
};

export default CompleteBudgetDetailedView;
