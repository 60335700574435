import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import useCommon from "../hooks/useCommons";

const AuthGuard = ({ children }) => {
  const auth = useAuth();
  const { setInitialRedirectURL } = useCommon();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.isAuthenticated) {
      if (location.pathname !== "/") {
        setInitialRedirectURL(location.pathname);
      }
      navigate("/authentication/login");
    }
  }, [auth.isAuthenticated, location, navigate, setInitialRedirectURL]);

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
