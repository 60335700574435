import { Autocomplete, Box, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { getUserList } from "../slices/user";
import { useDispatch, useSelector } from "../store";

const SelectUserDropdown = ({ setSelectedUser, defaultUser }) => {
  const dispatch = useDispatch();
  const { userList } = useSelector((state) => state.userList);

  useEffect(() => {
    dispatch(getUserList());
  }, []);

  return (
    <Box>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        sx={{ minWidth: 300 }}
        fullWidth
        blurOnSelect={true}
        options={userList}
        defaultValue={defaultUser ? defaultUser : null}
        onChange={(event, value) => {
          setSelectedUser(value);
        }}
        getOptionSelected={(option, value) => {
          return option.id === value.id;
        }}
        getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              key={`${option.first_name} ${option.last_name} ${option.id}`}
            >
              {`${option.first_name} ${option.last_name}`}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Select User" name="cPerson" />
        )}
      />
    </Box>
  );
};

export default SelectUserDropdown;
