import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
import {
  dropdownPortfolios,
  dropdownProjects,
  getBudgetData,
} from "../../api/budget/Budget";
import AllProjectBudget from "../../components/dashboard/budget/AllProjectBudget";
import useAuth from "../../hooks/useAuth";
import useLoader from "../../hooks/useLoader";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import currencyFormatter from "../../utils/currencyFormatter";
// import VideoFloatButton from "../../components/VideoFloatButton";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Budget = () => {
  const { settings } = useSettings();
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const [seledtedPortfolio, setSeledtedPortfolio] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [budget, setBudget] = useState([]);
  const [totalBugetData, setTotalBugetData] = useState(null);

  const [allProjects, setAllProjects] = useState([]);
  const [allPortfolio, setAllPortfolio] = useState([]);

  const { user } = useAuth();
  const { loaderStart, loaderEnd } = useLoader();
  // const { getAllPortfolio, allPortfolio } = usePortfolio();

  useEffect(() => {
    getDropdownPortfolios();
    return () => {
      setSelectedProject("");
    };
  }, []);

  useEffect(() => {
    getProjectBudget();
  }, [currentPage]);

  useEffect(() => {
    getDropdownProjects();
  }, [seledtedPortfolio]);

  //  jd
  const getProjectBudget = async () => {
    try {
      loaderStart();
      const res = await getBudgetData({
        projectId: selectedProject,
        portfolioId: seledtedPortfolio,
        adminId: user.id,
        page: currentPage,
      });
      if (res.data.success) {
        setBudget(res.data.data.budgets);
        setTotalBugetData(res.data.data.budgetData[0]);
      }
      loaderEnd();
    } catch (error) {
      loaderEnd();
    }
  };

  const getDropdownProjects = async () => {
    const res = await dropdownProjects({ portfolioId: seledtedPortfolio });
    if (res?.data?.success) {
      setAllProjects(res.data.data.projectDropdown);
    }
  };

  const getDropdownPortfolios = async () => {
    const res = await dropdownPortfolios();
    if (res?.data?.success) {
      setAllPortfolio(res.data.data.portfolioDropdown);
    }
  };

  const handlePortfolioChange = (e) => {
    setSeledtedPortfolio(e.target.value);
    setSelectedProject("");
  };

  const handleBudget = () => {
    getProjectBudget();
    setCurrentPage(1);
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Budget</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Budget
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                Budget
              </Typography>
            </Grid>
          </Grid>
          <Card sx={{ p: 3, mt: 2 }}>
            {/* <ViewAllPortfoliosListTable
                allPortfolio={allPortfolio}
                handleAllPortfolio={handleAllPortfolio}
              /> */}
            <Typography
              sx={{
                fontSize: "14px",
              }}
              variant="h6"
            >
              BUDGET
            </Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item md={3} sm={12} xs={12}>
                <Box sx={{ width: "100%" }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                      Portfolios
                    </InputLabel>
                    <Select
                      labelId="select-status"
                      label="SELECT PORTFOLIO"
                      name="seledtedPortfolio"
                      value={seledtedPortfolio}
                      fullWidth
                      onChange={handlePortfolioChange}
                    >
                      <MenuItem value="">All Portfolios</MenuItem>
                      {allPortfolio?.length > 0 &&
                        allPortfolio.map((portfolio) => (
                          <MenuItem key={portfolio.id} value={portfolio.id}>
                            {portfolio.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item md={3} sm={12} xs={12}>
                <Box sx={{ width: "100%" }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                      Projects
                    </InputLabel>
                    <Select
                      labelId="select-status"
                      label="SELECT PROJECT"
                      name="selectedProject"
                      value={selectedProject}
                      fullWidth
                      onChange={(e) => {
                        setSelectedProject(e.target.value);
                      }}
                    >
                      <MenuItem value="">All Projects</MenuItem>
                      {allProjects?.length > 0 &&
                        allProjects.map((proj) => (
                          <MenuItem key={proj.id} value={proj.id}>
                            {proj.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item md={3} sm={12} xs={12}>
                <Box sx={{ m: -1 }}>
                  <Button
                    color="primary"
                    sx={{ m: 1, py: 1.7 }}
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={handleBudget}
                  >
                    View Budget
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Typography sx={{ mt: 3 }}>Budget Information</Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                mt: 1,
              }}
            >
              Total Portfolios : {totalBugetData?.no_of_portfolios || 0} | Total
              Projects: {totalBugetData?.no_of_projects || 0}
            </Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item md={3} sm={6} xs={6}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "900",
                    mt: 1,
                    color: "#2D3E56",
                  }}
                >
                  BUDGET
                </Typography>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    mt: 1,
                    color: "#FD4860",
                  }}
                >
                  $
                  {totalBugetData?.total_budget
                    ? currencyFormatter(totalBugetData?.total_budget)
                    : 0}
                </Typography>
              </Grid>
              <Grid item md={3} sm={6} xs={6} sx={{ pl: 1 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "900",
                    mt: 1,
                    color: "#2D3E56",
                  }}
                >
                  ACTUAL
                </Typography>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    mt: 1,
                    color: "#9BD143",
                  }}
                >
                  $
                  {totalBugetData?.total_actual
                    ? currencyFormatter(totalBugetData?.total_actual)
                    : 0}
                </Typography>
              </Grid>
            </Grid>

            <Box sx={{ mt: 3 }}>
              <AllProjectBudget
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                budget={budget}
              />
            </Box>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Budget;
