import React from "react";
import { Box, Button, TextField } from "@mui/material";
import { AddCommentOutlined } from "@mui/icons-material";
import toast from "react-hot-toast";
import { addComment } from "../../../../api/project/Project";
import { Formik } from "formik";
import * as Yup from "yup";
import useMounted from "../../../../hooks/useMounted";

const AddComment = ({ projectId, getComments }) => {
  const mounted = useMounted();
  return (
    <>
      <Box sx={{ mt: 5 }}>
        <Formik
          initialValues={{
            comment: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            comment: Yup.string()
              .max(300, "Maximum 300 characters are allowed")
              .required("Can't be empty"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const res = await addComment(projectId, values.comment);

              if (res.data.success) {
                toast.success("New Comment added");
                getComments();
              }
              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              toast.error("Something went wrong");
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <TextField
                id="outlined-basic"
                rows={3}
                minRows={3}
                multiline
                label="Add Comment"
                fullWidth
                variant="outlined"
                name="comment"
                value={values.comment}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.comment && errors.comment)}
                helperText={touched.comment && errors.comment}
                sx={{ mt: 2 }}
              />
              <Button
                sx={{ mt: 1 }}
                variant="contained"
                disabled={isSubmitting}
                type="submit"
                color="primary"
                startIcon={<AddCommentOutlined />}
              >
                Add Comment
              </Button>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default AddComment;
