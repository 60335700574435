import { Box, Card, CardHeader, Grid } from "@mui/material";
import React from "react";
import AllTasksGanttChartView from "../dashboard/projects/AllTasksGanttChartView";
import MonthWiseBudget from "../dashboard/projects/budget/MonthWiseBudget";
import HealthGraph from "../dashboard/projects/dashboard/HealthGraph";
import PeopleUtilization from "../dashboard/projects/dashboard/PeopleUtilization";
import TaskGraph from "../dashboard/projects/dashboard/TaskGraph";
const ProjectDashboard = ({ projectID, selectedProject, authToken }) => {
  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <SingleProjectDashboard
          title="Health"
          styles={{ overflowY: "auto", height: 460 }}
        >
          <HealthGraph selectedProject={projectID} authToken={authToken} />
        </SingleProjectDashboard>

        <SingleProjectDashboard title="Task" styles={{ px: 1 }}>
          <TaskGraph selectedProject={projectID} authToken={authToken} />
        </SingleProjectDashboard>

        <SingleProjectDashboard
          title="Project gantt chart"
          styles={{ px: 1 }}
          md={12}
          sm={12}
          xs={12}
        >
          <AllTasksGanttChartView
            selectedProject={selectedProject}
            authToken={authToken}
            shared={true}
          />
        </SingleProjectDashboard>
        <SingleProjectDashboard
          title="Project Detailed Budget"
          styles={{
            px: 1,
            maxHeight: "600px",
            overflowY: "auto",
          }}
          md={12}
          sm={12}
          xs={12}
        >
          <MonthWiseBudget
            selectedProject={selectedProject}
            showOnlyDetailedView={true}
            authToken={authToken}
            shared={true}
          />
        </SingleProjectDashboard>
        <SingleProjectDashboard
          title="Member Utilization"
          styles={{
            px: 1,
            maxHeight: "600px",
            overflowY: "auto",
            mt: 2,
          }}
          md={12}
          sm={12}
          xs={12}
        >
          <PeopleUtilization selectedProject={selectedProject} shared={true} />
        </SingleProjectDashboard>
      </Grid>
    </Box>
  );
};

const SingleProjectDashboard = ({
  title,
  styles = {},
  sm,
  xs,
  md,
  children,
}) => {
  return (
    <>
      <Grid item xs={xs || 12} sm={sm || 6} md={md || 6}>
        <Card
          sx={{
            boxShadow: "3px 3px 10px #00000050 !important",
            ...styles,
          }}
        >
          <CardHeader title={title} />
          {children}
        </Card>
      </Grid>
    </>
  );
};

export default ProjectDashboard;
