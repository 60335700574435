import { Box, Grid, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsGantt from "highcharts/modules/gantt";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { getProjectGanttChart } from "../../api/common/Common";

highchartsGantt(Highcharts);

const ProjectGantt = () => {
  const [ganttData, setGanttData] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEnddate] = useState(null);
  const [chartOptions, setChartOptions] = useState({
    series: [{ data: [] }],
  });
  const chartRef = useRef(null);

  useEffect(() => {
    let isMounted = true;

    const generateGanttChart = async () => {
      try {
        const startDate = filterStartDate
          ? moment(filterStartDate).format("YYYY-MM-DD")
          : null;
        const endDate = filterEndDate
          ? moment(filterEndDate).format("YYYY-MM-DD")
          : null;
        const res = await getProjectGanttChart(startDate, endDate);

        if (!isMounted) return;

        if (res?.data?.data?.items.length > 0) {
          const ganttData = res?.data?.data?.items;

          const ganttDataWithUnixDate = ganttData.map((obj) => {
            if (obj.x && obj.x2) {
              const x1date = moment(obj.x, "YYYY-MM-DD")
                .format("YYYY-MM-DD")
                .split("-");
              const nextEndDay = moment(obj.x2, "YYYY-MM-DD")
                .add(1, "days")
                .format("YYYY-MM-DD");
              const x2date = nextEndDay.split("-");

              const x = Date.UTC(x1date[0], x1date[1], x1date[2]);
              const x2 = Date.UTC(x2date[0], x2date[1], x2date[2]);
              return { ...obj, x: x, x2: x2 };
            }
            return obj;
          });

          if (isMounted) {
            setChartOptions({
              chart: {
                type: "xrange",
              },
              credits: {
                enabled: false,
              },
              xAxis: {
                type: "datetime",
                dateTimeLabelFormats: {
                  day: "%e-%b",
                  week: "%e %b %Y",
                  month: "%b",
                },
                currentDateIndicator: true,
              },
              rangeSelector: {
                enabled: true,
                selected: 1,
                zIndex: 100,
                position: { y: 0 },
                inputPosition: { align: "left" },
                buttonPosition: { align: "right" },
                inputStyle: {
                  fontSize: "14px",
                  fontWeight: "800",
                  color: "#092bb1",
                  fill: "#092bb1",
                },
                selected: 3,
                buttons: [
                  { type: "month", count: 12, text: "12m" },
                  { type: "month", count: 3, text: "3m" },
                  { type: "day", count: 21, text: "3w" },
                  { type: "all", text: "All" },
                ],
              },
              yAxis: {
                labels: {
                  formatter: function () {
                    const projectId = ganttDataWithUnixDate.find(
                      (item) => item.name === this.value
                    )?.id;
                    if (projectId) {
                      return `<a href="/dashboard/projects/view-and-edit-project/${projectId}?p=dashboard">${this.value}</a>`;
                    }
                    return this.value;
                  },
                },
              },
              scrollbar: { enabled: true, liveRedraw: false },
              plotOptions: {
                series: {
                  dataLabels: {
                    enabled: true,
                  },
                  connectors: {
                    lineWidth: 1,
                    type: "fastAvoid",
                    startMarker: {
                      enabled: true,
                      symbol: "arrow-filled",
                      align: "left",
                    },
                  },
                },
              },
              tooltip: {
                formatter: function () {
                  const dt1 = new Date(this.x);
                  const dt2 = new Date(this.x2);
                  dt2.setDate(dt2.getDate() - 1);

                  return `<b>Name: ${
                    this.key
                  }</b><br/>Start Date: <b>${dt1.getDate()}-${
                    dt1.getMonth() === 0 ? 12 : dt1.getMonth()
                  }-${dt1.getFullYear()}</b><br/>End Date: <b>${dt2.getDate()}-${
                    dt2.getMonth() === 0 ? 12 : dt2.getMonth()
                  }-${dt2.getFullYear()}</b><br/>`;
                },
              },
              series: [{ data: ganttDataWithUnixDate }],
            });
            setGanttData(ganttDataWithUnixDate);
          }
        }
      } catch (error) {
        console.error("Error generating Gantt chart:", error);
      }
    };

    generateGanttChart();

    return () => {
      isMounted = false;
      // Cleanup Highcharts
      if (chartRef.current && chartRef.current.chart) {
        chartRef.current.chart.destroy();
      }
    };
  }, [filterStartDate, filterEndDate]);

  return (
    <Box>
      <Grid sx={{ justifyContent: "flex-end" }} container spacing={3}>
        {/* <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: "flex",
              ".MuiFormHelperText-root": {
                display: "none",
              },
            }}
          >
            <DatePicker
              label="Start Date"
              autoOk={true}
              className="pt-dtpicker"
              fullWidth
              onChange={(date) => setFilterStartDate(date)}
              helperText={null}
              value={filterStartDate}
              InputProps={{
                disableUnderline: true,
              }}
              name="filterStartDate"
              renderInput={(inputProps) => (
                <TextField
                  className="pt-datepicker-ip"
                  fullWidth
                  helperText={null}
                  variant="outlined"
                  {...inputProps}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              display: "flex",
              ".MuiFormHelperText-root": {
                display: "none",
              },
            }}
          >
            <DatePicker
              label="End Date"
              autoOk={true}
              className="pt-dtpicker"
              fullWidth
              onChange={(date) => setFilterEnddate(date)}
              helperText={null}
              value={filterEndDate}
              InputProps={{
                disableUnderline: true,
              }}
              name="filterEndDate"
              minDate={filterStartDate}
              renderInput={(inputProps) => (
                <TextField
                  fullWidth
                  helperText={null}
                  variant="outlined"
                  {...inputProps}
                />
              )}
            />
          </Box>
        </Grid> */}
        <Grid item xs={12} sx={{ py: 2 }}>
          {ganttData?.length > 0 ? (
            <HighchartsReact
              constructorType="ganttChart"
              highcharts={Highcharts}
              options={chartOptions}
              ref={chartRef}
            />
          ) : (
            <Typography
              align="center"
              sx={{ p: 4 }}
              variant="h6"
              color="secondary"
            >
              No projects available
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProjectGantt;
