import React from "react";
import {
  FormHelperText,
  TextField,
  Box,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import useMounted from "../../../hooks/useMounted";
import toast from "react-hot-toast";
import {
  addProjectMember,
  editProjectMember,
} from "../../../api/project/Project";
import useProject from "../../../hooks/useProject";

const EditMemberModal = (props) => {
  const { showAddMemberModal, setShowAddMemberModal, selectedProject, member } =
    props;
  const { getAllProjectMembers } = useProject();

  const mounted = useMounted();
  return (
    <>
      <Dialog
        open={showAddMemberModal}
        onClose={() => setShowAddMemberModal(false)}
        fullWidth
        maxWidth="sm"
      >
        <Formik
          initialValues={{
            projectHours: member.time_on_proj || "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            projectHours: Yup.number()
              .max(255)
              .required("Project Hours is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const memberData = {
                proj_id: selectedProject.id,
                proj_time: values.projectHours,
                member_id: member.member_id || null,
              };

              const res = await editProjectMember(memberData);

              if (res.data.success) {
                toast.success("Member work hour edited successfully");
                getAllProjectMembers(selectedProject.id);
                setShowAddMemberModal(false);
              }
              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              toast.error("Something went wrong");
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit} {...props}>
              {/* <DialogTitle>Edit Project Member</DialogTitle> */}
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="Daily project hours"
                      required
                      error={Boolean(
                        touched.projectHours && errors.projectHours
                      )}
                      fullWidth
                      helperText={touched.projectHours && errors.projectHours}
                      margin="normal"
                      name="projectHours"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.projectHours}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      onClick={() => setShowAddMemberModal(false)}
                      sx={{ backgroundColor: "#C0CBDE", ml: 1 }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default EditMemberModal;
