import createSvgIcon from "@mui/material/utils/createSvgIcon";

const Time = createSvgIcon(
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C7.038 22 3 17.963 3 13C3 8.037 7.038 4 12 4C16.962 4 21 8.037 21 13C21 17.963 16.962 22 12 22ZM12 6C8.14 6 5 9.14 5 13C5 16.86 8.14 20 12 20C15.86 20 19 16.86 19 13C19 9.14 15.86 6 12 6Z"
      fill="#C0CBDE"
    />
    <path
      d="M21.1409 8.098C21.6709 7.557 21.9999 6.817 21.9999 6C21.9999 4.343 20.6569 3 18.9999 3C18.1829 3 17.4419 3.329 16.9009 3.86L21.1409 8.098Z"
      fill="#C0CBDE"
    />
    <path
      d="M2.859 8.098C2.329 7.557 2 6.817 2 6C2 4.343 3.343 3 5 3C5.817 3 6.558 3.329 7.099 3.86L2.859 8.098Z"
      fill="#C0CBDE"
    />
    <path
      d="M15 16C14.6095 16.3905 13.9765 16.3905 13.586 16L11.293 13.707C11.105 13.519 11 13.265 11 13V9C11 8.44772 11.4477 8 12 8C12.5523 8 13 8.44772 13 9V12.586L15 14.586C15.3905 14.9765 15.3905 15.6095 15 16Z"
      fill="#C0CBDE"
    />
  </svg>,
  "Time"
);

export default Time;
