import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { dropdownProjects } from "../../../api/budget/Budget";
import { getDropdownTasks } from "../../../api/project/Project";
import { assignTask } from "../../../api/scheduling/Scheduling";
import useAuth from "../../../hooks/useAuth";
import useMounted from "../../../hooks/useMounted";
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AssignTask = ({
  showAssignTaskModal,
  setShowAssignTaskModal,
  userId,
  onSuccess,
}) => {
  const [allProjects, setAllProjects] = useState([]);
  const [allProjectTasks, setAllProjectTasks] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  const { user } = useAuth();
  const mounted = useMounted();
  const classes = useStyles();
  const getDropdownProjects = async () => {
    const res = await dropdownProjects({
      adminId: user.id,
      portfolioId: "",
    });
    if (res.data.success) {
      setAllProjects(res.data.data.projectDropdown);
    }
  };
  useEffect(() => {
    getDropdownProjects();
  }, []);

  useEffect(() => {
    if (selectedProject) {
      dropdownTasks();
    }
  }, [selectedProject]);

  const dropdownTasks = async () => {
    const res = await getDropdownTasks({ projectId: selectedProject });
    if (res.data.success) {
      setAllProjectTasks(res.data.data.tasks.data);
    }
  };

  return (
    <>
      <Dialog
        open={showAssignTaskModal}
        onClose={() => setShowAssignTaskModal(false)}
        fullWidth={true}
        maxWidth={"md"}
      >
        <Formik
          initialValues={{
            project: "",
            task: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            project: Yup.string().max(255).required("Project is required"),
            task: Yup.string().max(255).required("Task is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const res = await assignTask({
                userId: userId,
                taskId: values.task,
              });

              if (res.data.success) {
                toast.success("Task assigned successfully");
                setShowAssignTaskModal(false);
                if (onSuccess) {
                  onSuccess();
                }
              }

              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              toast.error("Something went wrong");
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>Assign Task</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Box sx={{ width: "100%" }}>
                      <FormControl className={classes.formControl} required>
                        <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                          Select Project
                        </InputLabel>
                        <Select
                          labelId="select-status"
                          label="SELECT PROJECT"
                          name="project"
                          value={values.project}
                          fullWidth
                          onChange={(e) => {
                            handleChange(e);
                            setSelectedProject(e.target.value);
                          }}
                        >
                          <MenuItem value="">none</MenuItem>
                          {allProjects?.length > 0 &&
                            allProjects.map((proj) => (
                              <MenuItem key={proj.id} value={proj.id}>
                                {proj.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Box sx={{ width: "100%" }}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                          Task{" "}
                        </InputLabel>
                        <Select
                          labelId="select-status"
                          label="task *"
                          name="task"
                          value={values.task}
                          error={Boolean(touched.task && errors.task)}
                          fullWidth
                          helperText={touched.task && errors.task}
                          placeholder="task"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <MenuItem value="">none</MenuItem>
                          {allProjectTasks?.length > 0 &&
                            allProjectTasks.map((task) => (
                              <MenuItem key={task.id} value={task.id}>
                                {task.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                  <Box sx={{ ml: 1 }}>
                    <Button
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      onClick={() => setShowAssignTaskModal(false)}
                      sx={{ backgroundColor: "#C0CBDE" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default AssignTask;
