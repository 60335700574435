import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
} from "@mui/material";
import { Share } from "@mui/icons-material";
import { createSharedCalendar } from "../../../api/calender/Calender";
import toast from "react-hot-toast";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

const ShareCalendar = ({ userId }) => {
  const [open, setOpen] = useState(false);
  const [expiryDate, setExpiryDate] = useState(moment(new Date()));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    const res = await createSharedCalendar({
      user_id: userId,
      expiry_date: moment(expiryDate).format("YYYY-MM-DD"),
    });
    const { token } = res.data;
    const URL = `${window.location.origin}/shared-calendar/${userId}?t=${token}`;
    // Copy the URL to the clipboard
    navigator.clipboard.writeText(URL);

    toast(
      <span
        style={{ wordWrap: "break-word", whiteSpace: "normal", color: "green" }}
      >
        URL copied to clipboard:
        <br />
        <span
          style={{
            color: "#2196f3",
          }}
        >
          {URL}
        </span>
      </span>,
      {
        style: {
          maxWidth: "400px", // Limit the width to break lines naturally
          whiteSpace: "normal", // Allow wrapping within the container
          padding: "10px 15px",
          overflowX: "scroll",
        },
        duration: 5000,
      }
    );
    handleClose();
  };

  const handleDateChange = (newValue) => {
    if (newValue && moment.isMoment(newValue)) {
      setExpiryDate(newValue);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        endIcon={<Share />}
        sx={{
          padding: "15px 26px",
          mr: 1,
        }}
      >
        Share
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>Share Calendar</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <DatePicker
                label="Expiry Date"
                value={expiryDate}
                onChange={handleDateChange}
                minDate={moment(new Date())}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: "outlined",
                    error: !expiryDate,
                    helperText: !expiryDate ? "Expiry date is required" : "",
                  },
                }}
              />
            </FormControl>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={!expiryDate}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ShareCalendar;
