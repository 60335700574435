import PropTypes from "prop-types";
import { matchPath } from "react-router-dom";
import { List, ListSubheader } from "@mui/material";
import NavItem from "./NavItem";
import useAuth from "../hooks/useAuth";
const renderNavItems = ({ depth = 0, items, pathname, userRoleId }) => {
  return (
    <List disablePadding>
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            pathname,
            depth,
            userRoleId,
          }),
        []
      )}
    </List>
  );
};

const reduceChildRoutes = ({ acc, pathname, item, depth, userRoleId }) => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: true,
        },
        pathname
      )
    : false;

  const partialMatch = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: false,
        },
        pathname
      )
    : false;
  if (item.children && item?.children?.length !== 0) {
    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname,
          userRoleId,
        })}
      </NavItem>
    );
  } else if (item?.children?.length === 0) {
    acc.push(
      <NavItem
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
        isLink={true}
      />
    );
  } else if (item.title !== "Pending Requests") {
    acc.push(
      <NavItem
        active={exactMatch}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
      />
    );
  } else if (
    item.title === "Pending Requests" &&
    (userRoleId === 1 || userRoleId === 2)
  ) {
    acc.push(
      <NavItem
        active={exactMatch}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
      />
    );
  }

  return acc;
};

const NavSection = (props) => {
  const { items, pathname, title, ...other } = props;
  const { user } = useAuth();
  const userRoleId = user?.role;
  return (
    <List
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: "text.primary",
            fontSize: "14px",
            lineHeight: 2.5,
            fontWeight: 700,
          }}
        >
          {title}
        </ListSubheader>
      }
      {...other}
    >
      {renderNavItems({
        items,
        pathname,
        userRoleId,
      })}
    </List>
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string,
};

export default NavSection;
