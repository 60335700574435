import { Box, Popover, Typography } from "@mui/material";
import React from "react";
import toast from "react-hot-toast";
import projectStatus from "../../../@const/projectStatus";
import { updateProjectStatus } from "../../../api/project/Project";

const ProjectStatusGraphics = ({ selectedProject, getProject }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const updateStatus = async (status) => {
    try {
      const res = await updateProjectStatus(selectedProject.id, status);
      if (res.data.success) {
        handleClose();
        getProject();
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    }
  };
  return (
    <>
      <Box
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          height: "12px",
          width: "12px",
          borderRadius: "12px",
          backgroundColor:
            selectedProject?.status === 3
              ? "#FF0000"
              : selectedProject?.status === 2
              ? "green"
              : "#FFBF00",
          mr: 0.5,
          mt: 0.13,
          cursor: "pointer",
        }}
      ></Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {projectStatus.map((status) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              p: 1,
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#ddd",
              },
            }}
            onClick={() => updateStatus(status.value)}
          >
            <Box
              sx={{
                height: "18px",
                width: "18px",
                borderRadius: "18px",
                backgroundColor: status.color,
                mr: 1,
              }}
            ></Box>
            <Typography>{status.label}</Typography>
          </Box>
        ))}
      </Popover>
    </>
  );
};

export default ProjectStatusGraphics;
