import { Dialog } from "@mui/material";
import BookRoomIntakeForm from "./BookRoomIntakeForm";

const CreateNewRoom = ({ showModal, setShowModal }) => {
  return (
    <>
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        minWidth={"md"}
        fullWidth={true}
      >
        <BookRoomIntakeForm />
      </Dialog>
    </>
  );
};

export default CreateNewRoom;
