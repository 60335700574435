import React, { useState } from "react";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { DeleteForeverOutlined, EditOutlined } from "@mui/icons-material";
import ShowEditMonthlyBudgetDetailsModal from "./ShowEditMonthlyBudgetDetailsModal";
import { deleteMonthlyBudgetData } from "../../../../api/budget/Budget";
import numberFormatter from "../../../../utils/numberFormatter";
import DeleteModal from "../../../DeleteModal";
const BudgetDetailedRow = ({
  item,
  calculateVariance,
  data,
  getAllMonthsDetailedBudget,
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [open, setOpen] = useState(false);
  const deleteRecord = async () => {
    const temp = item;
    const res = await deleteMonthlyBudgetData(item.id);
    if (res.data.success) {
      getAllMonthsDetailedBudget(
        temp.id,
        temp.budget,
        temp.actual,
        item.date,
        true,
        false
      );
    }
  };
  return (
    <>
      <DeleteModal
        open={open}
        setOpen={setOpen}
        onYesClick={deleteRecord}
        warningMessage="Are you sure you want to delete this record?"
      />
      {showEditModal && (
        <ShowEditMonthlyBudgetDetailsModal
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          budgetDetails={item}
          onSuccess={getAllMonthsDetailedBudget}
        />
      )}
      <TableRow key={item.id}>
        <TableCell>{item.summery}</TableCell>
        <TableCell>{numberFormatter(item.budget)}</TableCell>
        <TableCell>{numberFormatter(item.actual)}</TableCell>
        <TableCell
          sx={{
            backgroundColor:
              calculateVariance(item.budget, item.actual) < 0
                ? "#ebb0b0"
                : "#8dcebf",
          }}
        >
          {numberFormatter(calculateVariance(item.budget, item.actual))}
        </TableCell>
        <TableCell
          sx={{
            backgroundColor:
              calculateVariance(item.budget, item.actual) < 0
                ? "#ebb0b0"
                : "#8dcebf",
          }}
        >
          {Math.floor(item.budget > 0 ? (item.actual / item.budget) * 100 : 0)}{" "}
          %
        </TableCell>
        <TableCell align="right">
          <IconButton
            sx={{
              padding: 0,
            }}
            onClick={() => {
              setShowEditModal(true);
            }}
          >
            <EditOutlined />
          </IconButton>
          <IconButton
            sx={{
              padding: 0,
              ml: 1,
            }}
            onClick={() => {
              setOpen(true);
            }}
          >
            <DeleteForeverOutlined />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default BudgetDetailedRow;
