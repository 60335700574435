import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Autocomplete,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { Delete, Save } from "@mui/icons-material";
import useAuth from "../../../../hooks/useAuth";
import { deleteSavedReport } from "../../../../api/reports/CustomReport";
import toast from "react-hot-toast";
import WarningModal from "./WarningModal";
const SearchFilters = ({
  isPriviewModeActive,
  selectedSavedFilter,
  setSaveFilterModalOpen,
  savedFilters,
  updateFilters,
  setIsPriviewModeActive,
  getTaskSavedReports,
}) => {
  const autocompleteRef = useRef(null);
  const { user } = useAuth();
  const [open, setOpen] = useState(false);

  const deleteReport = async () => {
    const res = await deleteSavedReport(selectedSavedFilter.id);
    if (res.data.success) {
      toast.success(res.data.data.message);
      setIsPriviewModeActive(false);
      const elem = autocompleteRef?.current?.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      elem.click();
      document.body.click();
      getTaskSavedReports();
      setOpen(false);
    } else {
      toast.error("Something went wrong, can't delete this filter");
    }
  };

  const openDeleteModal = () => {
    setOpen(true);
  };

  return (
    <>
      {open && (
        <WarningModal
          open={open}
          setOpen={setOpen}
          actionFn={deleteReport}
          text="Are you sure, you want to delete this filter."
        />
      )}
      <Grid container spacing={2} sx={{ py: 3, px: 1 }}>
        <Grid item xs={12} sm={6}>
          <div style={{ marginBottom: "5px" }}>
            {isPriviewModeActive && (
              <>
                <Typography variant="h4">
                  {selectedSavedFilter?.title}
                </Typography>
                <Typography variant="body2" sx={{ pt: 1 }}>
                  {selectedSavedFilter?.description}
                </Typography>
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={3}>
          <Box sx={{ width: "100%" }}>
            <Autocomplete
              disablePortal
              ref={autocompleteRef}
              id="combo-box-demo"
              blurOnSelect={true}
              options={savedFilters.sort((a, b) => a.level - b.level)}
              onChange={updateFilters}
              value={selectedSavedFilter || null}
              groupBy={(option) =>
                option.level === 1
                  ? "Global"
                  : option.level === 2
                  ? "Company-Wide"
                  : "Personal"
              }
              group
              getOptionLabel={(option) => option.title}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={`${option.id}`}>
                    {`${option.title}`}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Saved Filters"
                  name="savedFilters"
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={2} sx={{ display: "flex" }}>
          <Tooltip
            title={user.custom_report === 1 ? "Save" : "$ Premium Feature"}
          >
            <Box>
              <Button
                onClick={() => {
                  setSaveFilterModalOpen(true);
                }}
                color="primary"
                variant="contained"
                disabled={user.custom_report === 0}
                size="large"
                sx={{
                  mr: 1,
                  py: 1.8,
                  backgroundColor: "#ddd",
                  color: "#333",
                  ":hover": {
                    backgroundColor: "#F7B31E",
                    color: "#000",
                  },
                }}
              >
                <Save />
              </Button>
            </Box>
          </Tooltip>
          {isPriviewModeActive && selectedSavedFilter?.level === 3 && (
            <Tooltip title="Delete">
              <Box>
                <Button
                  color="secondary"
                  onClick={openDeleteModal}
                  variant="outlined"
                  size="large"
                  sx={{
                    mr: 1,
                    py: 1.8,
                  }}
                >
                  <Delete />
                </Button>
              </Box>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SearchFilters;
