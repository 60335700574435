import React from "react";
import { Grid, TextField } from "@mui/material";

const StageSettingContent = ({ order, handleChange, stage }) => {
  const handleTextChange = (e) => {
    e.preventDefault();
    handleChange(order, e.target.value);
  };
  return (
    <Grid item xs={12} sm={4}>
      <TextField
        label={`Step ${order}`}
        required
        fullWidth
        name={`stage${order}`}
        onChange={handleTextChange}
        value={stage?.stage || ""}
        variant="outlined"
        sx={{ mt: 0 }}
      />
    </Grid>
  );
};

export default StageSettingContent;
