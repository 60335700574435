import React from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from "@mui/material";
import { isProd } from "../../../../utils/isProd";

const WORKFLOW_TYPES = [
  {
    title: "Waterfall or Task Based Approach",
    image:
      "https://img.freepik.com/free-vector/agile-method-concept-illustration_114360-9983.jpg",
    description:
      "Traditional project management with sequential phases and milestones",
  },
  {
    title:
      "Agile Or Sprint Based Approach" + `${isProd() ? " (Coming Soon)" : ""}`,
    image:
      "https://img.freepik.com/free-vector/group-office-workers-collaborating-tasks_1262-19784.jpg",
    description: "Iterative development with sprints and agile methodologies",
  },
];

const WorkflowSelection = ({ onWorkflowSelect }) => {
  const handleWorkflowSelect = (workflow) => {
    if (isProd() && workflow.title.includes("Agile")) return;
    onWorkflowSelect(workflow);
  };

  return (
    <Grid container spacing={6}>
      {WORKFLOW_TYPES.map((workflow) => (
        <Grid item xs={12} md={6} key={workflow.title}>
          <CardActionArea
            onClick={() => handleWorkflowSelect(workflow)}
            disabled={isProd() && workflow.title.includes("Agile")}
          >
            <Card>
              <CardMedia
                image={workflow.image}
                style={{ height: 0, paddingTop: "56.25%" }}
              />
              <CardContent>
                <Typography variant="h6" align="center" gutterBottom>
                  {workflow.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  {workflow.description}
                </Typography>
              </CardContent>
            </Card>
          </CardActionArea>
        </Grid>
      ))}
    </Grid>
  );
};

export default WorkflowSelection;
